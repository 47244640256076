import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-unauthorized',
    templateUrl: './unauthorized.component.html',
    styleUrls: ['./unauthorized.component.scss'],
    standalone: true
})
export class UnauthorizedComponent {
  constructor(private route: Router) {}

  ngOnInit(): void {}
  goToHome() {
    this.route.navigateByUrl('/').then();
  }


}
