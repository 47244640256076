<ng-container>
  <div class="flex flex-col justify-between" style="height: 100dvh;">
    <div>
      <div>
        <app-banner></app-banner>
      </div>
      <mat-toolbar class="auth-header header-toolbar">
          <div class="w-full">
            <div class="flex justify-between items-center">
              <div class="logo">
                <a href="https://mandatedreportertraining.com/" class="header-logo">
                  <img src="/assets/images/MandatedReporterTraining-log.svg" alt="Mandated Reporter Training" />
                </a>
              </div>
            </div>
          </div>
        </mat-toolbar>
      <mat-sidenav-container class="blank-component-mat-drawer-content">
          <router-outlet></router-outlet>
        </mat-sidenav-container>


    </div>
    <app-footer></app-footer>
  </div>
</ng-container>