import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root',
})
export class UsersApiService extends BaseAPI {
  pipe(arg0: any) {
    throw new Error('Method not implemented.');
  }
  getAll(paramsRequested: any) {
    return this.http.get('adminUser/allusers-grid', {
      params: paramsRequested,
    });
  }

  getUserProfile(id: any) { 
    return this.http.get(`adminUser/user-profile/${id}`);
  }

  getAssociatedEntities(id: any) {
    return this.http.get(`adminUser/associated-entities/${id}`);
  }

  updateUserProfile(user: any, emailConfirmationUrl: any)  { 
    return this.http.patch('adminUser', user,  { params: {emailConfirmationUrl: emailConfirmationUrl}} );
  }


  usersConfirmEmail(email: any) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    return this.http.post(`adminUser/confirm-email`, `"${email}"`, { headers: headers });
  }

  exportCSV(params: any) {
    return this.http.get(`adminUser/allusers-grid/export`, {
      params: params,
    });
  }

  setDefaultOrg(entityGuid: string, userProfileId: number) {
    return this.http.post(`adminEntity/set-default-org/${entityGuid}/${userProfileId}`, null );
  }

  upsertAssociatedEntity(model: any) {
    return this.http.post(`adminUser/upsert-associated-entity`, model);
  }
  
  usersDeleteUserState(id: any) {
    return this.http.delete(`adminUser/user-state/${id}`);
  }

  getOrgAdminAnalyst(paramsRequested: any) {
    return this.http.get('adminUser/org-admin-analyst', {
      params: paramsRequested,
    });
  }

  orgAdminAnalystExportCSV(params: any) {
    return this.http.get(`adminUser/org-admin-analyst/export`, {
      params: params,
    });
  }
}
