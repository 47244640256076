import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { OrgAll } from './org-all.model';
import { OrgAllStore } from './org-all.store';
import { ApiService } from '../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class OrgAllService {
  orgAllStore = inject(OrgAllStore);
  constructor(private api: ApiService) {}

  getAll(params: any) {
    return this.api.orgAll.getAll(params).pipe(
      tap((entities: OrgAll) => {
        this.orgAllStore.setAll(entities);
      })
    );
  }

  getSettings(id: any) {
    return this.api.orgAll.getSettings(id);
  }

  getAppointedCertificates(entityId: any) {
    return this.api.orgAll.getAppointedCertificates(entityId);
  }

  addAppointedCertificates(body: any) {
    return this.api.orgAll.addAppointedCertificates(body);
  }

  getEntityEnterprise(entityId: any) {
    return this.api.orgAll.getEntityEnterprise(entityId);
  }

  updateEnterprise(body: any) {
    return this.api.orgAll.updateEnterprise(body);
  }

  updateEntityStatus(formValues: any) {
    return this.api.orgAll.updateEntityStatus(formValues);
  }

  uploadImage(formData: any) {
    return this.api.orgAll.uploadImage(formData);
  }

  updateOrgAll(form: any) {
    return this.api.orgAll.updateAll(form);
  }

  export(params: any) {
    return this.api.orgAll.exportCSV(params);
  }
}
