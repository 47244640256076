import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { LearnerMetric } from './learner-metrics.model';
import { LearnerMetricsStore } from './learner-metrics.store';

@Injectable({ providedIn: 'root' })
export class LearnerMetricsService {
  learnerMetricsStore = inject(LearnerMetricsStore);
  constructor(private api: ApiService) {}
  getAll(params: any) {
    this.learnerMetricsStore.setLoading(true);   
    return this.api.learnerMetricsService.getAll(params).pipe(
      tap((entities: LearnerMetric) => {
        const { rows, totalNumberOfItems } = entities;
        const newRows = rows?.map((row) => {
          return {
            ...row,
            totalTime: row?.totalTime ? row?.totalTime.slice(0, 20) : null,
          };
        });
        const newEntities = {
          rows: newRows,
          totalNumberOfItems,
        };
        this.learnerMetricsStore.setAll(newEntities);
        this.learnerMetricsStore.setLoading(false);
      })
    );
  }

  exportCSV(params: any) {
    return this.api.learnerMetricsService.exportCsv(params);
  }

  download(id: any, name: string) {
    this.api.learnerMetricsService.downloadCert(id, name);
  }
  updateLearnerMetric(formValues: any) {
    return this.api.learnerMetricsService.updateLearnerMetrics(formValues);
  }
  transferCourse(formValues:any){
    return this.api.learnerMetricsService.transferCourse(formValues);
  }
}
