import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute, Data, RouterLink } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';

@Component({
    selector: 'app-breadcrumb',
    templateUrl: './breadcrumb.component.html',
    styleUrls: ['./breadcrumb.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, RouterLink, MatIconModule]
})
export class BreadcrumbComponent implements OnInit {
  pageInfo: Data = Object.create(null);
  dynamicName?: string = undefined;
  constructor(private router: Router, private activatedRoute: ActivatedRoute, private titleService: Title) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .pipe(map(() => this.activatedRoute))
      .pipe(
        map((route) => {
          this.dynamicName = undefined;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        })
      )
      .pipe(filter((route) => route.outlet === 'primary'))
      .pipe(mergeMap((route) => route.data))
      .subscribe((data) => {
        const navigation = this.router.getCurrentNavigation();
        this.titleService.setTitle(data['title']);
        this.pageInfo = data;

        if (navigation?.extras.state) {
          const state = navigation.extras.state;
          this.dynamicName = state.name;
        }
      });
  }

  ngOnInit(): void {
  }

}
