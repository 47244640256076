import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { CooperativeAll, initialState } from './cooperative-all.model';

export const CooperativeAllStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(coopAllSignal: CooperativeAll["coopAllSignal"]) {
      patchState(store, {coopAllSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);