import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})

export class appMessageService extends BaseAPI {
  getNotificationsGrid() {
    return this.http.get('AdminAppMessage');
  }

  getAppMessage(messageType: string, id: string) {
    return this.http.get('AdminAppMessage/get-app-message', {
      params: { messageType, id }
    });
  }

  postUploadFile(model: any) {
    return this.http.post(`AdminAppMessage/upload-file`, model, { responseType: 'text' });
  }

  upsertBannerMessage(req: any) {
    return this.http.post('AdminAppMessage/add-banner-message', req);
  }

  upsertAdvertisementMessage(req: any) {
    return this.http.post('AdminAppMessage/add-advertisement-message', req);
  }

  upsertPopupMessage(req: any) {
    return this.http.post('AdminAppMessage/add-popup-message', req);
  }
}

