<div class="page-wrapper">
  <div class="header-container">
    <div>
      <app-banner [useReportingDB]="useReportingDB"></app-banner>
    </div>
    <mat-toolbar class="flex justify-between h-16 header-toolbar">
      <div class="flex items-center">
        <div class="pr-5">
          <a href="https://mandatedreportertraining.com/" class="header-logo">
            <img src="/assets/images/MandatedReporterTraining-log.svg" alt="Mandated Reporter Training" />
          </a>
        </div>
        <button mat-icon-button class="primary-link" (click)="clickEvent()" value="sidebarclosed">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
      <app-header (reportBanner)="handleReportBanner($event)"  class="flex items-center space-x-xl justify-between header"></app-header>
    </mat-toolbar>
  </div>
  <div class="main-site">
    <mat-sidenav-container
      class="h-full"
      [style.marginTop.px]="mobileQuery.matches ? 0 : 0"
      [ngClass]="{ minisidebar: minisidebar }"
      [ngClass]="miniHeader ? 'mini-header' : ''"
    >
      <mat-sidenav
        #snav
        id="snav"
        class=""
        [mode]="mobileQuery.matches ? 'side' : 'over'"
        fixedTopGap="0"
        [opened]="sidebarOpened"
        (opened)="sidebarOpened = true"
        (close)="sidebarOpened = false"
        (closed)="sidebarOpened = false"
        [ngClass]="!snav.opened ? 'minisidebar' : ''"
      >
        <app-sidebar class="app-sidebar"></app-sidebar>
        <div class="relative text-center w-full py-3">
          <p class="version small w-full">v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }}</p>
        </div>
      </mat-sidenav>


      <mat-sidenav-content class="" id="scrolldiv" [ngClass]="!snav.opened ? 'minitoggle' : ''">
        <div class="flex flex-col h-full">
          <div class="breadcrumb-wrapper">
            <app-breadcrumb></app-breadcrumb>
          </div>
          <div class="p-5 main-content-wrapper">
            <router-outlet>
              <app-spinner></app-spinner>
            </router-outlet>
          </div>
          <div class="mt-auto footer-wrapper">
            <app-footer></app-footer>
          </div>
        </div>
      </mat-sidenav-content>


    </mat-sidenav-container>
  </div>
</div>
