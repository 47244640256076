import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { CoopUserAssociationStore } from './coop-user-association.store';

@Injectable({ providedIn: 'root' })
export class CoopUserAssociationService {
  coopUserAssociationStore = inject(CoopUserAssociationStore);
  constructor(private api: ApiService) {}

  getAll(params: any) {
    this.coopUserAssociationStore.setLoading(true);
    return this.api.orgAssociations.getAllCoops(params).pipe(
      tap((entities: any) => {
        this.coopUserAssociationStore.setAll(entities);
        this.coopUserAssociationStore.setLoading(false);
      })
    );
  }

  export(params: any) {
    return this.api.orgAssociations.exportCoops(params);
  }

  addUserToCoop(formValues: any) {
    return this.api.orgAssociations.addUserToCoop(formValues);
  }
}
