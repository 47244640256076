import { components } from "src/app/api/interfaces/swagger-types";

export type DashboardCharts = components['schemas']['AdminDashboardViewModel'];

export interface Dashboard {
  dailyDataSignal: DashboardCharts | null;
  dailyLoadingSignal: boolean;
  trendDataSignal: DashboardCharts | null;
  trendLoadingSignal: boolean;
}

export const initialState: Dashboard = {
  dailyDataSignal: null,
  dailyLoadingSignal: false,
  trendDataSignal: null,
  trendLoadingSignal: false,
};
