import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { shareReplay, tap } from 'rxjs';
import { MatCardModule } from '@angular/material/card';
@UntilDestroy()
@Component({
    selector: 'app-checkinbox',
    templateUrl: './checkinbox.component.html',
    styleUrls: ['./checkinbox.component.scss'],
    standalone: true,
    imports: [MatCardModule]
})
export class CheckinboxComponent implements OnInit {

  title!: string;
  content!: any;
  constructor(private _route: ActivatedRoute) {}

  ngOnInit(): void {
    this._route.queryParams
      .pipe(
        shareReplay(1),
        untilDestroyed(this),
        tap((params) => {
          let forgotPassword = params['forgot'];
          let email = params['email'];
          const replaceEmail = email.replace(' ', '+');
          if (forgotPassword === 'true') {
            this.title = 'Forgot Password';
            this.content = `<p class="mb-5">We’ve sent an email with password reset instructions.
            If your message doesn’t arrive within a few minutes, be sure to check your spam or all mail folder (sometimes messages get lost in there)
            </p>`;
          } else {
            this.title = 'Verify Email';
            this.content = `<p class="mb-5">We’ve sent a confirmation message to <a href="https://mail.google.com/mail/u/0" class="text-primary underline font-medium"
            >${replaceEmail}</a>. Click the link to verify your email address and activate your account. If your message doesn’t arrive within a few minutes, be sure to check your spam or all mail folder (sometimes messages get lost in there).</p>`;
          }
        })
      )
      .subscribe();
  }

}
