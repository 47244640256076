import { Component, effect, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { SimpleOffsiteMetricsService } from 'src/app/api/services/reports/simple-offsite-metrics/simple-offsite-metrics.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { ApiService } from 'src/app/api/services/api.service';
import { catchError, tap, throwError } from 'rxjs';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-simple-offsite-metrics',
    templateUrl: './simple-offsite-metrics.component.html',
    styleUrls: ['./simple-offsite-metrics.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class SimpleOffsiteMetricsComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly authStore = inject(AuthStore);
  paramsReq: any;
  selectedTrainingId: number = 0;
  defaultSortColumn: string = 'dateCompleted';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -60 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Client', formControlName: 'clientName', type: 'searchDropdown', defaultValue: undefined, lookup: [] },
      { name: 'Training', formControlName: 'trainingId', type: 'dropdown', defaultValue: undefined, lookup: this.lookupsStore.trainingsSignal },
      { name: 'Course', formControlName: 'courseId', type: 'dropdown', defaultValue: undefined, lookup: this.lookupsStore.courseTrainingsSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Issue Date', controlName: 'issueDate', displayPipe: 'date' },
      { header: 'Client', controlName: 'client' },
      { header: 'Student Name', controlName: 'studentName' },
      { header: 'Student ID', controlName: 'studentId' },
      { header: 'Training', controlName: 'training' },
      { header: 'Course', controlName: 'courseName' },
      { header: 'Certificate', controlName: 'certificationName',
        actionColumnitems: [
          { 
            buttonAction: 'download',
            text: { calculateText: (certificateName: any) => { return certificateName ? certificateName: '-'; }, textColumn: 'certificationName' },
          },
        ]
      }
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    addButtonName: undefined,
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };
  constructor(
    private lookupsService: LookupsService,
    private simpleOffsiteMetricsService: SimpleOffsiteMetricsService,
    private api: ApiService,
    private snackBar: MatSnackBar,
  ) {
      // Set default training to Child Abuse Training
      effect(() => {
        if (this.lookupsStore.trainingsSignal().length > 0) {
          var filteredTraining = this.lookupsStore.trainingsSignal().filter((training) => training.name === 'Child Abuse');
          if (filteredTraining.length > 0) {
            var id = filteredTraining[0]?.value;
            this.selectedTrainingId = id;
            var trainingFilter = this.gridOptions.filterData.find((filter) => filter.formControlName === 'trainingIds');
            trainingFilter!.defaultValue = id;
            if (this.paramsReq) {
              this.gridOptions.fetchData = true;
              this.lookupsService.getCourseTrainings(this.selectedTrainingId);
            }
          }
        }
      });
  }

  ngOnInit() {
    this.lookupsService.getCourses();
    this.lookupsService.getTrainings();
    this.entityLookup('');
    this.gridOptions.fetchData = true;

  }

  getAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    var useReportingDB = this.authStore.useReportingDBSignal() ?? false;

    const { search, startDate, endDate, trainingId, courseId, clientName, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);

    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      // fix entityGuid
      EntityGuid: clientName?.value ? clientName?.value : '',
      TrainingId: trainingId ? trainingId : '',
      CourseId: courseId ? courseId : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      UseReportingDB: useReportingDB ? useReportingDB : false,
    };

    this.paramsReq = params;
    var gridDatasource: any[] = [];
    this.simpleOffsiteMetricsService.getAll(params).subscribe((all: any) => {
      gridDatasource = [];
        const { rows, totalNumberOfItems } = all;
        rows.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
    });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'issueDate':
          activeSort = 'dateCompleted';
          break;
        case sort.active === 'client':
          activeSort = 'orgName';
          break;
        case sort.active === 'studentId':
          activeSort = 'studentId';
          break;
        case sort.active === 'courseName':
          activeSort = 'courseName';
          break;
        case sort.active === 'certificateName':
          activeSort = 'certificateName';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  downloadCertificate(data: any) {
    // download certificate
  }

  entityLookup(val: any) {
    this.api.lookups
    .getOffsiteClient(val)
    .pipe(
      catchError((e) => throwError(() => e)),
      tap((data: any) => {
        if (data) {
          this.gridOptions.filterData.find((filter) => filter.formControlName === 'clientName')!.lookup = data;
        }
      })
    )
    .subscribe({
      next: () => {},
      error: (e) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
      },
    });
  }

  onTrainingChange() {
    // Reset the Course dropdown
    var courseId = this.gridOptions.filterData.find((filter) => filter.formControlName === 'courseId');
    courseId!.defaultValue = null;
    this.lookupsService.getCourseTrainings(this.selectedTrainingId);
  }

  formChanges(formChanges: any) {
    const { clientName } = formChanges;
    if (typeof clientName === 'string' && clientName.length >= 3) {
      this.entityLookup(clientName);
    }
    if (this.selectedTrainingId !== formChanges.trainingId) {
      this.selectedTrainingId = formChanges.trainingId;
      this.onTrainingChange();
    }
  }

  clearFilters() {
    var filteredTraining = this.lookupsStore.trainingsSignal().filter((training) => training.name === 'Child Abuse');

    if (filteredTraining.length > 0) {
      var id = filteredTraining[0]?.value;
      this.selectedTrainingId = id;
      var trainingFilter = this.gridOptions.filterData.find((filter) => filter.formControlName === 'trainingIds');
      trainingFilter!.defaultValue = id;
      if (this.paramsReq) {
        this.paramsReq.TrainingIds = [id];
        // Now populate the Course dropdown
        this.lookupsService.getCourseTrainings(this.selectedTrainingId);
      }
    }
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.getAll(event.data);
    } else if (event.action === 'exportCSV') {
      // export
    } else if (event.action === 'formChanges') {
      this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // clear filters
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.downloadCertificate(event.data);
    }
  }
}
