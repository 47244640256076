import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { AddProductComponent } from 'src/app/shared/modals/add-product/add-product.component';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-paid-products',
    templateUrl: './paid-products.component.html',
    styleUrls: ['./paid-products.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class PaidProductsComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  defaultSortColumn: string = 'learningPath';
  defaultSortDirection: string = 'ASCENDING';
  dataInit: any;
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
        { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 365 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Training', formControlName: 'training', type: 'dropdown', lookup: this.lookupsStore.trainingsSignal },
        { name: 'Course', formControlName: 'course', type: 'dropdown', lookup: this.lookupsStore.learningPathSignal },
        { name: 'State', formControlName: 'state', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
      ],
      openFilters: false,
      columns: [
        { header: 'Learning Path', controlName: 'learningPathName' },
        { header: 'Training', controlName: 'training' },
        { header: 'Stripe Price Id', controlName: 'stripePriceId' },
        { header: 'Price', controlName: 'price', displayPipe: 'currency' },
        { header: 'State', controlName: 'state'},
        { header: 'Start Date', controlName: 'startDate', displayPipe: 'date'},
        { header: 'End Date', controlName: 'endDate', displayPipe: 'date' },
        { header: 'Action', controlName: 'action',
          actionColumnitems: [
            { icon: 'edit', disableOnColumnValue: {column: 'editable', values: [0, '0']}, buttonAction: 'edit' },
          ]
        }
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      addButtonName: 'Add Product',
      allowExportCSV: false,
      disableflag: false,
      isLoading: false,
      frontEndPagination: true,
      displaySearch: true,
    };

  constructor(
    private dialog: MatDialog,
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getTrainings();
    this.lookupsService.getLearningPath();
    this.lookupsService.getStates();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams? : any) {
    this.gridOptions.isLoading = true;
    this.coursesService.getPaidProductsGrid().subscribe((res: any) => {   
      this.gridOptions.data.data = res;
      this.dataInit = res;
      this.applyFilter(rawParams);
      this.gridOptions.isLoading = false
    });
  }



  applyFilter(rawParams?: any) {
    this.gridOptions.data.data = this.dataInit;
      if(rawParams.training) { 
        this.gridOptions.data.data = this.gridOptions.data.data.filter((item: any) => {
          return item.trainingId === rawParams.training;
        });
      }
      if(rawParams.course) { 
        this.gridOptions.data.data = this.gridOptions.data.data.filter((item: any) => {
          return item.learningPathId === rawParams.course;
        });
      }
      if(rawParams.state) { 
        this.gridOptions.data.data = this.dataInit.filter((item: any) => {
          return item.state === rawParams.state;
        });
      }
      if ( rawParams.fromDate) {
        const fromDate = new Date(rawParams.fromDate);
        this.gridOptions.data.data = this.gridOptions.data.data.filter((item: any) => {
          const itemDate = new Date(item.startDate);
          return itemDate >= fromDate;
        });
      }
      if (rawParams.toDate) {
        const toDate = new Date(rawParams.toDate);
        this.gridOptions.data.data = this.gridOptions.data.data.filter((item: any) => {
          const itemDate = new Date(item.startDate);
          return itemDate <= toDate;
        });
      }
    this.gridOptions.data.filter = rawParams.search?.trim().toLowerCase();
  }

  openAddProduct(item: any) {
    const dialogRef = this.dialog.open(AddProductComponent, {  panelClass: 'full-screen-modal-medium-mobile-panel-class', data: { items: item},
    minWidth: '50vw', width: '600px', maxWidth: '100vw'});
    dialogRef.afterClosed().subscribe(() => {
      this.gridOptions.fetchData = true;
    });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.applyFilter(event.data);
    } else if (event.action === 'clearFilters') {
      this.fetchAll(event.data);
    } else if (event.action === 'addButton') {
      this.openAddProduct({});
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.openAddProduct(event.data);
    }
  }
}
