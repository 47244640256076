import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Auth, initialState } from './auth.model';

export const AuthStore = signalStore(
  withState(initialState),
  withComputed(({userSignal}) => ({
    userLoggedInSignal: computed(() => !!userSignal),
  })),
  withMethods(({...store}) => ({
    setUser(user: Auth["userSignal"]) {
      patchState(store, {userSignal: user});
    },
    setJwt(jwt: Auth["jwtSignal"]) {
      patchState(store, {jwtSignal: jwt});
    },
    setUseReportingDB(useReportingDB: Auth["useReportingDBSignal"]) {
      patchState(store, {useReportingDBSignal: useReportingDB});
    },
    setUserIsLoggedIn(isLoggedIn: Auth["userIsLoggedInSignal"]) {
      patchState(store, {userIsLoggedInSignal: isLoggedIn});
    },
    setProfilePercentComplete(percentComplete: Auth["profilePercentCompleteSignal"]) {
      patchState(store, {profilePercentCompleteSignal: percentComplete});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {isLoadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);