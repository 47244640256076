import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { UserInvite } from './user-invites.model';
import { UserInvitesStore } from './user-invites.store';

@Injectable({ providedIn: 'root' })
export class UserInvitesService {
  userInvitesStore = inject(UserInvitesStore);
  constructor(private api: ApiService) {}

  getAllGrid(params: any) {
    this.userInvitesStore.setLoading(true);
    return this.api.orgUserInvites.getAll(params).pipe(
      tap((entities: any) => {
        this.userInvitesStore.setAll(entities);
        this.userInvitesStore.setLoading(false);
      })
    );
  }

  allUsersConfirmEmail(email: any) {
    return this.api.usersService.usersConfirmEmail(email);
  }

  resendInvitation(formValues: any) {
    return this.api.orgUserInvites.resendInvitation(formValues);
  }
  cancelInvites(inviteGuid: any) {
    return this.api.orgUserInvites.cancelInvite(inviteGuid);
  }
  sendInvite(formValues: any) {
    return this.api.orgUserInvites.sendInvite(formValues);
  }
  export(params: any) {
    return this.api.orgUserInvites.export(params);
  }
}
