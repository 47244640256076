<div class="p-4">
  <div class="flex justify-end close-modal-button-pos">
    <button type="button" class="pexit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <div class="px-12 py-8">
        <div class="text-center px-5 py-3">
            <h2 class="font-bold mb-5">  Add User to Cooperative</h2>
        </div>
        <p class="sm:text-center lg:text-left text-primary font-medium mx-auto my-0">
          Add an existing user to your selected cooperative.
        </p>
        <div [formGroup]="form">
          <div class="mt-3">
            <mat-form-field class="w-full">
              <input type="text" placeholder="Cooperative" matInput formControlName="cooperative" [matAutocomplete]="auto" />
              <mat-autocomplete
                #auto="matAutocomplete"
                [displayWith]="displayOptions"
                class="custom-autocomplete-size"
              >
                <mat-option *ngFor="let option of cooperativeOptions" [value]="option">
                  {{ option?.name }}
                </mat-option>
              </mat-autocomplete>
              <mat-error
                *ngIf="form.controls['cooperative']?.hasError('required') && form.controls['cooperative']?.touched">Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="text"
                (blur)="checkEmail($event, form.controls['userEmail'])"
                formControlName="userEmail"
              />
              <mat-error *ngIf="form.controls['userEmail']?.hasError('required') && form.controls['userEmail']?.touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['userEmail']?.hasError('email') && form.controls['userEmail']?.touched"
              >Invalid Email
            </mat-error>
            </mat-form-field>
            <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['userEmail']?.errors?.message}">
              <mat-error class="text-danger support-text" *ngIf="form.controls['userEmail']?.errors?.message">{{
                form.controls['userEmail'].getError('message')
              }}</mat-error>
            </div>
          </div>
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>Role</mat-label>
              <mat-select formControlName="coopRole" panelClass="mat-select-mt3">
                <mat-option *ngFor="let role of roles()" [value]="role.name">
                  {{ role.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.controls['coopRole']?.hasError('required') && form.controls['coopRole']?.touched">Required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <mat-dialog-actions class="justify-center">
          <div class="pb-6">
            <button *ngxPermissionsOnly="['coops-user-invites:edit']" class="btn-primary large" type="submit" [disabled]="disableAssociateCoop" (click)="associateUserToCoop()">
              <ng-container>Add User</ng-container>
            </button>
          </div>
        </mat-dialog-actions>
    </div>
  </div>
</div>