import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { UserAssociationsStore } from './user-associations.store';

@Injectable({ providedIn: 'root' })
export class UserAssociationsService {
  userAssociationStore = inject(UserAssociationsStore);
  constructor(private api: ApiService) {}

  getAll(params: any) {
    this.userAssociationStore.setLoading(true);
    return this.api.orgAssociations.getAll(params).pipe(
      tap((entities: any) => {
        this.userAssociationStore.setAll(entities);
        this.userAssociationStore.setLoading(false);
      })
    );
  }

  assignRoles(formValues: any) {
    return this.api.orgAssociations.assign(formValues);
  }

  export(params: any) {
    return this.api.orgAssociations.export(params);
  }

  assignStatus(formValues: any) {
    return this.api.orgAssociations.assignStatus(formValues);
  }
}
