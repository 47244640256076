import { Component, OnInit, ViewChild, EventEmitter, Output, inject } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AddCourseComponent } from 'src/app/shared/modals/add-course/add-course.component';
import { AddCourseVersionComponent } from 'src/app/shared/modals/add-course-version/add-course-version.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { CourseCardComponent } from './course-card/course-card.component';
import { MatButtonModule } from '@angular/material/button';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgFor, NgClass, DatePipe } from '@angular/common';
import { FilterIconComponent } from '../../../shared/components/layouts/filter-icon/filter-icon.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-courses-all',
    templateUrl: './courses-all.component.html',
    styleUrls: ['./courses-all.component.scss'],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatToolbarModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        FilterIconComponent,
        NgIf,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatExpansionModule,
        MatButtonModule,
        NgClass,
        CourseCardComponent,
        MatPaginatorModule,
        DatePipe,
        NgxPermissionsModule
    ],
})
export class CoursesAllComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  dataSource!: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  // @Output() actionEvent = new EventEmitter<GridParentEmitter>();
  pageSizeOptions: Array<number> = [20, 40, 60, 100];
  clearBtnFlag: boolean = true;
  openFilters: boolean = true;
  filtersForm!: FormGroup;
  initData: any;

  constructor(
    private fb: FormBuilder,
    private dialog: MatDialog,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private coursesService: CoursesService,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.lookupsService.getStates();
    this.lookupsService.getTrainings();
    this.lookupsService.getLearnerStatus();

    this.spinnerService.whiteBackground();
    this.refreshGrid();

    const today = new Date();
    const yearAgo = new Date(today.getFullYear() - 1, today.getMonth(), today.getDate());
    this.filtersForm = this.fb.group({
      fromDate: this.fb.control(yearAgo || null),
      toDate: this.fb.control(today || null),
      search: this.fb.control(''),
      trainingType: this.fb.control(0),
      stateId: this.fb.control(0),
      status: this.fb.control(0),
    });  
  }

  refreshGrid() {
    this.spinnerService.show();
    this.spinnerService.overlayBackground();
    this.coursesService.getCourseVersionGrid().subscribe((res: any) => {
      this.dataSource = new MatTableDataSource(res);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.initData = res;
      this.spinnerService.hide();
    });
  }

  applyFilter() {
    this.dataSource.data = this.initData;
    if(this.filtersForm.value.search) { 
      this.dataSource.filter = this.filtersForm.value.search.trim().toLowerCase();
    }
    if(this.filtersForm.value.trainingType) { 
      this.dataSource.data = this.dataSource.data.filter((item: any) => {
        return item.trainingId === this.filtersForm.value.trainingType;
      });
    }
    if(this.filtersForm.value.stateId) {
      this.dataSource.data = this.dataSource.data.filter((item: any) => {
        if(item.courseStates.includes(this.filtersForm.value.stateId)) {
          return item
        }
      });
    }
    if(this.filtersForm.value.status) { 
      this.dataSource.data = this.dataSource.data.filter((item: any) => {
        let str = this.filtersForm.value.status;
        let boolValue = str.toLowerCase() === str
        return item.published === boolValue;
      });
    }

    if (this.filtersForm.value.fromDate && this.filtersForm.value.toDate) {
      const fromDate = new Date(this.filtersForm.value.fromDate);
      const toDate = new Date(this.filtersForm.value.toDate);
  
      this.dataSource.data = this.dataSource.data.filter((item: any) => {
        const itemDate = new Date(item.createDate);
        return itemDate >= fromDate && itemDate <= toDate;
      });
    }
  }

  getStateName(stateId: number) {
    return this.lookupsStore.statesSignal().find((state) => state.value === stateId)?.name;
  }

  openDialog(action: any, item?: any) {
    const dialogRef = this.dialog.open(AddCourseComponent, {
      panelClass: 'full-screen-modal-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: { action, item },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.refreshGrid();
      }
    });
  }

  openCourseVersion(action: any, item?: any, index?: number) {
    const dialogRef = this.dialog.open(AddCourseVersionComponent, {
      panelClass: 'full-screen-modal-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: { action, item, index},
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result === 'success') {
        this.refreshGrid();
      }
    });
  }

  
  closeFilter() {
    this.openFilters = !this.openFilters;
  }

  clearFilters(){
    this.refreshGrid();
  }

  getTrainingTagClass(trainingName: string) {
    switch (trainingName) {
      case 'Child Abuse':
        return 'child';
      case 'Elder Abuse':
        return 'elder';
      case 'Sexual Harassment':
        return 'harassment';
      case 'Implicit Bias':
        return 'implicit-bias';
      default:
        return 'unknown';
    }
  }

  getTrainingTagTitle(trainingName: string) {
    switch (trainingName) {
      case 'Child Abuse':
        return 'Child';
      case 'Elder Abuse':
        return 'Elder';
      case 'Sexual Harassment':
        return 'Harassment';
      case 'Implicit Bias':
        return 'Implicit Bias';
      default:
        return 'Unknown';
    }
  }

}


