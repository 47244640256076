import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AddCourseComponent } from '../add-course/add-course.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatInputModule } from '@angular/material/input';
import { NgFor } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-add-module',
    templateUrl: './add-module.component.html',
    styleUrls: ['./add-module.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        MatInputModule,
        NgxPermissionsModule
    ],
})
export class AddModuleComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  disableFlag = true;
  initialSnapshot!: any;
  loaded = false;
  errorMessage = '';
  isNewModule = true;
  modalTitle: string = 'Add';
  isEditModule = false;


  moduleForm = this.fb.group({
    moduleId: this.fb.control(0),
    moduleName: this.fb.control(null, Validators.required),
    scormId: this.fb.control(null),
    scormFullName: this.fb.control(null, Validators.required),
    scormShortName: this.fb.control(null, Validators.required),
    scormDescription: this.fb.control(null, Validators.required),
    scormTypeId: this.fb.control(null, Validators.required),
    scormType: this.fb.control(null),
    scormDuration: this.fb.control(null, Validators.required),
    language: this.fb.control(null, Validators.required),
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.initialSnapshot = this.moduleForm.getRawValue();
    this.lookupsService.getScormTypes();
    this.lookupsService.getModules();

    this.moduleForm.valueChanges.subscribe((currentState: any) => {
      this.disableFlag = false;
      if (this.loaded && !this.isNewModule) {
        Object.keys(currentState).forEach((key) => {
          if (this.moduleForm.get(key)?.dirty) {
            if (this.initialSnapshot[key] !== currentState[key]) {
              const el = document.querySelector(`[formcontrolname="${key}"]`) as HTMLElement;
              el.classList.add('changed');
            } else {
              const el = document.querySelector(`[formcontrolname="${key}"]`) as HTMLElement;
              el.classList.remove('changed');
            }
          }
        });
      }
    });
    
    let moduleEdit = this.data.action;
    if(moduleEdit !== 'add') {
      this.modalTitle = "Edit Module"
      this.isEditModule = true;

      const formValues = {
        moduleId: moduleEdit.moduleId,
        moduleName: moduleEdit.moduleName,
        scormId: moduleEdit.scormId,
        scormFullName: moduleEdit.scormFullName,
        scormShortName: moduleEdit.scormShortName,
        scormDescription: moduleEdit.scormDescription,
        scormTypeId: moduleEdit.scormTypeId,
        scormType: moduleEdit.scormType,
        scormDuration: moduleEdit.scormDuration,
        language: moduleEdit.language,
      }

      this.moduleForm.patchValue(formValues);


    } else {
      this.modalTitle = "Add Module"
    }
  }

  onModuleSelect(event: any) {
    if (event.value > 0) {
      this.coursesService.getModuleDetails(event.value).subscribe((response) => {
        this.moduleForm.patchValue(response);
        this.initialSnapshot = this.moduleForm.getRawValue();
        this.loaded = true;
        this.isNewModule = false;
      });
    } else {
      this.isNewModule = true;
      this.moduleForm.reset();
      this.reset();
    }
  }

  reset() {
    var changedElements = document.querySelectorAll('.changed');
    changedElements.forEach((el) => {
      el.classList.remove('changed');
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.moduleForm.markAllAsTouched();
    if (this.moduleForm.invalid) {
      return;
    }

    this.disableFlag = true;

    this.coursesService.postNewModule(this.moduleForm.getRawValue()).subscribe({
      next: () => {

        if(!this.isEditModule) {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Module successfully added' }})
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Module successfully been updated' }})

        }
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.disableFlag = false;
        this.errorMessage = error.error.message;
      },
    });
  }
}
