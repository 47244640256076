import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { CoopUserAssociationAll, initialState } from './coop-user-association.model';

export const CoopUserAssociationStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(coopUserAssociationSignal: CoopUserAssociationAll["coopUserAssociationSignal"]) {
      patchState(store, {coopUserAssociationSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);