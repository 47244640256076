import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { AllUsers, initialState } from './all-users.model';

export const AllUsersStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(allUsersSignal: AllUsers["allUsersSignal"]) {
      patchState(store, {allUsersSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)