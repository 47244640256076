import { components } from '../../../interfaces/swagger-types';
export type LearnerMetric = components['schemas']['LearnerMetricsReportViewModel'];

export interface LearnerMetrics {
  learnerMetricsSignal: LearnerMetric | null;
  loadingSignal: boolean;
}

export const initialState: LearnerMetrics = {
  learnerMetricsSignal: null,
  loadingSignal: false,
};
