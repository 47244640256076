import { Component } from '@angular/core';

@Component({
    selector: 'app-filter-icon',
    templateUrl: './filter-icon.component.html',
    styleUrls: ['./filter-icon.component.scss'],
    standalone: true
})
export class FilterIconComponent {

}
