<div class="p-4">
  <div class="flex justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="admin-modal-body">
    <div class="px-12 py-8">
        <div class="text-center px-5 py-3">
            <h2 class="font-bold mb-5">Add Cooperative</h2>
        </div>

        <p class="text-center text-primary font-medium mx-auto my-0">
          Create a Cooperative to manage learners and assign trainings.
        </p>
        <div [formGroup]="form">
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>Email</mat-label>
              <input
                matInput
                type="text"
                (blur)="checkEmail($event, form.controls['email'])"
                formControlName="email"
              />
              <mat-error *ngIf="form.controls['email']?.hasError('required') && form.controls['email']?.touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['email']?.hasError('email') && form.controls['email']?.touched"
              >Invalid Email
            </mat-error>
            </mat-form-field>
            <div [ngClass]="{ 'mb-1 -mt-4 ': form.controls['email']?.errors?.message }">
              <mat-error class="text-danger support-text" *ngIf="form.controls['email']?.errors?.message">{{
                form.controls['email'].getError('message')
              }}</mat-error>
            </div>
          </div>
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>Organization Name</mat-label>
              <input type="text" matInput formControlName="name" />
              <mat-error
                *ngIf="form.controls['name']?.hasError('required') && form.controls['name']?.touched">Required</mat-error>
              <mat-error *ngIf="form.controls['name']?.hasError('whiteSpace') && form.controls['name']?.touched">Must constain
                at least one alphanumeric character</mat-error>
            </mat-form-field>
          </div>
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let state of lookupsStore.statesSignal()" [value]="state.value">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error
                *ngIf="form.controls['stateId']?.hasError('required') && form.controls['stateId']?.touched">Required</mat-error>
            </mat-form-field>
          </div>
          <div class="mt-3">
            <mat-form-field class="w-full">
              <mat-label>Industry</mat-label>
              <mat-select formControlName="industry" panelClass="mat-select-mt3">
                <mat-option *ngFor="let industry of lookupsStore.industriesSignal()" [value]="industry.value">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <mat-dialog-actions class="justify-center">
          <div class="pb-6">
            <button *ngxPermissionsOnly="['coops:edit']" class="btn-primary large" type="submit" [disabled]="disableCreateCoop" (click)="createCoop()">
              <ng-container>Add Cooperative</ng-container>
            </button>
          </div>
        </mat-dialog-actions>
    </div>
  </div>
</div>