import { Component, effect, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, tap, throwError } from 'rxjs';
import { ApiService } from 'src/app/api/services/api.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ResentVerificationComponent } from 'src/app/shared/modals/resent-verification/resent-verification.component';
import { UserInvitesService } from 'src/app/api/services/organizations/user-invites/user-invites.service';
import { UserInvitesStore } from 'src/app/api/services/organizations/user-invites/user-invites.store';
import { AddUsersComponent } from 'src/app/shared/modals/add-users/add-users.component';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-user-invites',
    templateUrl: './user-invites.component.html',
    styleUrls: ['./user-invites.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})
export class UserInvitesComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly userInvitesStore = inject(UserInvitesStore);
  paramsReq: any;
  defaultSortColumn: string = 'dateSent';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -365 },
        { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Org. Name', formControlName: 'orgName', type: 'searchDropdown', defaultValue: undefined, lookup: [] },
        { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
        { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore?.orgUserInvitesStatusSignal },
      ],
      openFilters: true,
      columns: [
        { header: 'Date Sent', controlName: 'dateSent', displayPipe: 'date'},
        { header: 'Email', controlName: 'email', displayPipe: 'truncate' },
        { header: 'Organization Name', controlName: 'organizationName' },
        { header: 'Group Name', controlName: 'groupName' },
        { header: 'State', controlName: 'state' },
        { header: 'Last Resent', controlName: 'lastResent' },
        { header: 'Status', controlName: 'status' },
        { header: 'Action', controlName: 'action',
          actionColumnitems: [
            { icon: 'refresh', disableOnColumnValue: {column: 'status', values: ['Accepted']},  buttonAction: 'resend'},
            { icon: 'cancel', disableOnColumnValue: {column: 'status', values: ['Accepted', 'Cancelled', 'Declined']}, buttonAction: 'cancel'},
          ]
        },
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      allowExportCSV: true,
      addButtonName: 'Invite Learner',
      disableflag: false,
      isLoading: false,
      displaySearch: true,
    };

  constructor(
    private dialog: MatDialog,
    private lookupsService: LookupsService,
    private userInvitesService: UserInvitesService,
    private api: ApiService,
    private snackBar: MatSnackBar,
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      const userInvites = this.userInvitesStore.userInvitesSignal() ?? {};
      gridDatasource = [];
      if (userInvites.totalNumberOfItems != null) {
        const { rows, totalNumberOfItems } = userInvites;
        rows.forEach((admin: any) => {
          const datasource = gridDatasource;
          datasource.push(admin);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.lookupsService.getOrgUserInvitesStatus();
    this.lookupsService.getStates();
    this.lookupsService.getOrgRoles();
    this.organizationLookup('a');
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    const { search, stateId, orgName,startDate, endDate,  status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      StateId: stateId ? stateId : '',
      Status: status ? status : '',
      ToDate: endDate,
      FromDate: startDate,
      //@ts-ignore
      EntityGuid: orgName?.value ? orgName?.value : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.userInvitesService.getAllGrid(params).subscribe();
  }

  exportCsv() {
    const paramsReq = {
      SearchTerm: this.paramsReq?.SearchTerm ? this.paramsReq?.SearchTerm : '',
      StateId: this.paramsReq?.StateId ? this.paramsReq?.StateId : '',
      Status: this.paramsReq?.Status ? this.paramsReq?.Status : '',
      EntityUserId: this.paramsReq?.EntityGuid ? this.paramsReq?.EntityGuid : '',
    };
    this.userInvitesService
      .export(paramsReq)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Report will be sent to your email' }})
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' }})
        },
      });
  }

  openModal(row: any, action: any) {
    if (action === 'userInvite-resent' || 'userInvite-cancel') {
      const dialogRef = this.dialog.open(ResentVerificationComponent, {
        panelClass: 'full-screen-modal-medium-mobile-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          buttonAction: action,
          items: row,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.gridOptions.fetchData = true;
      });
    }
  }

  openAddUserModal() {
    const dialogRef = this.dialog.open(AddUsersComponent, {  panelClass: 'full-screen-modal-medium-mobile-panel-class' });
    dialogRef.afterClosed().subscribe(() => {
      this.gridOptions.fetchData = true;
    });
  }

  organizationLookup(val: any) {
    if (val === '') {
      this.organizationLookup('a');
    } else {
      this.api.lookups
      .getOrganization(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.gridOptions.filterData.find((filter) => filter.formControlName === 'orgName')!.lookup = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'dateSent':
          activeSort = 'dateSent';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'groupName':
          activeSort = 'groupName';
          break;
        case sort.active === 'lastResent':
          activeSort = 'lastResent';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  formChanges(formChanges: any) {
    const { orgName } = formChanges;
    if (typeof orgName === 'string' && orgName.length >= 3) {
      this.organizationLookup(orgName);
    }
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv();
    } else if (event.action === 'formChanges') {
      this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      this.openAddUserModal();
    }
    // 'resend' & 'cancel' are specific to this component 
    else if (event.action === 'resend') {
      this.openModal(event.data, 'userInvite-resent');
    } else if (event.action === 'cancel') {
      this.openModal(event.data, 'userInvite-cancel');
    }
  }
}