import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({ providedIn: 'root' })
export class CertificatesApiService extends BaseAPI {

  getAll(paramsRequested: any) {
    return this.http.get('adminReports/certificates', {
      params: paramsRequested,
    });
  }

  exportCSV(params: any) {
    return this.http.get(`adminReports/certificates/export`, {
      params: params,
    });
  }

  downloadCert(certId: string, name: string) {
    let url = 'certificate/' + certId;
    this.http.get(url, { responseType: 'arraybuffer' }).subscribe((res) => {
      const blob = new Blob([res], { type: 'application/pdf' });
      this.downloadFile(blob, certId, name);
    });
  }

  downloadFile(blob: Blob, certId: string, name: string) {
    let url = window.URL.createObjectURL(blob);
    let a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = name + '.pdf';
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

}
