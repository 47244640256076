import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Dashboard, initialState } from './dashboard.model';

export const DashboardStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setDailyData(dailyDataSignal: Dashboard["dailyDataSignal"]) {
      patchState(store, {dailyDataSignal});
    },
    setDailyLoading(loadingSignal: boolean) {
      patchState(store, {dailyLoadingSignal: loadingSignal});
    },
    setTrendData(trendDataSignal: Dashboard["trendDataSignal"]) {
      patchState(store, {trendDataSignal});
    },
    setTrendLoading(loadingSignal: boolean) {
      patchState(store, {trendLoadingSignal: loadingSignal});
    },
    resetStore() {
      patchState(store, initialState);
    }
  })) 
);