import { Component, Inject, OnInit, inject } from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AllService } from '../../../api/services/organizations/all/all.service';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-archive-org',
    templateUrl: './archive-org.component.html',
    styleUrls: ['./archive-org.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        NgxPermissionsModule
    ],
})
export class ArchiveOrgComponent implements OnInit {
  confirm = new UntypedFormControl('');
  disableFlag: boolean = false;
  languages!: any[];
  languageDropdown!: UntypedFormControl;
  
  constructor(
    public dialogRef: MatDialogRef<ArchiveOrgComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { entityId: number; entityStatus: 'archived'; entityType: string; },
    private allApiService: AllService,
  ) {}

  ngOnInit(): void {
  }

  action() {
    this.disableFlag = true;
    let apiCall: any = null;

    if (this.data.entityStatus === 'archived') {
      apiCall = this.allApiService.updateEntityStatus(this.data);
    }      
  }

  archiveOrg() {
    // Admin requests to archive an organization    
    const formValues = {
      entityId: this.data.entityId,
      entityStatus: 'archived',
    };
    
    this.allApiService
      .updateEntityStatus(formValues)
      .subscribe((res: any) => {
        this.dialogRef.close(res);
      });
    }

  closeModal() {
    this.dialogRef.close();
  }
}
