import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class PrivacyService extends BaseAPI {

  getPrivacyRequestGrid(params: any) {
    return this.http.get('PrivacyRequest', { params });
  }

  resendPrivacyRequest(id: string) {
    return this.http.post(`PrivacyRequest/resend/${id}`, {});
  }
}
