<ng-container *ngIf="authStore.userLoggedInSignal(); else loggedOut">
  <footer
    class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3 pr-8"
  >
    <div>
      <span><p class="small">&copy; {{ date }} Mandated Reporter Training</p></span>
    </div>
    <div>
      <p class="small">
        <span class="mx-1 hidden lg:inline-block">|</span>
        <span class="mr-1">Powered by</span>
        <a href="https://simplelearning.com" target="_blank" class="primary-link">Simple</a>
      </p>
    </div>
  </footer>
</ng-container>


<ng-template #loggedOut>
  <footer
  class="border-t border-color-ddd flex flex-col lg:flex-row text-primary items-start lg:items-center justify-start lg:justify-center copywrite p-3"
  >
    <div class="flex flex-col lg:flex-row  w-full lg:w-auto text-center lg:text-left">
      <div>
        <span><p class="small"> &copy; {{ date }} Mandated Reporter Training</p></span>
      </div>
    </div>
    <div class="mr-12 flex w-full lg:w-auto justify-center">
      <p class="small">
        <a href="https://mandatedreportertraining.com/company/terms-of-use/" target="_blank" class="primary-link">
          <span class="mx-1 hidden lg:inline-block font-normal">|</span>Policies </a>
    </p>

    <p class="small hidden lg:inline-flex">
      <span class="mx-1 hidden sm:inline-block">|</span>
      <span class="mr-1">Powered by</span>
      <a href="https://simplelearning.com" target="_blank" class="primary-link">Simple</a>
      
      
      <span class="copywrite">
          <span class="mx-1 hidden sm:inline-block">|</span>
         v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }} 
        </span>
    </p>
    </div>

    <div class="lg:hidden w-full lg:w-auto text-center lg:text-left">
      <p class="small">
        <span class="mr-1">Powered by</span>
        <a href="https://simplelearning.com" target="_blank" class="primary-link">Simple</a>

        <br class="lg:hidden">
        <span class="copywrite">
          <span class="mx-1 hidden lg:inline-flex">|</span>
         v.{{ versionNumber.number }} rel. {{ versionNumber.date | date : 'MM.dd.yy' }} 
        </span>
      </p>
    </div>
  </footer>
</ng-template>
