import { Component, OnInit, ViewChild, AfterViewInit, OnDestroy, inject, effect } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { OrgAllService } from 'src/app/api/services/organizations/all/org-all.service';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { Subject, catchError, of, throwError } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AllCoursesService } from 'src/app/api/services/courses/all/all-courses.service';
import { AllCoursesStore } from 'src/app/api/services/courses/all/all-courses.store';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { Clipboard } from '@angular/cdk/clipboard';
import { ArchiveOrgComponent } from 'src/app/shared/modals/archive-org/archive-org.component';
import { CoopAllService } from 'src/app/api/services/cooperations/coop-all/coop-all.service';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MatTableDataSource } from '@angular/material/table';
import { SelectTrainingComponent } from 'src/app/shared/modals/select-training/select-training.component';
import { EntityCourseModalComponent } from 'src/app/shared/modals/entity-course-modal/entity-course-modal.component';
import { AddEnterpriseSubscriptionComponent } from 'src/app/shared/modals/add-enterprise-subscription/add-enterprise-subscription.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { AddAppointedCertificatesComponent } from 'src/app/shared/modals/add-appointed-certificates/add-appointed-certificates.component';
import { SubscriptionReportSerice } from 'src/app/api/services/reports/subscription-report/subscription-report.service';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { NgTemplateOutlet, NgIf, NgFor, DatePipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-organization-edit',
    templateUrl: './organization-edit.component.html',
    styleUrls: ['./organization-edit.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        MatTabsModule,
        NgTemplateOutlet,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        MatCardModule,
        MatToolbarModule,
        FileUploadModule,
        MatFormFieldModule,
        NgFor,
        MatIconModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,        
        MRTGridTemplateComponent,
        DatePipe,
        NgxPermissionsModule
    ],
})
export class OrganizationEditComponent implements OnInit, AfterViewInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  readonly allCoursesStore = inject(AllCoursesStore);
  courseFilterChange: any;
  maxPageSize = 1000;
  trialEnded!: Date;
  createDate!: Date;
  isOrganization: boolean = this.router.url.includes('organization');
  fromOrganizationPage: boolean = this.router.url.includes('organization')
  fromCooperativesPage: boolean = this.router.url.includes('cooperatives');
  fromEnterprisePage: boolean = this.router.url.includes('enterprise-subscriptions');
  disableFlag: boolean = false;
  showProfileImagePic: boolean = false;
  requiredImage: boolean = false;
  failImageFlag: boolean = false;
  failSizeFlag: boolean = false;
  failFlag: boolean = false;
  localImageUrl: any;
  profileImagePic: any;
  maxFileSize = 1024 * 1024;
  loader: boolean = false;
  landingPageEnabled = false;
  @ViewChild('selectedPicture') selectedPicture: any;
  @ViewChild(MatTabGroup) tabs!: MatTabGroup;
  clearSelectedPicture() {
    this.selectedPicture.nativeElement.value = '';
    this.showProfileImagePic = true;
    this.localImageUrl = this.profileImagePic;
  }
  entityAdminCount: any;
  entityAdminEmails!: string[];
  entityStatus: string = ''; 
  logoURL: any;
  organizationName: any;
  URLreg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  form = this.fb.group({
    name: this.fb.control(null, Validators.required),
    website: this.fb.control(null, [Validators.pattern(this.URLreg)]),
    industryId: this.fb.control(null),
    stateId: this.fb.control(null, Validators.required),
    hrContact: this.fb.control(null),
    hrEmail: this.fb.control(null, [Validators.email]),
    hrPhone: this.fb.control(null),
    publicLandingPageEnabled: this.fb.control(null),
    publicUserAssociationURL: this.fb.control(null),
  });

  allowAdd: boolean = false;
  public photoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpeg'],
    maxFileSize: this.maxFileSize,
  });
  responseObj: any;

  //Subscription Grid
  subscriptionGridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Created Date', controlName: 'createDate', displayPipe: 'date'},
      { header: 'Plan Name', controlName: 'planName'},
      { header: 'Plan Amount', controlName: 'planAmount' },
      { header: 'Status', controlName: 'numberOfSeats' },
      { header: '# of Active Users', controlName: 'numberOfActiveUsers' },
      { header: '# of Seats', controlName: 'numberOfSeats' },
      { header: 'Notes', controlName: 'notes' },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: true,
    isLoading: false,
    fetchData: false,
    hidepagination: true,
    displaySearch: true,
  };

  // ADHOC Add Certs to the organization
  appointCertsGridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Created Date', controlName: 'createdDate', displayPipe: 'date'},
      { header: 'Purchased Certs', controlName: 'purchasedCerts'},
      { header: 'Appointed Certs', controlName: 'appointedCerts' },
      { header: 'Modified By', controlName: 'createdByEmail' },
      { header: 'Detail', controlName: 'notes' },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: true,
    isLoading: false,
    fetchData: false,
    addButtonName: 'Add Appointed Certificates',
    hidepagination: true,
  };

  // Enterprise Entity Grid
  enterpriseGridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Created Date', controlName: 'createdDate', displayPipe: 'date'},
      { header: 'Start Date', controlName: 'startDate', displayPipe: 'date'},
      { header: 'Expire Date', controlName: 'expireDate', displayPipe: 'date'},
      { header: 'Active Users', controlName: 'numberOfActiveUsers'},
      { header: 'Seats', controlName: 'numberOfSeats' },
      { header: 'Status', controlName: 'enterpriseStatus' },
      { header: 'Edit', controlName: 'action',
        actionColumnitems: [
          { icon: 'edit', buttonAction: 'edit' },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: true,
    isLoading: false,
    fetchData: false,
    addButtonName: 'Add Enterprise',
    hidepagination: true,
  };

  // add training
  onDataLoad = false;
  showZeroOverlay = false;
  destroy$: Subject<boolean> = new Subject<boolean>();
  entityGuid: any;
  stateId: any;
  gridOptions: GridOptions = {
    filterData: [
    ],
    openFilters: false,
    columns: [
      { header: 'Name', controlName: 'name' },
      { header: 'Training Type', controlName: 'trainingType' },
      { header: 'State', controlName: 'selectedState' },
      { header: 'Status', controlName: 'status' },
      { header: 'Action', controlName: 'action',
        actionColumnitems: [
          { text : { 
              calculateText: (isActive: any) => { return isActive ? 'Archive' : 'Reactivate Training'; }, 
              textColumn: 'isActive'
            },
            buttonAction: 'edit'
          }],
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    frontEndPagination: true,
  };
  constructor(
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private orgAllService: OrgAllService,
    private coopAllService: CoopAllService,
    private route: ActivatedRoute,
    public allCoursesService: AllCoursesService,
    public courseApiService: CourseApiService,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private router: Router,
    private clipboard: Clipboard,
    private dialog: MatDialog,
    private subscriptionReportSerice: SubscriptionReportSerice,
  ) {
    effect(() => {
      const courses = this.allCoursesStore.coursesSignal();
      if (!courses || courses.length === 0) {
        return;
      }
      this.gridOptions.isLoading = false;
      this.gridOptions.data.filter = this.courseFilterChange?.search;

      // Apply filter criteria to the courses
      const filteredCourses = courses.filter((entity: any) => {
        const stateMatches = entity.states
          ? this.courseFilterChange?.state
            ? Object.values(entity.states).includes(this.courseFilterChange.state)
            : true
          : true;
    
        const statusMatches = this.courseFilterChange?.status
          ? this.courseFilterChange.status === 'archived'
            ? entity.isAdded === true && entity.isActive === false
            : entity.isActive === true
          : true;
        return stateMatches && statusMatches;
      });
      this.gridOptions.data.data = filteredCourses.filter(
        (course: any) => course.isActive || course.isAdded
      );
    });
  }

  integerValidator(control: any) {
    const value = control.value;

    if (value === null || value === undefined || value === '') {
      return null;
    }

    const isValid = Number.isInteger(Number(value));

    return isValid ? null : { 'notAnInteger': true };
  }

  ngOnInit(): void {
    this.lookupsService.getTrainings();
    this.lookupsService.getIndustries();
    this.lookupsService.getStates();

    // Fetch all settings for the organization with the id in the route
    this.fetchAll();
    this.fetchEnterprise();
    this.getSubscriptionData();
    this.getAppointedCertificates();

    this.photoUploader.onAfterAddingFile = (fileItem) => {
      this.requiredImage = false;
      if (this.photoUploader.queue.length > 1) {
        this.photoUploader.queue.splice(0, 1);
      }

      let url = window.URL
        ? window.URL.createObjectURL(fileItem._file)
        : (window as any).webkitURL.createObjectURL(fileItem._file);
      this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);

      this.failImageFlag = false;
      this.failSizeFlag = false;
      this.failFlag = false;
      this.showProfileImagePic = true;

      const imageFromData = new FormData();
      this.photoUploader.queue.map((file: any) => {
        imageFromData.append('file', file.file.rawFile);
      });
      this.form.markAsDirty();
      this.loader = true;
      this.orgAllService
        .uploadImage(imageFromData)
        .pipe(
          catchError((e) => throwError(() => e)),
          tap((url) => {
            if (
              url?.includes('.pjp') ||
              url?.includes('.jpg') ||
              url?.includes('.jpeg') ||
              url?.includes('.jfif') ||
              url?.includes('.pjpeg') ||
              url?.includes('.png')
            ) {
              this.showProfileImagePic = true;
              this.localImageUrl = url;
            }
          })
        )
        .subscribe({
          next: () => {
            this.loader = false;
          },
          error: (e) => {
            console.log(e);
            this.loader = false;
            this.showProfileImagePic = false;
          },
        });
    };
    this.photoUploader.onWhenAddingFileFailed = (fileItem) => {
      this.form.markAsDirty();
      let fileType = fileItem.type;
      let fileSize = fileItem.size;
      if (
        (fileType === 'image/png' ||
          fileType === 'image/jpeg' ||
          fileType === 'image/pjp' ||
          fileType === 'image/jpg' ||
          fileType === 'image/jfif' ||
          fileType === 'image/pjpeg') &&
        fileSize <= this.maxFileSize
      ) {
        this.failImageFlag = false;
        this.failSizeFlag = false;
        this.failFlag = false;
      } else {
        this.failImageFlag = true;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      }

      if (
        !(
          fileType === 'image/png' ||
          fileType === 'image/jpeg' ||
          fileType === 'image/pjp' ||
          fileType === 'image/jpg' ||
          fileType === 'image/jfif' ||
          fileType === 'image/pjpeg'
        )
      ) {
        this.failFlag = true;
        this.failSizeFlag = false;
        this.failImageFlag = false;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      } else {
        this.failFlag = false;
      }

      if (!(fileSize <= this.maxFileSize)) {
        this.failSizeFlag = true;
        this.failFlag = false;
        this.failImageFlag = false;
        this.photoUploader.clearQueue();
        if (this.showProfileImagePic) {
          this.localImageUrl = this.profileImagePic;
        }
      } else {
        this.failSizeFlag = false;
      }
    };
  }

  ngAfterViewInit() {
    this.route.queryParams.subscribe((params) => {
      if (params?.enterprise) {
        setTimeout(() => {
          this.tabs.selectedIndex = 3;
        },500);
      }
    });
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    this.allCoursesStore.resetStore();
  }

  update() {
    if (!this.form?.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          if (error.field === 'logoUrl') {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          } else {
            const control = this.form.get(error.field);
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message: error.message,
              });
            }
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }

      return of(null);
    };

    const formValues = {
      ...this.responseObj,
      ...this.form.value,
      logoUrl: this.localImageUrl,
    };

    
    if (!this.landingPageEnabled) {
      this.form.get('publicUserAssociationURL')?.disable();
    } else {
      this.form.get('publicUserAssociationURL')?.enable();
    }

    this.orgAllService
      .updateOrgAll(formValues)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          const paramsReq = {
            PageSize: 20,
            PageNumber: 1,
          };
          if (this.isOrganization) {
            this.orgAllService.getAll(paramsReq).subscribe();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Organization Information Updated' }})
          } else {
            this.coopAllService.all(paramsReq).subscribe();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Cooperative Information Updated' }})
          }
        },
        error: (e) => {
          console.log(e);
          validationErrorHandler(e);
        },
      });
  }

  goToUserAssociation(orgName: any) {
    if (this.isOrganization) {
      this.router.navigateByUrl(`/organizations/user-associations?&orgName=${encodeURIComponent(orgName)}&orgRole=c5c0bb73-5a63-40fd-a245-ff27a5a012eb`).then();
    } else {
      this.router.navigateByUrl(`/cooperatives/user-associations?&orgName=${encodeURIComponent(orgName)}&orgRole=7DE3E2FB-20F1-414D-B717-D6E2B2F5B7DB`).then();
    }
  }

  goToAllAdmins() {
    if (this.isOrganization) {
      this.router.navigateByUrl(`/organizations/all`).then();
    } else {
      this.router.navigateByUrl(`/cooperatives/all`).then();
    }
  }

  updateEntityStatus() {
    if (this.entityStatus === 'Active' && this.entityAdminCount > 0) {
      this.openModal(this.responseObj?.entityId, 'archived');
    } 
    else {
      // Admin wants to reactivate the organization, do this immediately
      const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
        if (validationErrors.length) {
          validationErrors.forEach((error: any) => {
            const control = this.form.get(error.field);
            if (control) {
              this.form.markAllAsTouched();
              control.setErrors({
                message: error.message,
              });
            }
          });
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
        }
        return of(null);
      };

      const formValues = {
        EntityId: this.responseObj?.entityId,
        EntityStatus: this.responseObj?.entityStatus === 'Active' ? 'Archived' : 'Active',
      };

      this.orgAllService.updateEntityStatus(formValues).subscribe({
        next: () => {
          this.entityStatus = this.entityStatus === 'Active' ? 'Archived' : 'Active';
          var msg = (this.entityStatus === 'Archived' ? 'Archived ' : 'Reactivated ') + " successfully";
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: msg }})
        },
        error: (e) => {
          console.log(e);
          validationErrorHandler(e);
        },
      });
    }
  }

  AllowAdd() {
    this.allowAdd = true;
  }

  clipBoard() {
    if (this.landingPageEnabled) {
      this.clipboard.copy(`${this.form.get('publicUserAssociationURL')?.value}`);
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Copied Link' }})
    }
  }

  // Open the modal to archive the organization
  openModal(id: any, action: any) {
    if (action === 'archived') {

      const dialogRef = this.dialog.open(ArchiveOrgComponent, {
        panelClass: 'full-screen-modal-medium-mobile-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          entityId: id,
          entityStatus: action,
          entityType: this.isOrganization ? 'Organization' : 'Cooperative',
        },
      });

       dialogRef.afterClosed().subscribe(() => {
         this.fetchAll();
       });
    }
  }

  fetchAll() {
    // Previously this was in ngOnInit and is now in its own function so that we may refresh the screen.   
    this.orgAllService
      .getSettings(this.route.snapshot.params?.id)
      .pipe(
        tap((data: any) => { 
          // add training
          this.entityGuid = data?.entityGuid; 
          this.stateId = data?.stateId;  
          this.reloadAllCourses();   

          this.isOrganization = data?.entityType === 'Organization' ? true : false;
          this.landingPageEnabled = data?.publicLandingPageEnabled;
          this.allowAdd = data?.createdDate ? true : false;
          const { id, entityId, entityGuid, entityAdminCount, entityStatus, shareableCertificate, industryId, stateId } = data;
          const items = [industryId, stateId];

          const result = items.map((item) => {
            if (item === 0 || item === '0') {
              return null;
            } else {
              return item;
            }
          });

          this.responseObj = {
            id,
            entityId,
            entityGuid,
            entityAdminCount,
            entityStatus,
            shareableCertificate,
          };

          this.logoURL = data?.logoUrl;
          this.trialEnded = data?.createDate
          this.createDate = data?.createDate
          this.entityAdminCount = data?.entityAdminCount;
          this.entityAdminEmails = data?.entityAdminEmails;
          this.entityStatus = data?.entityStatus;
          this.organizationName = data?.name;
          this.form?.patchValue({ ...data });

          if (
            data?.logoUrl?.includes('.pjp') ||
            data?.logoUrl?.includes('.jpg') ||
            data?.logoUrl?.includes('.jpeg') ||
            data?.logoUrl?.includes('.jfif') ||
            data?.logoUrl?.includes('.pjpeg') ||
            data?.logoUrl?.includes('.png')
          ) {
            this.localImageUrl = data?.logoUrl?.trim();
            this.profileImagePic = data?.logoUrl?.trim();
            this.showProfileImagePic = true;
          } else {
            this.showProfileImagePic = false;
          }

          const formValues = {
            industryId: result[0],
            stateId: result[1],
            publicUserAssociationURL: data?.landingPageShortUrl
          };

          if (!this.landingPageEnabled) {
            this.form.get('publicUserAssociationURL')?.disable();
          } else {
            this.form.get('publicUserAssociationURL')?.enable();
          }

          this.form.patchValue(formValues);
        })
      )
      .subscribe();
  }

  // ADHOC Add Certs to the organization
  openAddAppointedCertificatesModal(action?: string, item?: any) {
    const dialogRef = this.dialog.open(AddAppointedCertificatesComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: {
        action: action,
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe((val: any) => {
      if (val) {
        this.getAppointedCertificates();
      }
    });
  }

  getSubscriptionData(rawParams?: any) {
    this.subscriptionGridOptions.isLoading = true;
  rawParams = rawParams || {};
  const { pageSize = this.maxPageSize, pageNumber = 1} = rawParams;
  const params = {
    EntityId: this.route.snapshot.params?.id,
    PageSize: pageSize,
    PageNumber: pageNumber,
  };
    this.subscriptionReportSerice.getAll(params).subscribe((results: any) => {
      var gridDatasource: any[] = [];
      const { rows, totalNumberOfItems } = results;
      rows.forEach((row: any) => {
        const datasource = gridDatasource;
        datasource.push(row);
        gridDatasource = datasource;
      });
      this.subscriptionGridOptions.data.data = gridDatasource;
      this.subscriptionGridOptions.totalNumberOfItems = totalNumberOfItems;
      this.subscriptionGridOptions.isLoading = false;
    });
  }

  subscriptionEvent(event: any) {
  }
  
  // ADHOC Get Appointed Certificate entries
  getAppointedCertificates() {
    this.appointCertsGridOptions.isLoading = true;
    this.orgAllService.getAppointedCertificates(this.route.snapshot.params?.id).pipe(
      tap((data: any) => {
        this.appointCertsGridOptions.isLoading = false;
        this.appointCertsGridOptions.data.data = data;
      })
    ).subscribe();
  }

  // ADHOC Appointed Certs grid method
  appointCertsActionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.getAppointedCertificates();
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      // formChanges
    } else if (event.action === 'clearFilters') {
      this.getAppointedCertificates();
    } else if (event.action === 'addButton') {
    this.openAddAppointedCertificatesModal('Add', { entityId: this.route.snapshot.params?.id });
    }
  }

  // enterprise entity grid method
  fetchEnterprise() {
    this.enterpriseGridOptions.isLoading = true;
    this.orgAllService.getEntityEnterprise(this.route.snapshot.params?.id).pipe(
      tap((data: any) => {
        this.enterpriseGridOptions.isLoading = false;
        this.enterpriseGridOptions.data.data = data;
      })
    ).subscribe();
  }
  
  // enterprise entity grid method
  openEnterpriseSubscriptionModal(action: string, item?: any) {
    const dialogRef = this.dialog.open(AddEnterpriseSubscriptionComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: {
        action: action,
        item: item,
      },
    });
    dialogRef.afterClosed().subscribe((val: any) => {
      if (val) {
        this.fetchEnterprise();
      }
    });
  }

  // enterprise entity grid method
  enterpriseActionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchEnterprise();
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      // formChanges
    } else if (event.action === 'clearFilters') {
      this.fetchEnterprise();
    } else if (event.action === 'addButton') {
    this.openEnterpriseSubscriptionModal('Add', { entityId: this.route.snapshot.params?.id });
    }
    // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.openEnterpriseSubscriptionModal('Edit', event.data);
    }
  }

  // add training method
  reloadAllCourses(change?: any) {
    this.courseFilterChange = change;
    this.gridOptions.isLoading = true;
    if (this.entityGuid) {
      this.allCoursesService
        .reloadCourses(this.entityGuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe((data: any) => {
          this.onDataLoad = true;
          if (data.some((x: any) => x.isAdded || x.isActive)) {
            this.showZeroOverlay = false;
            this.gridOptions.addButtonName = 'Add Training';
          } else {
            this.showZeroOverlay = true;
          }
        });
    }   
  }

  // add training method
  openSelectTrainingModal() {
    const dialogRef = this.dialog.open(SelectTrainingComponent, {
      panelClass: 'full-screen-modal-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: {
        entityGuid: this.entityGuid,
        stateId: this.stateId
      },
    });
    dialogRef.afterClosed().subscribe((val: any) => {
      if (val) {
        this.reloadAllCourses();
      }
    });
  }

  // add training method
  editTrainingStatusModal(course: any) {
    const dialogRef = this.dialog.open(EntityCourseModalComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: {
        buttonAction: course.isActive? 'archive-training' : 'add-training',
        course,
        entityGuid: this.entityGuid,
      },
    });
    dialogRef.afterClosed().subscribe((val: any) => {
      if (val) {
        this.reloadAllCourses();
      }
    });
  }

  // add training method
  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.reloadAllCourses(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      // formChanges
    } else if (event.action === 'clearFilters') {
      this.reloadAllCourses();
    } else if (event.action === 'addButton') {
      this.openSelectTrainingModal();
    }
    // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.editTrainingStatusModal(event.data);
    }
  }
}
