import { AfterContentInit, ChangeDetectorRef, Component, Injector, Input, OnInit } from '@angular/core';
import { FormControl, NG_VALUE_ACCESSOR, NgControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BaseControlValueAccessor } from '../../base-control-value-accessor';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-form-field',
    templateUrl: './form-field.component.html',
    styleUrls: ['./form-field.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: FormFieldComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
    ],
})
export class FormFieldComponent extends BaseControlValueAccessor<string> implements OnInit, AfterContentInit {
  masker: any;
  hide: boolean = true;
  @Input() label = '';
  @Input() type: 'zip' | 'text' | 'zip-code' | any = 'text';
  @Input() startHint?: string;
  @Input() endHint?: string;
  @Input() suffix?: string;
  @Input() prefix?: string;
  @Input() additionalClass?: string;
  @Input() pre: string = '';
  @Input() step?: string;
  @Input() min?: string;
  @Input() max?: string;
  @Input() autocomplete?: string;
  @Input() specialCharaters?: boolean = false;
  @Input() showPassword?: boolean = false;
  @Input() fieldMatch?: string;
  @Input() patternErrorMessage?: string;
  @Input() showErrorMsg?: boolean = false;
  @Input() placeholder?: string;
  @Input() maxlength?: any;

  control!: FormControl;

  constructor(private injector: Injector, private cdRef: ChangeDetectorRef) {
    super();
  }

  ngAfterContentInit(): void {
    const ngControl: NgControl = this.injector.get(NgControl, null) as NgControl;
    if (ngControl) {
      this.control = ngControl.control as FormControl;
    } else {
    }

    this.cdRef.detectChanges();
  }

  ngOnInit(): void {}

  get inputType() {
    switch (this.type) {
      case 'num':
      case 'number':
        return 'num';
      case 'phone':
        return 'num';
      case 'text':
        return 'text';
      case 'password':
        return 'password';
      case 'ssn':
        return 'text';
      case 'rate':
        return 'text';
      case 'fax':
        return 'text';
      case 'year':
        return 'text';
      case 'step-number':
        return 'number';
      default:
        return 'text';
    }
  }

  get mask() {
    switch (this.type) {
      case 'phone':
        return '(000) 000-0000';
      case 'num':
      case 'number':
        return '0*';
      case 'ssn':
        return '000-00-0000';
      case 'rate':
        return '0*.00';
      case 'zip':
        return '00000';
      case 'zip-code':
        return '00000-0000';
      case 'fax':
        return '0000000000';
      case 'year':
        return '0000';
      default:
        return null;
    }
  }

  public get invalid(): boolean | null {
    return this.control ? this.control?.invalid : false;
  }

  public get showError(): boolean | null {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    return this.invalid ? dirty || touched : false;
  }

  handleChange(value: any) {
    this.value = value;
    this.onChange(value);
  }

  showPasswordHandler() {
    this.hide = !this.hide;
  }
}
