import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  constructor(private _snackBar: MatSnackBar) {}

  public error(msg: string) {
    this._snackBar.openFromComponent(SnackbarComponent, { duration: 5000, data: { toastType: ToastType.Error, message: msg }})
  }

  public notify(msg: string) {
    this._snackBar.openFromComponent(SnackbarComponent, { duration: 5000, data: { toastType: ToastType.Error, message: msg }})
  }

  public notifyFormValidationError() {
    this.notify('Form not valid. Please fix validation errors and try again.');
  }
}
