import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { appMessageService } from 'src/app/api/services/app-messages/app-message.service';
import { BannerMessageEditComponent } from '../../../shared/modals/banner-message-edit/banner-message-edit.component';
import { DashboardAdMessageEditComponent } from '../../../shared/modals/dashboard-ad-message-edit/dashboard-ad-message-edit.component';
import { AddMessageComponent } from 'src/app/shared/modals/add-message/add-message.component';
import { LoginMessageEditComponent } from 'src/app/shared/modals/login-message-edit/login-message-edit.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-app-messages',
    templateUrl: './app-messages.component.html',
    styleUrls: ['./app-messages.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class AppMessagesComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  paramsReq: any;
  defaultSortColumn: string = 'startDate';
  defaultSortDirection: string = 'DESCENDING';
  dataInit: any;
  
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: 0 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Type', formControlName: 'typeId', type: 'dropdown', lookup: this.lookupsStore.uiNotificationTypesSignal },
      { name: 'Audience', formControlName: 'audienceId', type: 'dropdown', lookup: this.lookupsStore.rolesSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Start Date', controlName: 'startDate', displayPipe: 'date' },
      { header: 'Type', controlName: 'type' },
      { header: 'Title', controlName: 'title' },
      { header: 'Primary CTA', controlName: 'buttonText', displayPipe: 'truncate' },
      { header: 'Audience', controlName: 'audience' },
      { header: 'End Date', controlName: 'endDate',  displayPipe: 'date' },
      { header: 'Action', controlName: 'action', actionColumnitems: [{ icon: 'edit', buttonAction: 'edit' }] },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    addButtonName: 'Add App Message',
    disableflag: false,
    allowExportCSV: false,
    isLoading: false,
    frontEndPagination: true,
    displaySearch: true,
  };
  types: any;
  constructor(
    private dialog: MatDialog,
    private lookupsService: LookupsService,
    private appMessageService: appMessageService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getRoles();
    this.lookupsService.getUiNotificationTypes();
    this.gridOptions.fetchData = true;
  }
  
  fetchAll() {
    var gridDatasource: any[] = [];
    this.appMessageService
    .getNotificationsGrid()
    .subscribe((data) =>{
      if (Array.isArray(data)) {
        data.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
      } else {
        console.error('Data is not an array:', data);
      }
      this.gridOptions.data.data = gridDatasource;
      this.dataInit = gridDatasource;
      this.gridOptions.isLoading = false;
    });
  }

  applyFilter(rawParams?: any) {
    this.gridOptions.data.data = this.dataInit;
    let filteredData = this.dataInit;


    if (rawParams.typeId) { 
      filteredData = filteredData.filter((item: any) => {
        return item.type == rawParams.typeId;
      });
    }
  


    if (rawParams.startDate && rawParams.endDate) {

      const fromDate = new Date(rawParams.startDate).getTime(); 
      const toDate = new Date(rawParams.endDate).getTime(); 
 
      filteredData = filteredData.filter((item: any) => {
        const itemStartDate = new Date(item.startDate).getTime(); 
        const itemEndDate = new Date(item.endDate).getTime(); 
    
        return itemStartDate < toDate && itemEndDate > fromDate;
      });

    }

    if (rawParams.audienceId) { 
      const role = this.lookupsStore.rolesSignal().filter(role => role.value === rawParams.audienceId)[0];
      var audienceFilterName = role ? role.name : '';
      filteredData.filter((item: any) => {
        const audienceArray = item.audience.split(", ").map((role: any) => role.trim());
        return audienceArray.some((roleName: any) => audienceFilterName.includes(roleName));
      });
    }

    this.gridOptions.data.data = filteredData;
    this.gridOptions.data.filter = rawParams.search?.trim().toLowerCase();
  }
  

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'startDate':
          activeSort = 'StartDate';
          break;
        case sort.active === 'typeId':
          activeSort = 'TypeId';
          break;
        case sort.active === 'primaryCta':
          activeSort = 'ButtonText';
          break;
        case sort.active === 'audienceId':
          activeSort = 'AudienceId';
          break;
        case sort.active === 'stateId':
          activeSort = 'State';
          break;
        case sort.active === 'endDate':
          activeSort = 'EndDate';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }



  goToEdit(action: 'add' | 'edit', row: any) {


    if ( row.type === 'Banner') {
      const dialogRef = this.dialog.open(BannerMessageEditComponent, {
        panelClass: 'full-screen-modal-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          action: action,
          items: row,
          messageType: 'Banner'
        },
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.fetchAll();
          this.gridOptions.isLoading = false;
        }
      });
    } else  if( row.type === 'DashboardAd') {
      const dialogRef = this.dialog.open(DashboardAdMessageEditComponent, {
        panelClass: 'full-screen-modal-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          action: action,
          items: row,
          messageType: 'DashboardAd',
          uploadContainer: 'dashboardAdContainer'
        },
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.fetchAll();
        }
      });
    } else  if( row.type === 'LoginAd') {
      const dialogRef = this.dialog.open(LoginMessageEditComponent, {
        panelClass: 'full-screen-modal-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          action: action,
          items: row,
          messageType: 'LoginAd',
          uploadContainer: 'loginAdContainer'
        },
      });
      dialogRef.afterClosed().subscribe((response) => {
        if (response) {
          this.fetchAll();
        }
      });
    }

  }


  openAddAppMessagesModal() {
    const dialogRef = this.dialog.open(AddMessageComponent, {
      panelClass: 'full-screen-modal-panel-class',
      data: { items: '' },
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.fetchAll();
        this.gridOptions.isLoading = false;
      }
    });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll();
    } else if (event.action === 'exportCSV') {
      // export CSV
    } else if (event.action === 'formChanges') {
      // form changes
      this.applyFilter(event.data);
    } else if (event.action === 'clearFilters') {
      this.gridOptions.data.data = this.dataInit;
      // clear filters
    } else if (event.action === 'addButton') {
      this.openAddAppMessagesModal();
    }
    // 'edit' is specific to this component
    else if (event.action === 'edit') {
      this.goToEdit('edit', event.data);
    }
  }
}
