import { inject, Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ValidationErrors } from '../interfaces/validation-errors';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { Stores } from '../services/stores';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  stores = inject(Stores);
  constructor(
    private _snackBar: MatSnackBar,
    private router: Router,
  ) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        switch (error.status) {
          case 500:
            const jwt = window.localStorage.getItem('token');
            if(request.method.toLowerCase() !== "get")
            {
              if(jwt){
                const decodedJWT: any = jwtDecode(jwt);
                if(decodedJWT.UseReportingDatabase === 'true'){
                  this._snackBar.openFromComponent(SnackbarComponent, { duration: 5000, data: { toastType: ToastType.Error, message: 'Data modification not allowed on the Reporting Database!' }})
                  break;
                }
              }
            }
            this._snackBar.openFromComponent(SnackbarComponent, { duration: 5000, data: { toastType: ToastType.Error, message: 'An error occurred, please try again. If the problem persists, click the Help button for technical support.' }})
            break;

          case 401:
            localStorage.removeItem('token');
            this.stores.resetStores();
            window.localStorage.removeItem('course-module');
            window.localStorage.removeItem('entityGuid');
            window.localStorage.removeItem('entityType');
            window.localStorage.removeItem('roleType');
            this.router.navigateByUrl('/authentication/login');

            break;

          case 400:
            if (error?.error?.title === 'One or more validation errors occurred.') {
              const validationErrors: ValidationErrors = Object.entries(error.error.errors).map(([key, v]) => {
                const value = v as string[];

                let errorMessage = value.join(', ');

                if (key === 'password') {
                  errorMessage = `Passwords must be at least 6 characters and have at least one non alphanumeric character, one digit ('0'-'9'), and one uppercase ('A'-'Z').`;
                }

                return {
                  field: key,
                  message: errorMessage,
                };
              });

              return throwError(validationErrors);
            }

            const identityValidationError = error.error?.['']?.[0];
            if (identityValidationError && identityValidationError.includes(' is already taken.')) {
              const validationErrors: ValidationErrors = [{ field: 'email', message: identityValidationError }];

              return throwError(validationErrors);
            }
            break;

          case 0:
            this._snackBar.openFromComponent(SnackbarComponent, { duration: 5000, data: { toastType: ToastType.Error, message: 'An error occurred, please try again. If the problem persists, click the Help button for technical support.' }})
        }
        return throwError(error);
      })
    );
  }
}
