import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AddFileModalComponent } from 'src/app/shared/modals/add-file-modal/add-file-modal.component';
import { MatIconModule } from '@angular/material/icon';
import { NgIf, NgFor } from '@angular/common';

/**
 * Course Files Component
 * @notused This was an experimental component that is not being used.
 */
@Component({
    selector: 'app-course-files',
    templateUrl: './course-files.component.html',
    styleUrls: ['./course-files.component.scss'],    
    standalone: true,
    imports: [
        NgIf,
        MatIconModule,
        NgFor,
    ],
})
export class CourseFilesComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  constructor(public lookupsService: LookupsService, private dialog: MatDialog) {}

  ngOnInit() {
    this.lookupsService.getCertFiles();
    this.lookupsService.getBadgeFiles();
  }

  addBadgeFile() {
    this.dialog.open(AddFileModalComponent, { data: { uploadContainer: 'coursebadges' } });
  }
}
