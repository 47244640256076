<div class="flex flex-col h-full">
  <div class="text-right justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="admin-modal-body">
    <div class="px-12 py-8">
      <div class="text-center px-5 py-3">
          <h2 class="font-bold mb-5">{{ action + ' Dashboard Message' }}</h2>
      </div>
      <form [formGroup]="form" class="mt-8 notification-form">
        <div>
          <div class="w-full">
            <h3 class="learner">Content</h3>
          </div>

          <div class="w-full flex lg:gap-8">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Title" matInput formControlName="title" />
                <mat-error *ngIf="form.controls['title'].hasError('required') && form.controls['title'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Image URL" matInput formControlName="imageUrl" />
                <mat-error *ngIf="form.controls['imageUrl'].hasError('required') && form.controls['imageUrl'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w-full flex lg:gap-8 mt-4 justify-end">
            <div class="w-full lg:w-1/2">
              <label for="photos">Upload New Image</label>
              <div class="flex sm:flex-row flex-col w-full pt-4">
                <div>
                  <label for="photos" class="mb-1">
                    <input
                      type="file"
                      hidden
                      class="text-black"
                      id="photos"
                      accept="image/png, image/jpeg, image/jpg, image/jfif, image/pjpeg, image/pjp"
                      ng2FileSelect
                      [uploader]="photoUploader"
                      #selectedPicture
                    />
                    <div class="flex items-center">
                      <div class="btn-primary-outlined small text-center mb-1">Browse</div>
                      <div class="lds-ring" *ngIf="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <mat-error *ngIf="requiredImage"> Required </mat-error>
                  </label>
                  <div *ngIf="failImageFlag" class="{{ failImageFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error">
                      We accept only jpg, png, jpeg, jfif, pjpeg, pjp files and file size must be less than 1mb</mat-error
                    >
                  </div>
                  <div *ngIf="failSizeFlag" class="{{ failSizeFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error"> file size must be less than 1mb </mat-error>
                  </div>
                  <div *ngIf="failFlag" class="{{ failFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error"> We accept only jpg, png, jpeg, jfif, pjpeg, pjp files </mat-error>
                  </div>
                  <div *ngFor="let item of photoUploader.queue">
                    <div class="mb-1 w-full flex flex-wrap gap-1 items-center">
                      <p class="mb-0">
                        {{ item?.file?.name }}
                      </p>
                      <mat-icon class="cursor-pointer text-secondary" (click)="item.remove(); clearSelectedPicture()"
                        >delete</mat-icon
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full">
              <mat-form-field class="w-full">
                <textarea
                  placeholder="Message"
                  matInput
                  formControlName="description"
                  style="height: 90px"
                ></textarea>
                <mat-error *ngIf="form.controls['description'].hasError('required') && form.controls['description'].touched"
                >Required
              </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="w-full mt-10">
            <h3 class="learner">Primary CTA</h3>
          </div>
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button Text" matInput formControlName="buttonText" />
                <mat-error *ngIf="form.controls['buttonText'].hasError('required') && form.controls['buttonText'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button Url" matInput formControlName="buttonUrl" />
                <mat-error *ngIf="form.controls['buttonUrl'].hasError('required') && form.controls['buttonUrl'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>


          <div class="w-full mt-10">
            <h3 class="learner">Secondary CTA</h3>
          </div>
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full">
              <mat-form-field class="w-full">
                <input placeholder="Button2 Title" matInput formControlName="button2Title" />
                <mat-error
                  *ngIf="form.controls['button2Title'].hasError('required') && form.controls['button2Title'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button2 Text" matInput formControlName="button2Text" />
                <mat-error
                  *ngIf="form.controls['button2Text'].hasError('required') && form.controls['button2Text'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <input placeholder="Button2 Url" matInput formControlName="button2Url" />
                <mat-error *ngIf="form.controls['button2Url'].hasError('required') && form.controls['button2Url'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>



          <div class="w-full mt-10">
            <h3 class="learner">Display Criteria</h3>
          </div>
          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2 lg:gap-8 flex">
              <div class="w-full sm:w-1/4">
                <mat-checkbox class="py-3" formControlName="showEntityAdmin">Show Entity Admin</mat-checkbox>
              </div>

              <div class="w-full sm:w-1/4">
                <mat-checkbox class="py-3" formControlName="showCoopAdmin">Show Coop Admin</mat-checkbox>
              </div>

              <div class="w-full sm:w-1/4">
                <mat-checkbox class="py-3" formControlName="showOrgLearner">Show Org Learner</mat-checkbox>
              </div>

              <div class="w-full sm:w-1/4">
                <mat-checkbox class="py-3" formControlName="showIndividual">Show Individual</mat-checkbox>
              </div>
            </div>

            <div class="w-full lg:w-1/2">
              <mat-form-field class="w-full">
                <mat-label>Display Page</mat-label>
                <mat-select formControlName="sitePages" panelClass="mat-select-mt3">
                  <mat-option *ngFor="let page of lookupsStore.sitePagesSignal()" [value]="page.value">{{ page.name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="form.controls['sitePages'].hasError('required') && form.controls['sitePages'].touched"
                >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>

          <div class="w-full flex lg:gap-8 mt-4">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 w-full">
                <mat-label>Start Date</mat-label>
                <input matInput formControlName="startDate" [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                <mat-error *ngIf="form.controls['startDate'].hasError('required') && form.controls['startDate'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 w-full">
                <mat-label>End Date</mat-label>
                <input matInput formControlName="endDate" [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                <mat-error *ngIf="form.controls['endDate'].hasError('required') && form.controls['endDate'].touched"
                  >Required
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="w-full flex lg:gap-8 justify-center mt-8">
          <div class="w-full text-center lg:w-1/2">
            <button (click)="onSubmit()" class="btn-primary large" type="submit" [disabled]="disableFlag">
              {{ action === 'Edit' ? 'Edit App' : 'Add' }}  Message
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
