import { Component, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { AddModuleComponent } from 'src/app/shared/modals/add-module/add-module.component';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { FormGroup } from '@angular/forms';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-modules-all',
    templateUrl: './modules-all.component.html',
    styleUrls: ['./modules-all.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class ModulesAllComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'ASCENDING';
  dataInit: any;
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'fromDate', type: 'date', dateOffset: -365 },
        { name: 'To', formControlName: 'toDate', type: 'date', dateOffset: 0 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Type', formControlName: 'scormType', type: 'dropdown', lookup: this.lookupsStore?.scormTypesSignal },
      ],
      openFilters: false,
      columns: [
        { header: 'Date Created', controlName: 'createDate', displayPipe: 'date'},
        { header: 'Module Name', controlName: 'moduleName' },
        { header: 'Scorm Name', controlName: 'scormFullName' },
        { header: 'Package Name', controlName: 'scormShortName' },
        { header: 'Duration', controlName: 'scormDuration' },
        { header: 'Type', controlName: 'scormType' },
        { header: 'Created By', controlName: 'createdBy' },
        { header: 'Action', controlName: 'action',
            actionColumnitems: [
              { buttonAction: 'edit', conditionalIcon: { calculateIcon: (isReadOnly: any) => { return isReadOnly  ? 'remove_red_eye' : 'edit'; }, iconColumn: 'isReadOnly' } },
            ]
         },
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      addButtonName: 'Add New Module',
      allowExportCSV: false,
      disableflag: false,
      isLoading: false,
      frontEndPagination: true,
      displaySearch: true,
    };
    filtersForm!: FormGroup;

  constructor(
    private dialog: MatDialog,
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getScormTypes();
    this.gridOptions.fetchData = true;
  }

  openDialog(action: 'add' | 'edit') {
    const dialogRef = this.dialog.open(AddModuleComponent, {
      panelClass: 'full-screen-modal-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: { action }
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.fetchAll();
      }
    });
  }

  fetchAll() {
    this.gridOptions.isLoading = true;
    this.coursesService.getModulesGrid().subscribe((res: any) => {  
      this.dataInit = res;
      this.gridOptions.data.data = res;
      this.gridOptions.totalNumberOfItems = res.length;
      this.gridOptions.isLoading = false;
    });
  }

  applyFilter(rawParams?: any) {
    this.gridOptions.data.data = this.dataInit;
    let filteredData = this.dataInit;

    if(rawParams.scormType) { 
      this.gridOptions.data.data = this.dataInit.filter((item: any) => {
        return item.scormTypeId === rawParams.scormType;
      });
    }

    if (rawParams.fromDate && rawParams.toDate) {
      const fromDate = new Date(rawParams.fromDate);
      const toDate = new Date(rawParams.toDate);
  
      filteredData = filteredData.filter((item: any) => {
        const itemDate = new Date(item.createDate);
        return itemDate >= fromDate && itemDate <= toDate;
      });
    }
  
    this.gridOptions.data.data = filteredData;
    this.gridOptions.data.filter = rawParams.search?.trim().toLowerCase();
  }


  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll();
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.applyFilter(event.data);
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      this.openDialog('add');
    } // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.openDialog(event.data );
    }
  }
}
