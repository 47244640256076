import { Component, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, ValidatorFn, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, tap } from 'rxjs/operators';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatSnackBar } from '@angular/material/snack-bar';
import { of, throwError } from 'rxjs';
import { ApiService } from 'src/app/api/services/api.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { GrecaptchaService } from 'src/app/shared/services/grecaptcha.service';
import { FormFieldComponent } from '../../../../form/components/form-field/form-field.component';

@Component({
    selector: 'app-forgot',
    templateUrl: './forgot.component.html',
    styleUrls: ['./forgot.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, FormFieldComponent, RouterLink]
})
export class ForgotComponent implements OnInit {

  public forgotForm: FormGroup = Object.create(null);
  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private _api: ApiService,
    private reCaptchaService: GrecaptchaService,
  ) {}

  ngOnInit(): void {
    this.forgotForm = this.fb.group({
      email: this.fb.control(null, Validators.required),
    });
  }
  disableFlag: boolean = false;
  onSubmit() {
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        // @ts-ignore
        validationErrors.forEach((error) => {
          const control = this.forgotForm.get(error.field);
          if (control) {
            this.forgotForm.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors.error) {
        const control = this.forgotForm.get('email');
        if (control) {
          this.forgotForm.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error.user[0],
          });
        }
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${validationErrors?.error.user[0]}` }})
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }

      return of(null);
    };

    if (!this.forgotForm.valid) {
      this.forgotForm.markAllAsTouched();
      return;
    }
    this.disableFlag = true;
    const email = this.forgotForm.get('email')?.value;
    const resetPasswordUrl = window.origin + '/authentication/reset';
    const app = 'Admin';

    this.reCaptchaService.execute('forgotPassword').then((reCaptchaToken: any) => {
      this._api.auth
        .forgotPassword({ email, resetPasswordUrl, reCaptchaToken, app })
        .pipe(catchError((error) => throwError(() => error)))
        .subscribe({
          next: () => {
            this.disableFlag = false;
            this.router.navigateByUrl(`/authentication/checkinbox?email=${email}&forgot=true`);
          },
          error: (error) => {
            validationErrorHandler(error);
            this.disableFlag = false;
          },
        });
      })
    }

}
