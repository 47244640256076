export interface LookupEntry {
  label: string;
  value: any;
  [key: string]: any;
}

export type Lookups = {
  userScormtrackStatusSignal: LookupEntry[];
  entityStatusSignal: LookupEntry[];
  industriesSignal: LookupEntry[];
  languagesSignal: LookupEntry[];
  pricingPlansSignal: LookupEntry[];
  stripeSubscriptionStatusSignal: LookupEntry[];
  statesSignal: LookupEntry[];
  coursesSignal: LookupEntry[];
  coursesFilteredSignal: LookupEntry[];
  courseTrainingsSignal: LookupEntry[];
  courseStatesByCourseIdSignal: LookupEntry[];
  courseVersionsSignal: LookupEntry[];
  trainingsSignal: LookupEntry[];
  learnerStatusSignal: LookupEntry[];
  orgCoopSignal: LookupEntry[];
  activeOrgCoopSignal: LookupEntry[];
  rolesSignal: LookupEntry[];
  orgRolesSignal: LookupEntry[];
  orgUserInvitesStatusSignal: LookupEntry[];
  scormTypesSignal: LookupEntry[];
  modulesSignal: LookupEntry[];
  learningPathSignal: LookupEntry[];
  privacyRequestTypesSignal: LookupEntry[];
  certFilesSignal: LookupEntry[];
  badgeFilesSignal: LookupEntry[];
  uiNotificationTypesSignal: LookupEntry[];
  sitePagesSignal: LookupEntry[];
  orgPurchaseTypesSignal: LookupEntry[];
  userOptOutNotificationTypesSignal: LookupEntry[];
  professionsSignal: LookupEntry[];
  jobTitlesSignal: LookupEntry[];
};

export const initialState: Lookups = {
  userScormtrackStatusSignal: [],
  entityStatusSignal: [],
  industriesSignal: [],
  languagesSignal: [],
  pricingPlansSignal: [],
  stripeSubscriptionStatusSignal: [],
  statesSignal: [],
  coursesSignal: [],
  coursesFilteredSignal: [],
  courseTrainingsSignal: [],
  courseStatesByCourseIdSignal: [],
  courseVersionsSignal: [],
  trainingsSignal: [],
  learnerStatusSignal: [],
  orgCoopSignal: [],
  activeOrgCoopSignal: [],
  rolesSignal: [],
  orgRolesSignal: [],
  orgUserInvitesStatusSignal: [],
  scormTypesSignal: [],
  modulesSignal: [],
  learningPathSignal: [],
  privacyRequestTypesSignal: [],
  certFilesSignal: [],
  badgeFilesSignal: [],
  uiNotificationTypesSignal: [],
  sitePagesSignal: [],
  orgPurchaseTypesSignal: [],
  userOptOutNotificationTypesSignal: [],
  professionsSignal: [],
  jobTitlesSignal: []
};