<div class="flex flex-col h-full">
  <div class="text-right justify-end close-modal-button-pos">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <ng-container [ngSwitch]="currentView">
    <ng-template ngSwitchDefault>
        <div class="admin-modal-body site-container-1340 h-full">
            <h1 class="learner text-primary text-center w-full font-bold">Add App Message</h1>
        
            <div class="w-full flex flex-col lg:flex-row justify-center lg:gap-8 mt-[4rem]">
              <div class="w-full lg:w-1/3">
                <div class="add-message-tile">
                  <div> 
                    <div class="image-wrapper mb-5 text-center">
                      <img src="assets/images/app-messages/manual-icon.svg" alt="outlined image of a checklist">
                    </div>
                    <h2 class="learner text-center">Banner Message</h2>
                    <p class="pt-3 large description">Add a new banner message for Maintenance, Upgrades, or Subsrciptions. </p>
                  </div>
        
                  <div class="py-3 text-center">
                    <button class="btn-primary-outlined medium" (click)="openAddBannerModel()">Banner Message</button>
                  </div>
                </div>
              </div>
        
              <div class="w-full lg:w-1/3">
                <div class="add-message-tile">
                  <div>
                    <div class="image-wrapper mb-5 text-center">
                      <img src="assets/images/app-messages/file-upload-icon.svg" alt="outlined image of a document">
                    </div>
                    <h2 class="learner text-center">Dashboard Message</h2>
                    <p class="pt-3 large description">Add a new  dashboard message or advertisement.</p>
                  </div>
        
                  <div class="py-3 text-center">
                    <button class="btn-primary-outlined medium" (click)="openAddDashboardAdModel()">
                      Dashboard Message
                    </button>
                  </div>
                </div>
              </div>
                 

               <div class="w-full lg:w-1/3">
                <div class="add-message-tile">
                  <div>
                    <div class="image-wrapper mb-5 text-center">
                      <img src="assets/images/app-messages/webpage-icon.svg" alt="outlined image of a a matrix dashboard">
                    </div>
                    <h2 class="learner text-center">Login Message</h2>
                    <p class="pt-3 large description">Add a new message users will see when they log in. </p>
                  </div>
        
                  <div class="py-3 text-center">
                    <button class="btn-primary-outlined medium" (click)="openAddLoginAdModel()">
                      Login Message
                    </button>
                  </div>
                </div>
              </div> 
            </div>
          </div>
    </ng-template>

    <ng-template [ngSwitchCase]="'bannerMessage'">
      <app-banner-message-edit class="h-full"></app-banner-message-edit>
    </ng-template>

   
    <ng-template [ngSwitchCase]="'dashboardAdMessage'">
      <app-dashboard-ad-message-edit class="h-full"></app-dashboard-ad-message-edit>
    </ng-template>


    <ng-template [ngSwitchCase]="'loginAdMessage'">
      <app-login-message-edit class="h-full"></app-login-message-edit>
    </ng-template>
  </ng-container>

  
</div>
