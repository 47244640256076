import { Component, OnInit, OnDestroy, computed, inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { catchError, tap, throwError, of, Subject } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ApiService } from 'src/app/api/services/api.service';
import { CoopAllService } from 'src/app/api/services/cooperations/coop-all/coop-all.service';
import { CoopUserAssociationService } from 'src/app/api/services/cooperations/user-association/coop-user-association.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-add-user-to-cooperative',
  templateUrl: './add-user-to-cooperative.component.html',
  styleUrls: ['./add-user-to-cooperative.component.scss'],
  standalone: true,
  imports: [
    MatIconModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatInputModule, 
    MatAutocompleteModule, 
    NgFor, 
    MatOptionModule, 
    NgIf, 
    NgClass, 
    MatSelectModule, 
    MatDialogModule, 
    NgxPermissionsModule
  ]
})

export class AddUserToCooperativeComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup = this.fb.group({
    cooperative: this.fb.control('', Validators.compose([Validators.required])),
    userEmail: this.fb.control('', Validators.compose([Validators.required, Validators.email])),
    coopRole: this.fb.control(null, Validators.compose([Validators.required])),
  });
  roles = computed(() => {
    return this.lookupsStore.rolesSignal().filter((role) => role.name === 'CoopAdmin' || role.name === 'Learner');
  });
  cooperativeOptions: any[] = [];
  verifyEmail: boolean = false;
  validEmailSpinner: boolean = false;
  disableAssociateCoop: boolean = false;
  cooperativeEntityGuid!: string | null;

  constructor(
    private fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddUserToCooperativeComponent>,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private api: ApiService,
    private coopAllService: CoopAllService,
    private coopUserAssociationService: CoopUserAssociationService
  ) {}

  ngOnInit(): void {
    this.lookupsService.getRoles();
    this.cooperativeLookup("");
    this.form.get('cooperative')?.valueChanges.subscribe((val) => {
      if (typeof val === 'string' && val.length >= 3) {
        this.cooperativeEntityGuid = null;
        this.cooperativeLookup(val);
        
      } else {
        this.cooperativeEntityGuid = val.value;
        this.checkEmailInCoop(this.form.get('userEmail')?.value);
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkEmail(email: any, control: any) {
    this.verifyEmail = false;

    if (control?.errors === null) {
      this.validEmailSpinner = false;
      this.coopAllService.emailExists(email.target.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (!data.userExists) {
              control.markAsTouched();
              control.setErrors({
                message: `Email Not Registed`,
              });
            }
            this.validEmailSpinner = false;
            this.checkEmailInCoop(email.target.value);
          },
          (error) => {
            this.validEmailSpinner = false;
          }
        );
    }
  }

  checkEmailInCoop(email: any) {
    var control = this.form.controls['userEmail'];
    if (control?.errors === null && this.cooperativeEntityGuid && email) {
      this.validEmailSpinner = false;
      this.coopAllService.entityUserEmailExist(email, this.cooperativeEntityGuid)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (data) {
              control?.markAsTouched();
              control?.setErrors({
                message: `Email Already Associated with Cooperative`,
              });
            }
            this.validEmailSpinner = false;
          },
          (error) => {
            this.validEmailSpinner = false;
          }
        );
    }
  }

  cooperativeLookup(val: any) {
    this.api.lookups
    .getCooperative(val)
    .pipe(
      catchError((e) => throwError(() => e)),
      tap((data: any) => {
        if (data) {
          this.cooperativeOptions = data;
        }
      })
    )
    .subscribe({
      next: () => {},
      error: (e) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
      },
    });
}

displayOptions(option: any): string {
  return option && option.name ? option.name : option?.length > 0 ? option : '';
}

  associateUserToCoop() {
    this.disableAssociateCoop = true;
    if (!this.form.valid) {
      this.disableAssociateCoop = false;
      this.form.markAllAsTouched();
      return;
    }
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors?.error[''][0]) {
        const control = this.form.get('name');
        if (control) {
          this.form.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error[''][0],
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }

      return of(null);
    };

    const {coopRole, cooperative, userEmail} = this.form.value;
    const formValues = {
      EntityGuid: cooperative.value,
      CoopRole: coopRole,
      UserEmail: userEmail,
    };
    this.coopUserAssociationService
      .addUserToCoop(formValues)
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (data) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'User associated to Cooperative successfully' }})
          this.matDialogRef.close(cooperative);
        },
        (error) => {
          this.disableAssociateCoop = false;
          if (error.status === 400) {
            validationErrorHandler(error.error);
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
          }
        }
      );
  }

  close() {
    this.matDialogRef.close();
  }
}
