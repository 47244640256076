<h1 class="learner">Training Reports</h1>
<mat-toolbar class="mb-5 pt-2 lg:pb-0 justify-center mat-toolbar-multi-row px-0" style="background: transparent;">
  <div class="flex flex-col w-full" [formGroup]="filtersForm">
    <div class="px-4">
      <div class="flex flex-row justify-end items-center">
        <div class="flex flex-row flex-wrap">
          <div class="w-full lg:w-1/2">
            <mat-form-field class="w-full pr-4">
              <mat-label>From</mat-label>
              <input matInput [matDatepicker]="monthPicker1" formControlName="fromDate">
              <mat-datepicker-toggle matSuffix [for]="monthPicker1"></mat-datepicker-toggle>
              <mat-datepicker #monthPicker1 startView="multi-year" (monthSelected)="chooseFromMonthHandler($event, monthPicker1)" panelClass="mat-select-mt3">
              </mat-datepicker>
            </mat-form-field>
          </div>
          <div class="w-full lg:w-1/2">
            <mat-form-field class="w-full pr-4">
              <mat-label>To</mat-label>
              <input matInput [matDatepicker]="monthPicker2" formControlName="toDate">
              <mat-datepicker-toggle matSuffix [for]="monthPicker2"></mat-datepicker-toggle>
              <mat-datepicker #monthPicker2 startView="multi-year" (monthSelected)="chooseToMonthHandler($event, monthPicker2)" panelClass="mat-select-mt3">
              </mat-datepicker>
            </mat-form-field>
          </div>
        </div>
        <button class="flex items-center primary-link h-auto w-auto" (click)="closeFilter()">
          <app-filter-icon></app-filter-icon>
        </button>
      </div>
    </div>
    
    <div class="flex justify-between items-center leading-none lg:gap-4 w-full flex-col lg:flex-row px-4" style="background: var(--mat-toolbar-container-background-color);" *ngIf="openFilters">
      <div class="w-full lg:w-1/4">
        <div class="w-full">
          <mat-form-field class="w-full text-[16px]" hideRequiredMarker>
            <mat-label>Search</mat-label>
            <input matInput placeholder="Search" #input formControlName="search" />
          </mat-form-field>
        </div>
      </div>
      <div class="flex justify-end items-center leading-none lg:gap-4 lg:w-3/4 w-full flex-col lg:flex-row">
        <div class="w-full lg:w-1/4">
          <mat-form-field class="w-full">
            <input type="text" placeholder="Org. Name" matInput formControlName="orgName" [matAutocomplete]="auto" />
            <mat-autocomplete
              #auto="matAutocomplete"
              [displayWith]="displayFn"
            >
              <mat-option *ngFor="let option of searchData" [value]="option">
                {{ option?.name }}
              </mat-option>
            </mat-autocomplete>
            <mat-error *ngIf="noSearchData"> Please enter valid Data </mat-error>
          </mat-form-field>
        </div>
        <div class="w-full lg:w-1/4">
          <mat-form-field class="w-full">
            <mat-label>Training</mat-label>
            <mat-select formControlName="trainingType" panelClass="mat-select-mt3">
              <mat-option [value]="null">All</mat-option>
              <mat-option *ngFor="let training of lookupsStore?.trainingsSignal()" [value]="training.value"
                >{{ training.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="w-full lg:w-1/4">
          <mat-form-field class="w-full">
            <mat-label>State</mat-label>
            <mat-select formControlName="stateId" panelClass="mat-select-mt3">
              <mat-option [value]="null">All</mat-option>
              <mat-option [value]="0">National</mat-option>
              <mat-option *ngFor="let state of lookupsStore?.statesSignal()" [value]="state.value"
                >{{ state.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
  
        <div class="flex items-center">
          <button class="btn-primary-outlined small" (click)="applyFilters()">
            Search
          </button>
          <div class="lg:pl-3">
            <button type="button" class="btn-link-alt opacity-100" (click)="clearFilters()">
              <mat-icon> close</mat-icon>
            </button>
          </div>
        </div>
      </div>
    </div>

  </div>
</mat-toolbar>
<div class="grid-table">
  <mat-card>
    <mat-card-content>
      <div class="seperate-header-table">
        <table mat-table [dataSource]="displayData" class="w-full justify-center" multiTemplateDataRows>
          <ng-container matColumnDef="{{ column }}" *ngFor="let column of displayedColumns; let i = index">
            <th mat-header-cell *matHeaderCellDef>{{ tableHeading[i] }}</th>
            <td mat-cell *matCellDef="let row">
              <div *ngIf="i === 0; else other_td" class="primary-link flex items-start pl-6">
                <mat-icon> {{ !checkExpanded(row) ? 'remove' : 'add' }} </mat-icon>
                <span class="pl-1 text-left break-word">
                  {{ row[column] }}
                </span>
              </div>
              <ng-template #other_td>
                {{ row[column] }}
              </ng-template>
            </td>
          </ng-container>
          <ng-container matColumnDef="expandedDetail">
            <td mat-cell *matCellDef="let row" [attr.colspan]="displayedColumns.length">
              <div class="expand-hide-row" [@detailExpand]="checkExpanded(row) ? 'collapsed' : 'expanded'">
                <div class="flex w-full expanded-border" *ngFor="let col of row['languageCounts']">
                  <div class="expanded-table">
                    <div>{{ col?.language }}</div>
                  </div>
                  <div class="expanded-table">
                    <div></div>
                  </div>
                  <div class="expanded-table">
                    <div></div>
                  </div>
                  <div class="expanded-table">
                    <div>{{ col?.notStartedCount }}</div>
                  </div>
                  <div class="expanded-table">
                    <div>{{ col?.inProgressCount }}</div>
                  </div>
                  <div class="expanded-table">
                    <div>{{ col?.completeCount }}</div>
                  </div>
                  <div class="expanded-table">
                    <div>{{ col?.registrationCount }}</div>
                  </div>
                  <div class="expanded-table">
                    <div>{{ col?.certsIssuedCount }}</div>
                  </div>
                </div>
              </div>
            </td>
          </ng-container>

          <ng-container matColumnDef="header-row-first-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
          </ng-container>
          <ng-container matColumnDef="header-row-second-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
          </ng-container>
          <ng-container matColumnDef="header-row-third-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
          </ng-container>
          <ng-container matColumnDef="header-row-fourth-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="3">Status</th>
          </ng-container>
          <ng-container matColumnDef="header-row-fifth-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
          </ng-container>
          <ng-container matColumnDef="header-row-sixth-group">
            <th mat-header-cell *matHeaderCellDef [attr.colspan]="1"></th>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="[
              'header-row-first-group',
              'header-row-second-group',
              'header-row-third-group',
              'header-row-fourth-group',
              'header-row-fifth-group',
              'header-row-sixth-group'
            ]"
          ></tr>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr
            mat-row
            class="cursor-pointer not-expanded-row"
            *matRowDef="let row; columns: displayedColumns"
            [class.example-expanded-row]="checkExpanded(row)"
            (click)="pushPopElement(row)"
          ></tr>
          <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="detail-row"></tr>

         
                <ng-container matColumnDef="footer" *ngIf="!displayData || displayData.length === 0">
                  <td mat-footer-cell *matFooterCellDef [attr.colspan]="displayedColumns.length">
                    <div class="footer-content">
                      <div>Total</div>
                      <div></div>
                      <div></div>
                      <div class="expanded-table">{{ totalNotStartedCourse }}</div>
                      <div>{{ totalIncompleteCourse }}</div>
                      <div>{{ totalCompleteCourse }}</div>
                      <div>{{ totalRegistrations }}</div>
                      <div>{{ totalCertificatesIssued }}</div>
                    </div>
                  </td>
                </ng-container>
          
          
                <tr mat-footer-row  *matFooterRowDef="['footer']"></tr>


          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data</td>
          </tr>
        </table>
      </div>
      <div class="flex mt-5">
        <a
          (click)="exportCsv()"
          class="text-primary underline text-base cursor-pointer {{
            disableflag === true ? 'pointer-events-none' : ''
          }}"
          >Export to CSV</a
        >
      </div>
    </mat-card-content>
  </mat-card>
</div>
