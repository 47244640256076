import { computed, inject, Injectable, signal } from '@angular/core';
import { BaseAPI } from '../base-api';
import { map, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { LookupsStore } from './lookups.store';
import { LookupEntry } from './lookups.model';

export interface LookupResult {
  value: any | undefined;
  name: string | null | undefined;
}

export type LookupResults = LookupResult[];

export type LookupApiResponse = any[];

export type Lookup =
  | 'entity-status'
  | 'industries'
  | 'languages'
  | 'professions'
  | 'pricing-plan'
  | 'stripe-subscriptions-statuses'
  | 'groups'
  | 'states'
  | 'offsite-client'
  | 'organization'
  | 'cooperative'
  | 'org-coop'
  | 'activeOrganization'
  | 'org-roles'
  | 'roles'
  | 'entity-user-invite-status'
  | 'courses'
  | 'courses-filtered'
  | 'course-training'
  | 'course-states'
  | 'course-versions'
  | 'trainings'
  | 'learner-status'
  | 'certificate-status'
  | 'user-scormtrack-status'
  | 'scorm-types'
  | 'modules'
  | 'learning-path'
  | 'privacy-request-types'
  | 'cert-files'
  | 'badge-files'
  | 'ui-notification-types'
  | 'site-pages'
  | 'org-purchase-types'
  | 'user-opt-out-notification-types';

@Injectable({
  providedIn: 'root',
})
export class LookupsService extends BaseAPI {
  readonly lookupsStore = inject(LookupsStore);

  // api lookup methods return observable
  getOffsiteClient(searchT: any) {
    return this.entityLookup('offsite-client', searchT);
  }
  getOrganization(searchT: any) {
    return this.entityLookup('organization', searchT);
  }
  getActiveOrganization(searchT: any) {
    // Fetch only active organizations skipping archived ones
    return this.activeEntityLookup('organization', searchT);
  }
  getCooperative(searchT: any) {
    return this.entityLookup('cooperative', searchT);
  }
  getActiveCooperative(searchT: any) {
    // Fetch only active cooperatives skipping archived ones
    return this.activeEntityLookup('cooperative', searchT);
  }
  getOrgCoop(searchT: any) {
    return this.entityLookup('org-coop', searchT);
  }
  getActiveOrgCoop(searchT: any) {
    // Fetch only active Organizations & cooperatives skipping archived ones
    return this.activeEntityLookup('org-coop', searchT);
  }
  getGroup(entityGuid: any) {
    return this.groupLookup('groups', entityGuid);
  }

  // api lookup methods return void and set signal
  getUserScormtrackStatus() {
    if (this.lookupsStore.userScormtrackStatusSignal().length > 0) return;
    this.lookup('user-scormtrack-status').subscribe((res) => {
      this.lookupsStore.setLookup('userScormtrackStatusSignal', this.lookupToLookup(res));
    });
  }
  getEntityStatus() {
    if (this.lookupsStore.entityStatusSignal().length > 0) return;
    this.lookup('entity-status').subscribe((res) => {
      this.lookupsStore.setLookup('entityStatusSignal', this.lookupToLookup(res));
    });
  }
  getIndustries() {
    if (this.lookupsStore.industriesSignal().length > 0) return;
    this.lookup('industries').subscribe((res) => {
      this.lookupsStore.setLookup('industriesSignal', this.lookupToLookup(res));
    });
  }
  getLanguages() {
    if (this.lookupsStore.languagesSignal().length > 0) return;
    this.lookup('languages').subscribe((res) => {
      this.lookupsStore.setLookup('languagesSignal', this.lookupToLookup(res));
    });
  }
  getPricingPlans() {
    if (this.lookupsStore.pricingPlansSignal().length > 0) return;
    this.lookup('pricing-plan').subscribe((res) => {
      this.lookupsStore.setLookup('pricingPlansSignal', this.lookupToLookup(res));
    });
  }
  getStripeSubscriptionStatus() {
    if (this.lookupsStore.stripeSubscriptionStatusSignal().length > 0) return;
    this.lookup('stripe-subscriptions-statuses').subscribe((res) => {
      this.lookupsStore.setLookup('stripeSubscriptionStatusSignal', this.lookupToLookup(res));
    });
  }
  getStates() {
    if (this.lookupsStore.statesSignal().length > 0) return;
    this.lookupStates('states').subscribe((res) => {
      this.lookupsStore.setLookup('statesSignal', this.lookupToLookup(res));
    });
  }
  getCourses() {
    if (this.lookupsStore.coursesSignal().length > 0) return;
    this.lookup('courses').subscribe((res) => {
      this.lookupsStore.setLookup('coursesSignal', this.lookupToLookup(res));
    });
  }
  getCoursesFiltered(trainingId: number, stateId: number) {
    this.coursesFilteredLookup(trainingId, stateId).subscribe((res) => {
      this.lookupsStore.setLookup('coursesFilteredSignal', this.lookupToLookup(res));
    });
  }
  getCourseTrainings(trainingId: number) {
    this.courseTrainingLookup('course-training', trainingId).subscribe((res) => {
      this.lookupsStore.setLookup('courseTrainingsSignal', this.lookupToLookup(res));
    });
  }
  getCourseStatesByCourseId(courseId: number) {
    this.lookupCourseStates(courseId).subscribe((res) => {
      this.lookupsStore.setLookup('courseStatesByCourseIdSignal', this.lookupToLookup(res));
    });
  }
  getCourseVersions(trainingId: number) {
    this.courseVersionLookup('course-versions', trainingId).subscribe((res) => {
      this.lookupsStore.setLookup('courseVersionsSignal', this.lookupToLookup(res));
    });
  }
  getTrainings() {
    if (this.lookupsStore.trainingsSignal().length > 0) return;
    this.lookup('trainings').subscribe((res) => {
      this.lookupsStore.setLookup('trainingsSignal', this.lookupToLookup(res));
    });
  }
  getLearnerStatus() {
    if (this.lookupsStore.learnerStatusSignal().length > 0) return;
    this.lookup('learner-status').subscribe((res) => {
      this.lookupsStore.setLookup('learnerStatusSignal', this.lookupToLookup(res));
    });
  }
  getRoles() {
    if (this.lookupsStore.rolesSignal().length > 0) return;
    this.rolesLookup('roles').subscribe((res) => {
      this.lookupsStore.setLookup('rolesSignal', this.lookupToLookup(res));
    });
  }
  getOrgRoles() {
    if (this.lookupsStore.orgRolesSignal().length > 0) return;
    this.lookup('org-roles').subscribe((res) => {
      this.lookupsStore.setLookup('orgRolesSignal', this.lookupToLookup(res));
    });
  }
  getOrgUserInvitesStatus() {
    if (this.lookupsStore.orgUserInvitesStatusSignal().length > 0) return;
    this.lookup('entity-user-invite-status').subscribe((res) => {
      this.lookupsStore.setLookup('orgUserInvitesStatusSignal', this.lookupToLookup(res));
    });
  }
  getScormTypes() {
    if (this.lookupsStore.scormTypesSignal().length > 0) return;
    this.lookup('scorm-types').subscribe((res) => {
      this.lookupsStore.setLookup('scormTypesSignal', this.lookupToLookup(res));
    });
  }
  getModules() {
    if (this.lookupsStore.modulesSignal().length > 0) return;
    this.lookup('modules').subscribe((res) => {
      this.lookupsStore.setLookup('modulesSignal', this.lookupToLookup(res));
    });
  }
  getLearningPath() {
    if (this.lookupsStore.learningPathSignal().length > 0) return;
    this.lookup('learning-path').subscribe((res) => {
      this.lookupsStore.setLookup('learningPathSignal', this.lookupToLookup(res));
    });
  }
  getPrivacyRequestTypes() {
    if (this.lookupsStore.privacyRequestTypesSignal().length > 0) return;
    this.lookup('privacy-request-types').subscribe((res) => {
      this.lookupsStore.setLookup('privacyRequestTypesSignal', this.lookupToLookup(res));
    });
  }
  getCertFiles() {
    this.lookup('cert-files').subscribe((res) => {
      this.lookupsStore.setLookup('certFilesSignal', this.lookupToLookup(res));
    });
  }
  getBadgeFiles() {
    this.lookup('badge-files').subscribe((res) => {
      this.lookupsStore.setLookup('badgeFilesSignal', this.lookupToLookup(res));
    });
  }
  getUiNotificationTypes() {
    if (this.lookupsStore.uiNotificationTypesSignal().length > 0) return;
    this.lookup('ui-notification-types').subscribe((res) => {
      this.lookupsStore.setLookup('uiNotificationTypesSignal', this.lookupToLookup(res));
    });
  }
  getSitePages() {
    if (this.lookupsStore.sitePagesSignal().length > 0) return;
    this.lookup('site-pages').subscribe((res) => {
      this.lookupsStore.setLookup('sitePagesSignal', this.lookupToLookup(res));
    });
  }
  getOrgPurchaseTypes() {
    if (this.lookupsStore.orgPurchaseTypesSignal().length > 0) return;
    this.lookup('org-purchase-types').subscribe((res) => {
      this.lookupsStore.setLookup('orgPurchaseTypesSignal', this.lookupToLookup(res));
    });
  }
  getUserOptOutNotificationTypes() {
    if (this.lookupsStore.userOptOutNotificationTypesSignal().length > 0) return;
    this.lookup('user-opt-out-notification-types').subscribe((res) => {
      this.lookupsStore.setLookup('userOptOutNotificationTypesSignal', this.lookupToLookup(res));
    });
  }
  getProfessions(industryId: number) {
    this.lookupProfessions(industryId).subscribe((res) => {
      this.lookupsStore.setLookup('professionsSignal', this.lookupToLookup(res));
    });
  }
  getJobTitles(professionId: number) {
    this.lookupJobTitles(professionId).subscribe((res) => {
      this.lookupsStore.setLookup('jobTitlesSignal', this.lookupToLookup(res));
    });
  }


  // private lookup methods
  private lookup(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private lookupStates(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text, abbr: item.abbr };
        });
      })
    );
  }

  private entityLookup(lookup: Lookup, searchTerm: any): Observable<LookupResult[]> {
    return this.http
      .get<LookupApiResponse>(`LookUp/${lookup}`, {
        params: { searchTerm: searchTerm },
      })
      .pipe(
        map((res) => {
          return res.map((item) => {
            return { value: item.key, name: item.text };
          });
        })
      );
  }

  // Search for active org or coop only, skipping archived ones
  private activeEntityLookup(lookup: Lookup, searchTerm: any): Observable<LookupResult[]> {
    var statusId = '3'; // Archived status
    return this.http
      .get<LookupApiResponse>(`LookUp/${lookup}/${statusId}`, {
        params: { searchTerm: searchTerm},
      })
      .pipe(
        map((res) => {
          return res.map((item) => {
            return { value: item.key, name: item.text };
          });
        })
      );
  }

  private rolesLookup(lookup: Lookup): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.id, name: item.name };
        });
      })
    );
  }

  private groupLookup(lookup: Lookup, entityGuid: any): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${entityGuid}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private courseVersionLookup(lookup: Lookup, trainingId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${trainingId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private courseTrainingLookup(lookup: Lookup, trainingId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/${lookup}/${trainingId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  private coursesFilteredLookup(trainingId: number, stateId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/courses-filtered/${trainingId}/${stateId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      })
    );
  }

  // used directly in the component
  public lookupProfessions(industryId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/professions/${industryId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  // used directly in the component
  public lookupJobTitles(professionId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/job-titles/${professionId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  private lookupCourseStates(courseId: number): Observable<LookupResult[]> {
    return this.http.get<LookupApiResponse>(`LookUp/course-states/${courseId}`).pipe(
      map((res) => {
        return res.map((item) => {
          return { value: item.key, name: item.text };
        });
      }),
    );
  }

  // private lookup map method
  private lookupToLookup(lookupItems: LookupResult[]): LookupEntry[] {
    return lookupItems.map((lookup) => {
      const nameTrim: any = lookup.name?.trim();
      return {
        ...lookup,
        value: lookup.value,
        label: lookup.name?.trim() ?? '',
        filterLabel: /[,\-]/.test(nameTrim) ? nameTrim.replace(/-/g, '') : nameTrim,
      };
    });
  }
}
