import { inject, Injectable } from '@angular/core';
import { AuthStore } from './auth.store';
import { AuthApiService } from './auth-api.service';
import { Router } from '@angular/router';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, throwError } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { jwtDecode } from 'jwt-decode';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { Stores } from '../stores';

@Injectable({ providedIn: 'root' })
export class AuthService {

  authStore = inject(AuthStore);

  constructor(
    private router: Router,
    private authApiService: AuthApiService,
    private snackBar: MatSnackBar,
    private stores: Stores
  ) {
    // When the app loads grab a JWT from localStorage, if it exists.
    const jwt = window.localStorage.getItem('token');
    if(jwt){
      const decodedJWT: any = jwtDecode(jwt);
      if(decodedJWT.UseReportingDatabase =='true'){
        this.storeJWT(jwt as string, true);
      }
      else{
        this.storeJWT(jwt as string, false);
      
      }
    }
  }

  public useReportingDB(useReportingDatabase: boolean): Observable<boolean> {
    this.authStore.setLoading(true);
    return this.authApiService.useReportingDB(useReportingDatabase).pipe(
      catchError((e) => throwError(() => e)),
      
      map((jwt) => {
        
        this.authStore.setLoading(false);
        const success = Boolean(jwt);

        if (success) {
          this.storeJWT(jwt as string, useReportingDatabase);
          this.router.navigateByUrl('/dashboard').then(() =>{
            window.location.reload();
          });
        }

        return success;
      })
    );
  }

  public loginValidate(req: { userName: string; password: string }) {
    return this.authApiService.loginValidate(req);
  }

  public login(req: { userName: string; password: string }): Observable<boolean> {
    this.authStore.setLoading(true);
    return this.authApiService.login(req).pipe(
      catchError((e) => throwError(() => e)),
      
      map((jwt) => {
        
        this.authStore.setLoading(false);
        const success = Boolean(jwt);

        if (success) {
          const isReportingDB = (jwtDecode( jwt as string) as any).UseReportingDatabase;
          this.storeJWT(jwt as string, isReportingDB);
        }

        return success;
      })
    );
  }

  public storeJWT(token: string, useReportingDB: boolean, userIsLoggedIn?: boolean) {
    window.localStorage.setItem('token', token);
    this.authStore.setJwt(token);
    this.authStore.setUseReportingDB(useReportingDB);
    this.authStore.setUserIsLoggedIn(userIsLoggedIn ? userIsLoggedIn : null);
    
    
  }
  public loadUserDetails() {
    
    if (!this.authStore.jwtSignal()) {
      this.authStore.setUser(null);
      this.authStore.setUserIsLoggedIn(false);
      return;
    }

    this.authStore.setLoading(true);
    this.authApiService
      .user()
      .pipe(
        catchError((error) => {
          this.authStore.setLoading(false);
          return of(false);
        })
      )
      .subscribe({
        next: (user:any) => {
          this.authStore.setLoading(false);
          if (!user) {
            this.authStore.setUser(user);
            this.authStore.setUserIsLoggedIn(false);
            this.logout();
            return;
          }
          if(!(user?.roles?.includes('Admin') || user?.roles?.includes('LeadSupport') || user?.roles?.includes('SupportTechnician') || user?.roles?.includes('CourseManager')
            || user?.roles?.includes('Accounting') || user?.roles?.includes('Analyst'))){
            this.logout();
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Not Authorized' }})
            return
          }
    
          this.authStore.setUser(user);
          this.authStore.setUserIsLoggedIn(true);
        }
      });
  }
  public logout() {
    window.localStorage.removeItem('token');
    this.stores.resetStores();
    this.router.navigateByUrl('/authentication/login').then(() =>{
      window.location.reload();
    });
  }



}
