import { inject, Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';
import { paths } from '../../../interfaces/swagger-types';
import { tap } from 'rxjs';
import { CooperativeAllStore } from './cooperative-all.store';
export type Parameters = paths['/api/AdminEntity/cooperative-grid']['get']['parameters']['query'];

@Injectable({
  providedIn: 'root',
})
export class CooperativeAllService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminEntity/cooperative-grid', {
      params: paramsRequested,
    });
  }

  addCooperative(body: any) {
    return this.http.post(`adminEntity/coop`, body);
  }
}
