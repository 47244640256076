export type UserInvite = any;

export interface UserInvites {
  userInvitesSignal: UserInvite | null;
  loadingSignal: boolean;
}

export const initialState: UserInvites = {
  userInvitesSignal: null,
  loadingSignal: false,
};
