import { Component, inject, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { EnterpriseSubscriptionsService } from 'src/app/api/services/reports/enterprise-subscriptions/enterprise-subscriptions.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { Router } from '@angular/router';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-enterprise-subscriptions',
    templateUrl: './enterprise-subscriptions.component.html',
    styleUrls: ['./enterprise-subscriptions.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
  export class EnterpriseSubscriptionsComponent implements OnInit {
    readonly lookupsStore = inject(LookupsStore);
    paramsReq: any;
    defaultSortColumn: string = 'createDate';
    defaultSortDirection: string = 'DESCENDING';
    gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -365 },
        { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 365 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
        { name: 'Status', formControlName: 'enterpriseStatus', type: 'dropdown', lookup: [{value: 1, label: 'Active'}, {value: 2, label: 'Expired'}, {value: 3, label: 'Over Limit'}, {value: 4, label: 'Scheduled'}]},
       
      ],
      openFilters: true,
      columns: [
        { header: 'Start Date', controlName: 'startDate', displayPipe: 'date'},
        { header: 'Org Name', controlName: 'orgName' },
        { header: 'Org State', controlName: 'orgState' },
        { header: 'Contact Email', controlName: 'entityContactEmail', displayPipe: 'truncate'},
        { header: 'Expire Date', controlName: 'expireDate', displayPipe: 'date'},
        { header: 'Amount', controlName: 'amount' },
        { header: 'Active Users', controlName: 'numberOfActiveUsers', conditionalClass: { calculateClass: (value: string) => { return value === "Over Limit" ? "text-warn" : "";}, classColumn: 'enterpriseStatus'} },
        { header: 'Seats', controlName: 'numberOfSeats' },
        { header: 'Status', controlName: 'enterpriseStatus', conditionalClass: { calculateClass: (value: string) => { return value === "Over Limit" ? "text-warn" : "";}, classColumn: 'enterpriseStatus'}},
        { header: 'Edit', controlName: 'action',
          actionColumnitems: [
            { icon: 'edit', buttonAction: 'edit' }, // TODO: Add "requiresPermission: ['orgs:view']" to action column item
          ]
        },
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      allowExportCSV: false,
      disableflag: false,
      isLoading: false,
      displaySearch: true,
    };

  constructor(
    private enterpriseSubscriptionsService: EnterpriseSubscriptionsService,
    private lookupsService: LookupsService,
    private router: Router,
  ) {}


  ngOnInit(): void {
    this.lookupsService.getStates();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    const { search, stateId, enterpriseStatus, startDate, endDate, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ? pageSize : 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      OrgState: stateId ? stateId : [],
      enterpriseStatus: enterpriseStatus ? enterpriseStatus : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.enterpriseSubscriptionsService.getAll(params).subscribe((results: any) => {
      var gridDatasource: any[] = [];
      const { rows, totalNumberOfItems } = results;
      rows.forEach((row: any) => {
        const datasource = gridDatasource;
        datasource.push(row);
        gridDatasource = datasource;
      });
      this.gridOptions.data.data = gridDatasource;
      this.gridOptions.totalNumberOfItems = totalNumberOfItems;
      this.gridOptions.isLoading = false;
    });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'startDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'orgName':
          activeSort = 'orgName';
          break;
        case sort.active === 'orgState':
          activeSort = 'state';
          break;
        case sort.active === 'entityContactEmail':
          activeSort = 'entityContactEmail';
          break;
        case sort.active === 'expireDate':
          activeSort = 'expireDate';
          break;
        case sort.active === 'amount':
          activeSort = 'amount';
          break;
        case sort.active === 'numberOfActiveUsers':
          activeSort = 'numberOfActiveUsers';
          break;
        case sort.active === 'numberOfSeats':
          activeSort = 'numberOfSeats';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  goToEdit(row: any) { 
    this.router.navigate(
      [`/revenue/enterprise-subscriptions/edit-org/${row?.entityId}`],
      { queryParams: { enterprise: 'true' }, state: { name: row.orgName } }
    ).then();
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // export csv
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      // add button
    } // 'edit' is specific to this component
    else if (event.action === 'edit') {
      this.goToEdit(event.data);
    }
  }
}
