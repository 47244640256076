import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, RouterLink } from '@angular/router';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MatTableDataSource } from '@angular/material/table';
import { ClearCacheComponent } from 'src/app/shared/modals/users-clearcache/users-clearcache.component';
import { UsersApiService } from 'src/app/api/services/users/users-api.service';
import { ConfirmUsersEmailComponent } from 'src/app/shared/modals/users-confirm-email/users-confirm-email.component';
import { AddAssociationModalComponent } from './add-association-modal/add-association-modal.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { UserResetPasswordComponent } from 'src/app/shared/modals/user-reset-password/user-reset-password.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgTemplateOutlet, NgIf, NgFor } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-all-users-edit',
    templateUrl: './all-users-edit.component.html',
    styleUrls: ['./all-users-edit.component.scss'],
    standalone: true,
    imports: [
        RouterLink,
        MatTabsModule,
        NgTemplateOutlet,
        FormsModule,
        ReactiveFormsModule,
        MatIconModule,
        MatCardModule,
        MatToolbarModule,
        NgIf,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        MRTGridTemplateComponent,
        NgxPermissionsModule
    ],
})

export class AllUsersEditComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  entityName!: string;
  disableFlag: boolean = false;
  logoImageUrl: any;
  maxFileSize = 1024 * 1024;
  loader: boolean = false;
  @ViewChild('selectedPicture') selectedPicture: any;
  @ViewChild(MatTabGroup) tabs!: MatTabGroup;
  emailConfirmationUrl: boolean = false;
  URLreg = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  form = this.fb.group({
    firstName: this.fb.control(null, Validators.required),
    lastName: this.fb.control(null, Validators.required),
    email: this.fb.control(null, [Validators.email]),
    stateId: this.fb.control(null),
    industryId: this.fb.control(null),
    professionId: this.fb.control(null),
    jobTitleId: this.fb.control(null),
    languageId: this.fb.control(null),
    zipCode: this.fb.control(null),
    registryId: this.fb.control(null),
  });
  userProfileId: any;
  userEmail: string = '';

  public photoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpeg'],
    maxFileSize: this.maxFileSize,
  });

  // Associated Entities
  gridOptions: GridOptions = {
    filterData: [],
    openFilters: false,
    columns: [
      { header: 'Associated Entity', controlName: 'entityName' },
      { header: 'Roles', controlName: 'roles' },
      { header: 'Entity Type', controlName: 'entityType' },
      { header: 'Default Account', controlName: 'setAsDefault',
        actionColumnitems: [
          { radioButton: true, buttonAction: 'setAsDefault' },
        ]
       },
      { header: 'Action', controlName: 'action',
        actionColumnitems: [
          { icon: 'edit', buttonAction: 'action', disableOnColumnValue: { column: 'entityType', values: ['Individual']} },
        ]
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    disableflag: false,
    isLoading: false,
    fetchData: false,
    addButtonName: 'Add Association',
    hidepagination: true,
    displaySearch: true,
  };

  constructor(
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private route: ActivatedRoute,
    public usersService: UsersApiService,
    public courseApiService: CourseApiService,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog,
  ) {}


  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.userProfileId = params.id
    })
    this.lookupsService.getIndustries();
    this.lookupsService.getStates();
    this.lookupsService.getLanguages();
    this.getAssociatedEntities();
    this.usersService.getUserProfile(this.userProfileId).subscribe((data: any)  => {
      this.emailConfirmationUrl = data.emailConfirmed;
      this.logoImageUrl = data.logoImageUrl;
      this.userEmail = data.email;
      this.entityName = data.firstName + ' ' + data.lastName;
      let formValues = {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        stateId: data.stateId,
        industryId: data.industryId,
        professionId: data.professionId,
        jobTitleId: data.jobTitleId,
        languageId: data.languageId,
        zipCode: data.zipCode,
      }; 

      if(formValues.industryId) {
        this.lookupsService.getProfessions(formValues.industryId)
      }
      if(formValues.professionId) {
        this.lookupsService.getJobTitles(formValues.professionId)
      }
      this.form.patchValue(formValues as any);
    })
  }

  update() {
    if (!this.form?.valid) {
      this.form.markAllAsTouched();
      return;
    }

    const user ={ 
      ...this.form.value,
      id: this.userProfileId
    };
    const emailConfirmationUrl = `${window.location.origin}/email-confirmed`;

    this.usersService.updateUserProfile(user, emailConfirmationUrl).subscribe({
        next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'User Information Updated' }})
        },
        error: (e) => {
          console.log(e);
        },
      }
    )
  }

  clearusercashe() {
    this.dialog.open(ClearCacheComponent, {  panelClass: 'full-screen-modal-medium-mobile-panel-class', data: { items: {
      userprofileId: this.userProfileId,
      email: this.userEmail
    }}});
  }

  confirmEmail(){
    this.dialog.open(ConfirmUsersEmailComponent, { panelClass: 'full-screen-modal-medium-mobile-panel-class', data: {
      userprofileId: this.userProfileId,
      email: this.userEmail
    }});
  }

  resetPassword() {
    this.dialog.open(UserResetPasswordComponent, {  panelClass: 'full-screen-modal-medium-mobile-panel-class', data: {
      userprofileId: this.userProfileId,
      email: this.userEmail
    }});
  }

  handleIndustryChange(event: any) {
    let industryId = event.value;
    this.lookupsService.getProfessions(industryId);
  }

  handleProfessionChange(event: any) {
    let professionId = event.value;
    this.lookupsService.getJobTitles(professionId);
  }

  // Associated Entities
  getAssociatedEntities() {
    this.gridOptions.isLoading = true;
    this.usersService.getAssociatedEntities(this.userProfileId).subscribe((data: any) => {
      this.gridOptions.isLoading = false;
      this.gridOptions.data = new MatTableDataSource(data);
    });
  }

  // Associated Entities
  setDefaultOrg(event: any) {
    if (!event.setAsDefault) {
      this.usersService.setDefaultOrg(event.entityGuid, this.userProfileId).subscribe({
        next: () => {
          this.gridOptions.data.data.forEach((row: any) => {
            if (row.entityGuid === event.entityGuid) {
              row.setAsDefault = true;
            } else {
              row.setAsDefault = false;
            }
          });
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Default Account Set' }})
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }

  // Associated Entities
  addAssociation(data: any, action: string) {
    const dialogRef = this.dialog.open(AddAssociationModalComponent, { 
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      data: { 
        action: action,
        item: {
        userProfileId: this.userProfileId,
        userName: this.form.get('firstName')?.value + ' ' + this.form.get('lastName')?.value,
        ... data
        }
      }
    });
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.getAssociatedEntities();
      }
    });
  }

  // Associated Entities
  actionEvent(event: any) {
    if (event.action === 'addButton') {
      this.addAssociation({}, 'Add')
    }
    // 'edit' & 'setAsDefault' are specific to this component 
    else if (event.action === 'action') {
      this.addAssociation(event.data, 'Edit')
    }
    else if (event.action === 'setAsDefault') {
      this.setDefaultOrg(event.data);
    }
  }
}
