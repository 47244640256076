import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({
  providedIn: 'root'
})
export class EnterpriseSubscriptionsService extends BaseAPI {
  getAll(paramsRequested: any) {
    return this.http.get('adminReports/enterprise-subscriptions', {
      params: paramsRequested,
    });
  }
}