import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, effect, EventEmitter, inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/api/services/auth/auth.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { Menu, MenuItems } from '../menu-items/menu-items';
import { MatIconModule } from '@angular/material/icon';
import { RouterLinkActive, RouterLink } from '@angular/router';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  standalone: true,
  imports: [
    MatExpansionModule,
    MatIconModule,
    NgFor,
    NgIf,
    RouterLinkActive,
    RouterLink,
    AsyncPipe,
  ],
})
export class SidebarComponent implements OnInit, OnDestroy {
  readonly authStore = inject(AuthStore);
  mobileQuery: MediaQueryList;
  private _mobileQueryListener: () => void;
  status = true;
  
  itemSelect: number[] = [];
  parentIndex = 0;
  childIndex = 0;
  @Output() openSidebar = new EventEmitter();
  @Input() sidebarOpened!: boolean;
  sideMenuitems!: Menu[];
  menuChildren!: any;
  sideMenu: any[] = [];
  eventEmitter: any;

 setClickedRow(i: number, j: number) {
  this.parentIndex = i;
  this.childIndex = j;
  }

  subclickEvent() {
    this.status = true;
  }

  scrollToTop() {
    document.querySelector('.page-wrapper')?.scroll({
      top: 0,
      left: 0,
    });
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItems,
    public authService: AuthService
  ) {
    this.mobileQuery = media.matchMedia('(min-width: 768px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);
    effect(() => {
      if (this.authStore.userSignal && this.authStore.userSignal() ) {
        this.sideMenuitems = this.menuItems.getMenuitem(this.authStore.userSignal());
      }
    });
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this._mobileQueryListener);
  }

  ngOnInit(): void {}


  logout() {
    this.authService.logout();
  }
}
