import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { OrganizationAll, initialState } from './org-all.model';

export const OrgAllStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(orgAllSignal: OrganizationAll["orgAllSignal"]) {
      patchState(store, {orgAllSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);