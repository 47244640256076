<h1 class="learner">{{ this.entityName }}</h1>
<div class="back-to-link mb-4 lg:mb-0 pr-4">
  <a class="primary-link" [routerLink]="'/learners/all-learners'">Back to All Learners</a>
</div>
<mat-tab-group #tabs>
  <mat-tab label="Information">
    <ng-container *ngTemplateOutlet="informationTab"></ng-container>
  </mat-tab>
  <mat-tab label="Associated Entities">
    <ng-container *ngTemplateOutlet="associatedEntities"></ng-container>
  </mat-tab>
</mat-tab-group>
<ng-template #informationTab>
  <form [formGroup]="form">
    <div class="p-3 mt-3">
      <button class="flex items-center primary-link" (click)="clearusercashe()">
        <mat-icon>loop</mat-icon>
        <span>Clear Cache</span>
      </button>
    </div>
    
    <mat-card class="mb-8">
      <mat-card-content>
        <mat-toolbar class="bg-transparent h-12 px-0 text-black flex justify-between text-base font-bold w-full">
          <span>Profile Image</span>
        </mat-toolbar>
        <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
          <div class="flex sm:flex-row flex-col w-full mb-2 py-3">
            <div>
              <img
                *ngIf="logoImageUrl; else other_image"
                [src]="logoImageUrl"
                class="w-[6.25rem] h-[6.25rem] block object-cover border-2 rounded-full border-primary p-[4px] org_image"
                alt="{{ form?.value?.name }}"
              />
              <ng-template #other_image>
                <img
                  src="/assets/images/profile/individual-icon.svg"
                  class="w-[6.25rem] h-[6.25rem] block object-cover border-2 rounded-full border-primary p-[4px] org_image"
                  alt="{{ form?.value?.name }}"
                />
              </ng-template>
            </div>
          </div>
        </div>
  
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>First Name</mat-label>
              <input type="text" class="text-black" matInput formControlName="firstName" />
              <mat-error *ngIf="form.controls['firstName'].hasError('required') && form.controls['firstName'].touched"
                >Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Last Name</mat-label>
              <input type="text" class="text-black" matInput formControlName="lastName" />
              <mat-error *ngIf="form.controls['lastName'].hasError('required') && form.controls['lastName'].touched"
                >Required
              </mat-error>
            </mat-form-field>
          </div>
        </div>

        
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Email</mat-label>
              <input type="text" class="text-black" matInput formControlName="email" />
              <mat-error *ngIf="form.controls['email'].hasError('required') && form.controls['email'].touched"
                >Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/4">
           <div class="flex w-full">
            <div class="flex flex-col sm:w-full">
              <label  class="font-bold mb-3">Email Confirmed</label>
              <ng-container *ngIf="emailConfirmationUrl; else confirmLink">
                True
              </ng-container>
              <ng-template #confirmLink>
                <button class="flex primary-link underline" (click)="confirmEmail()">Confirm Email</button>
              </ng-template>
              
            </div>
           </div>
          </div>
          <div class="w-full sm:w-1/4">
            <div class="flex w-full">
             <div class="flex flex-col sm:w-full">
               <label  class="font-bold mb-3">Password</label>
                <button class="flex primary-link underline" (click)="resetPassword()" [disabled]="!emailConfirmationUrl">Reset Password</button>
             </div>
            </div>
           </div>
        </div>


        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let state of lookupsStore?.statesSignal()" [value]="state.value">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['stateId'].hasError('required') && form.controls['stateId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['stateId'].errors?.message">{{
                form.controls['stateId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 ">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Industry</mat-label>
              <mat-select formControlName="industryId" panelClass="mat-select-mt3" (selectionChange)="handleIndustryChange($event)">
                <mat-option *ngFor="let industry of lookupsStore?.industriesSignal()" [value]="industry.value">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['industryId'].hasError('required') && form.controls['industryId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['industryId'].errors?.message">{{
                form.controls['industryId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
       
        </div>

        
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Profession</mat-label>
              <mat-select formControlName="professionId" panelClass="mat-select-mt3" (selectionChange)="handleProfessionChange($event)">
                <mat-option *ngFor="let profession of lookupsStore?.professionsSignal()" [value]="profession.value">
                  {{ profession.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['professionId'].hasError('required') && form.controls['professionId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['professionId'].errors?.message">{{
                form.controls['professionId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 ">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Job Title</mat-label>
              <mat-select formControlName="jobTitleId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let industry of lookupsStore?.jobTitlesSignal()" [value]="industry.value">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['jobTitleId'].hasError('required') && form.controls['jobTitleId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['jobTitleId'].errors?.message">{{
                form.controls['jobTitleId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
      
        </div>

        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Preferred Language</mat-label>
              <mat-select formControlName="languageId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let language of lookupsStore?.languagesSignal()" [value]="language.value">
                  {{ language.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['languageId'].hasError('required') && form.controls['languageId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['languageId'].errors?.message">{{
                form.controls['languageId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 ">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Zip Code</mat-label>
              <input type="text" class="text-black" matInput formControlName="zipCode" />
              <mat-error *ngIf="form.controls['zipCode'].hasError('required') && form.controls['zipCode'].touched"
                >Required
              </mat-error>
            </mat-form-field>
          </div>
      
        </div>
     
       
      </mat-card-content>
    </mat-card>
    <div class="text-center">
      <button *ngxPermissionsOnly="['orgs-info:edit']" type="submit" class="btn-primary large" [disabled]="loader" (click)="update()">
        Update Learner
      </button>
    </div>
  </form>
</ng-template>
<ng-template #associatedEntities>
  <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
</ng-template>


