import { Component, Inject } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoginMessageEditComponent } from '../login-message-edit/login-message-edit.component';
import { DashboardAdMessageEditComponent } from '../dashboard-ad-message-edit/dashboard-ad-message-edit.component';
import { BannerMessageEditComponent } from '../banner-message-edit/banner-message-edit.component';
import { NgSwitch, NgSwitchDefault, NgSwitchCase } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';


@Component({
    selector: 'app-add-message',
    templateUrl: './add-message.component.html',
    styleUrls: ['./add-message.component.scss'],
    standalone: true,
    imports: [MatIconModule, NgSwitch, NgSwitchDefault, NgSwitchCase, BannerMessageEditComponent, DashboardAdMessageEditComponent, LoginMessageEditComponent]
})
export class AddMessageComponent {
  currentView: string = ''; 
  disableFlag: boolean = false;
  dialog: any;

  constructor(
    private dialogRef: MatDialogRef<AddMessageComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action?: string, items: any, messageType: string, uploadContainer: string },
  ) {}

  

  closeModal() {
    this.dialogRef.close();
  }

  openAddBannerModel() {
    this.currentView = 'bannerMessage';
  }

  openAddDashboardAdModel() {
    this.currentView = 'dashboardAdMessage';
    this.data.uploadContainer = 'dashboardAdContainer';
  }

  openAddLoginAdModel() {
    this.currentView = 'loginAdMessage';
    this.data.uploadContainer = 'loginAdContainer';
  }
}
