import { Component, OnInit, OnDestroy, inject } from '@angular/core';
import { AbstractControl, FormGroup, UntypedFormBuilder, Validators, ValidatorFn, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CoopAllService } from 'src/app/api/services/cooperations/coop-all/coop-all.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { NgIf, NgClass, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

export function WhiteSpaceValidator(): ValidatorFn {
  return (control: AbstractControl): { [key: string]: any } | null => {
    const value = control.value;
    if (value && value.trim().length === 0) {
      return { whiteSpace: true };
    }
    return null;
  };
}

@Component({
  selector: 'app-add-cooperative',
  templateUrl: './add-cooperative.component.html',
  styleUrls: ['./add-cooperative.component.scss'],
  standalone: true,
  imports: [
    MatIconModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatInputModule, 
    NgIf, 
    NgClass, 
    MatSelectModule, 
    NgFor, 
    MatOptionModule, 
    MatDialogModule, 
    NgxPermissionsModule
  ]
})

export class AddCooperativeComponent implements OnInit, OnDestroy {
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup = this.fb.group({
    email: this.fb.control('', Validators.compose([Validators.required, Validators.email])),
    name: this.fb.control('', Validators.compose([Validators.required, WhiteSpaceValidator()])),
    stateId: this.fb.control('', Validators.required),
    industry: this.fb.control(''),
  });
  disableCreateCoop: boolean = false;
  validEmailSpinner: boolean = false;
  verifyEmail: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddCooperativeComponent>,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private coopAllService: CoopAllService
  ) {}

  ngOnInit(): void {
    this.lookupsService.getIndustries();
    this.lookupsService.getStates();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  checkEmail(email: any, control: any) {
    this.verifyEmail = false;

    if (control?.errors === null) {
      this.validEmailSpinner = false;
      this.coopAllService.emailExists(email.target.value)
        .pipe(takeUntil(this.destroy$))
        .subscribe(
          (data: any) => {
            if (!data.userExists) {
              control.markAsTouched();
              control.setErrors({
                message: `Email Not Registed`,
              });
            }
            this.validEmailSpinner = false;
          },
          (error) => {
            this.validEmailSpinner = false;
          }
        );
    }
  }

  createCoop() {
    this.disableCreateCoop = true;
    if (!this.form.valid) {
      this.disableCreateCoop = false;
      this.form.markAllAsTouched();
      return;
    }
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors?.error[''][0]) {
        const control = this.form.get('name');
        if (control) {
          this.form.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error[''][0],
          });
        }
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }

      return of(null);
    };
    const body = this.form.getRawValue();
    this.coopAllService.addCooperative(body).subscribe(
      (data) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Cooperative Created Successfully' }})
        this.matDialogRef.close();
      },
      (error) => {
        this.disableCreateCoop = false;
        if (error.status === 400) {
          validationErrorHandler(error.error);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
        }
      }
    );
    
  }

  close() {
    this.matDialogRef.close();
  }
}
