import { Component, inject, OnInit } from '@angular/core';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog } from '@angular/material/dialog';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CatalogService } from 'src/app/api/services/courses/catalog.service';
import { tap } from 'rxjs';
import { Sort } from '@angular/material/sort';
import { AddCourseCatalogComponent } from 'src/app/shared/modals/add-course-catalog/add-course-catalog.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-catalog',
    templateUrl: './catalog.component.html',
    styleUrls: ['./catalog.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})
export class CatalogComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  defaultSortColumn: string = 'courseName';
  defaultSortDirection: string = 'ASCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Training', formControlName: 'trainingId', type: 'dropdown', lookup: this.lookupsStore.trainingsSignal },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Course', controlName: 'courseName' },
      { header: 'Training', controlName: 'trainingName' },
      { header: 'State', controlName: 'state' },
      { header: 'Industries', controlName: 'industries', displayPipe: 'truncateWithCount' },
      { header: 'Professions', controlName: 'professions', displayPipe: 'truncateWithCount' },
      { header: 'Job Titles', controlName: 'jobTitles', displayPipe: 'truncate' },
      {
        header: 'Edit',
        controlName: 'action',
        actionColumnitems: [{ icon: 'edit', buttonAction: 'edit' }],
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    addButtonName: 'Add Course',
    disableflag: false,
    isLoading: false,
    frontEndPagination: true,
    displaySearch: true,
  };

  constructor(
    private dialog: MatDialog,
    private lookupsService: LookupsService,
    private catalogService: CatalogService
  ) {}

  ngOnInit() {
    this.lookupsService.getStates();
    this.lookupsService.getTrainings();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    if (rawParams) {
      const { search, trainingId, stateId, sortOrder, pageSize, pageNumber } = rawParams;

      const newSortOrder = this.sortField(sortOrder);
      const params = {
        PageSize: pageSize ?? 20,
        PageNumber: pageNumber ?? 1,
        SearchTerm: search?.length! > 0 ? search : '',
        TrainingId: trainingId ? trainingId : '',
        StateId: stateId ? stateId : '',
        SortField: newSortOrder
          ? newSortOrder.active && newSortOrder.direction
            ? newSortOrder.active
            : this.defaultSortColumn
          : this.defaultSortColumn,
        SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      };

      var gridDatasource: any[] = [];
      this.catalogService
        .getCourseCatalogGrid(params)
        .pipe(
          tap((data: any) => {
            gridDatasource = [];
            if (data.rows.length > 0) {
              const { rows, totalNumberOfItems } = data;
              rows.forEach((row: any) => {
                const datasource = gridDatasource;
                datasource.push(row);
                gridDatasource = datasource;
              });
              this.gridOptions.totalNumberOfItems = totalNumberOfItems;
            } else {
              this.gridOptions.totalNumberOfItems = 0;
            }
            this.gridOptions.data.data = gridDatasource;
            this.gridOptions.isLoading = false;
          })
        )
        .subscribe();
    }
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'courseName':
          activeSort = 'courseName';
          break;
        case sort.active === 'trainingName':
          activeSort = 'training';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  openDialog(action: 'add' | 'edit', course?: any) {
    const dialogRef = this.dialog.open(AddCourseCatalogComponent, {
      panelClass: 'full-screen-modal-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: { action, course }
    });

    dialogRef.afterClosed().subscribe((result) => {
      this.actionEvent({ action: 'fetchAll', data: {} });
    });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'addButton') {
      this.openDialog('add', event.data);
    } else if (event.action === 'edit') {
      this.openDialog('edit', event.data);
    }
  }
}
