import { Component, inject, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { PrivacyService } from 'src/app/api/services/privacy/privacy.service';
import { ConfirmModalComponent } from 'src/app/shared/modals/confirm-modal/confirm-modal.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    providers: [DatePipe],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class PrivacyComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -45 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      {
        name: 'Request Type',
        formControlName: 'requestTypeId',
        type: 'dropdown',
        lookup: this.lookupsStore?.privacyRequestTypesSignal,
      },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Created Date', controlName: 'createDate', displayPipe: 'date' },
      { header: 'Email', controlName: 'email', displayPipe: 'truncate' },
      { header: 'Request Type', controlName: 'requestType' },
      { header: 'State', controlName: 'state' },
      { header: 'Deletion Date', controlName: 'deletionDate', displayPipe: 'date' },
      { header: 'Resend Date', controlName: 'resendDate', displayPipe: 'date' },
      {
        header: 'Resend Email',
        controlName: 'action',
        actionColumnitems: [{ icon: 'cached', buttonAction: 'resendEmail'}], // TODO: Add  "requiresPermission: ['privacyrequest:edit']" to action column items array
      },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };
  constructor(
    private datepipe: DatePipe,
    private dialog: MatDialog,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private privacyService: PrivacyService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getPrivacyRequestTypes();
    this.lookupsService.getStates();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;
    if (rawParams) {
      const { search, startDate, endDate, requestTypeId, stateId, sortOrder, pageSize, pageNumber } = rawParams;

      const newSortOrder = this.sortField(sortOrder);
      const params = {
        PageSize: pageSize ?? 20,
        PageNumber: pageNumber,
        SearchTerm: search?.length! > 0 ? search : '',
        RequestTypeId: requestTypeId ? requestTypeId : '',
        FromDate: startDate,
        ToDate: endDate,
        StateId: stateId ? stateId : '',
        SortField: newSortOrder
          ? newSortOrder.active && newSortOrder.direction
            ? newSortOrder.active
            : this.defaultSortColumn
          : this.defaultSortColumn,
        SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      };

      var gridDatasource: any[] = [];
      this.privacyService
        .getPrivacyRequestGrid(params)
        .pipe(
          tap((data: any) => {
            gridDatasource = [];
            if (data.rows.length > 0) {
              const { rows, totalNumberOfItems } = data;
              rows.forEach((row: any) => {
                const datasource = gridDatasource;
                datasource.push(row);
                gridDatasource = datasource;
              });
              this.gridOptions.totalNumberOfItems = totalNumberOfItems;
            } else {
              this.gridOptions.totalNumberOfItems = 0;
            }
            this.gridOptions.data.data = gridDatasource;
            this.gridOptions.isLoading = false;
          })
        )
        .subscribe();
    }
  }

  resendEmail(data: any) {
    const dialogRef = this.dialog.open(ConfirmModalComponent, { 
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      data: { items: {
      title: "Resend Email",
      body: `The Privacy Request email was originally sent to ${data.email} on ${this.datepipe.transform(data.createDate, 'M/d/yy')}. To resend the email, click the Resend Email button`,
      buttonText: "Resend Email"
    }}});
    dialogRef.afterClosed().subscribe((response) => {
      if (response) {
        this.privacyService.resendPrivacyRequest(data.id).subscribe(() => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Email has been resent' }})
        });
      }
    });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'requestType':
          activeSort = 'requestType';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'deletionDate':
          activeSort = 'deletionDate';
          break;
        case sort.active === 'resendDate':
          activeSort = 'lastResent';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'resendEmail') {
      this.resendEmail(event.data);
    }
  }
}
