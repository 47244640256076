<div class="p-4">
  <div class="flex justify-center flex-col">
    <div class="flex justify-end close-modal-button-pos">
      <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="admin-modal-body">
      <div class="px-12 py-8 content-parent">
        <div class="text-center content-child">
          <h1 class="font-bold text-primary mb-0 learner">
            {{ data?.items.training }}<br/>
            {{ courseData?.trainingType }} | {{ data?.items.state }}
          </h1>
          <h2 class="font-semibold text-primary learner">{{ data?.items.firstName }} {{ data?.items.lastName }} | {{ data?.items.email }}</h2>
          <div *ngIf="editPage">
            <div class="mt-3">
              <h3 class="mb-0 learner">Training Status</h3>
              <p *ngIf="data?.items.certificateName" class="m-0">
                Complete | 
                <span
                (click)="downloadCert(data?.items.certificateId, data?.items.certificateName)"
                class="primary-link" style="font-size: unset;"
                >
                {{ data?.items.certificateName }}</span>
              </p>
              <p *ngIf="!data?.items.certificateName" class="m-0">In Progress</p>
    
              <form [formGroup]="formStatus">
                <div *ngFor="let item of moduleStatusData"  >
                  <mat-form-field class="w-full" *ngIf="item.controlName !== 'note'">
                    <mat-label>{{item.label }}</mat-label>
                    <mat-select (selectionChange)="statusChange($event)" [formControlName]="item.controlName">
                      <mat-option [value]="null">All</mat-option>
                      <mat-option *ngFor="let status of lookupsStore?.userScormtrackStatusSignal()" [value]="status?.value"
                        >{{ status.label }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
    
                </div>
              </form>
    
            </div>
            <div>
              <h3 class="mb-0 mt-4 learner">Training Association</h3>
              <p class="m-0">
                Learner will see this training in the selected account.
              </p>
              <div class="mb-8">
                <form [formGroup]="formAccount">
                <mat-form-field  class="w-full">
                <mat-label>Organization</mat-label>
                  <mat-select   formControlName="organization">
                    <mat-option  [value]="null">Please Select</mat-option>
                    <mat-option *ngFor="let option of options" [value]="option.text"
                      >{{ option.label }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-error *ngIf="data?.items.purchaseDate !== null && formAccount.controls['organization'].touched"
                  class="text-left mt--4"
                  >This training has been purchased.
                </mat-error>              
                </form>
                <form [formGroup]="formStatus">  
                  <div>
                    <app-form-field type="text" label="Notes" placeholder="Add Reason text" maxlength="200" formControlName="note"> </app-form-field>
                  </div>
                </form>
              </div>
            </div>
    
    
            <div class="mb-4 inline-grid justify-center items-center">
              <button  *ngxPermissionsOnly="['reports-learner-metrics:edit']"
                class="btn-primary medium"
                type="button"
                (click)="submitButton(data?.buttonAction)"
              >Update</button>
              <button
                class="primary-link flex justify-center items-center mt-3"
                type="button"
                (click)="editPage = false">
                <span>See More</span>
                <mat-icon class="rotated-icon-45">arrow_outward</mat-icon>
              </button>
            </div>
          </div>
          <div *ngIf="!editPage">
            <div class="mt-3">
              <h3 class="mb-0 learner">Training Details</h3>
              <p *ngIf="data?.items.certificateName" class="m-0">
                Complete | 
                <span
                (click)="downloadCert(data?.items.certificateId, data?.items.certificateName)"
                class="primary-text" style="font-size: unset;"
                >
                {{ data?.items.certificateName }}</span>
              </p>
              <p *ngIf="!data?.items.certificateName" class="mb-5">In Progress</p>
              <mat-accordion class="learner-metrics-accordion">
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <p class="font-semibold">{{ data?.items.training }}</p>
                  </mat-expansion-panel-header>
                  <div class="grid grid-cols-1">
                    <div class="training-details-column">
                      <div class="training-details-label">Type</div>
                      <div>{{ courseData?.trainingType }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">State</div>
                      <div>{{ stateNames[data?.items.stateId] }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Version</div>
                      <div>{{ data?.items.version }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Language</div>
                      <div>{{ courseData?.language }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Added Date</div>
                      <div>{{ formatDate(data?.items.dateAdded) }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">End Date</div>
                      <div>{{ data?.items.dateCompleted? formatDate(data?.items.dateCompleted): "-" }}</div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="mt-8">
              <h3 class="mb-0 learner">Training Interactions</h3>
              <p class="mb-5">
                Total Session Time {{  data?.items.totalTime }}
              </p>
              <mat-accordion class="learner-metrics-accordion">
                <mat-expansion-panel *ngFor="let scorm of courseData?.scormTracks">
                  <mat-expansion-panel-header>
                    <p class="font-semibold">{{ scorm?.name }}</p>
                  </mat-expansion-panel-header>
                  <div class="grid grid-cols-1">
                    <div class="training-details-column">
                      <div class="training-details-label">Status</div>
                      <div class="first-item">{{ scorm?.status }}</div>
                      <div *ngIf="scorm?.name.includes('Exam')" class="ml-4 training-details-label">Score</div>
                      <div *ngIf="scorm?.name.includes('Exam')"> {{ scorm?.scormJson?.["cmi.core.score.raw"] }}%</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Total Session Time</div>
                      <div class="first-item">{{ scorm?.totalTime }}</div>
                      <div *ngIf="scorm?.name.includes('Exam')" class="ml-4 training-details-label">JSON Data</div>
                      <div *ngIf="scorm?.name.includes('Exam')">
                        <mat-icon matSuffix class="primary-text" (click)="clipBoard(scorm?.scormJson)"
                        >assignment</mat-icon>
                      </div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Attempts</div>
                      <div class="first-item">{{ scorm?.attempt }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Last Session Time</div>
                      <div class="first-item">{{ scorm?.sessionTime }}</div>
                    </div>
                  </div>
                </mat-expansion-panel>
                <mat-expansion-panel>
                  <mat-expansion-panel-header>
                    <p class="font-semibold">Certificate</p>
                  </mat-expansion-panel-header>
                  <div class="grid grid-cols-1">
                    <div class="training-details-column">
                      <div class="training-details-label">Number</div>
                      <div class="first-item">
                        <span *ngIf="data?.items.certificateName"
                        (click)="downloadCert(data?.items.certificateId, data?.items.certificateName)"
                        class="primary-text" style="font-size: unset;"
                        >
                        {{ data?.items.certificateName }}</span>
                        <span *ngIf="!data?.items.certificateName">-</span>
                      </div>
                      <div class="ml-4 training-details-label">Paid | CE</div>
                      <div>{{ courseData?.paid ? 'Yes': 'No' }} | {{ courseData?.isCE ? 'Yes': 'No' }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Issue Date</div>
                      <div class="first-item">{{ data?.items.dateCompleted ? formatDate(data?.items.dateCompleted): "-" }}</div>
                      <div class="ml-4 training-details-label">Purchase Date</div>
                      <div>{{ courseData?.purchaseDate ? formatDate(courseData?.purchaseDate): "-" }}</div>
                    </div>
                    <div class="training-details-column">
                      <div class="training-details-label">Expiry Date</div>
                      <div class="first-item">{{ (courseData?.certExpiryMonths && data?.items.dateCompleted) ? getExpiryMonths(data?.items.dateCompleted, courseData?.certExpiryMonths) : "-" }}</div>
                      <div class="ml-4 training-details-label">Amount</div>
                      <div>{{ courseData?.price ? ("$" + courseData?.price) : "-" }}</div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>
            </div>
            <div class="mb-4 mt-8 inline-grid justify-center items-center">
              <button
                class="primary-link flex justify-center items-center mt-3"
                type="button"
                (click)="editPage = true">
                <mat-icon class="rotated-icon-135">arrow_outward</mat-icon>
                <span>Back</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
