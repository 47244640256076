<div class="p-4">
  <div class="flex flex-col h-full">
    <div class="text-right justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="admin-modal-body">
      <div class="px-12 py-8">
          <div class="text-center px-5 py-3">
              <h2 class="font-bold mb-5">{{ data.action + ' Appointed Certificates' }}</h2>
          </div>
  
          <p class="text-primary text-center w-full">Add Certificates to an Organization.</p>
          <form [formGroup]="form">
            <mat-card class="mb-10 relative">
              <mat-card-content>
              
        
          
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>Quantity</mat-label>
                      <input class="text-black" type="text" matInput formControlName="quantity" />
                      <mat-error *ngIf="form.controls['quantity'].hasError('notAnInteger') && form.controls['quantity'].touched"
                      >Please enter a valid integer.
                      </mat-error>
                    </mat-form-field>
                  </div>        
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>Notes</mat-label>
                      <input class="text-black" type="text" matInput formControlName="notes" />
                      <mat-error *ngIf="form.controls['notes'].hasError('maxlength') && form.controls['notes'].touched"
                      >Max 200 Characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                
              </mat-card-content>
            </mat-card>
            <div class="mt-auto pb-3 flex justify-center flex-col items-center">
              <button *ngxPermissionsOnly="['purchases-subscriptions:edit']" class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmit()">
                <ng-container>{{ data.action + ' Appointed Certificates' }}</ng-container>
              </button>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>