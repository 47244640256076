import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { NgIf, NgFor } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-add-file-modal',
    templateUrl: './add-file-modal.component.html',
    styleUrls: ['./add-file-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        NgIf,
        FileUploadModule,
        NgFor,
    ],
})
export class AddFileModalComponent implements OnInit {
  localImageUrl: any;
  loader: boolean = false;
  showImagePreview: boolean = false;
  selectedFile: any;
  uploadContainer!: string;

  public photoUploader: FileUploader = new FileUploader({
    url: '',
  });

  constructor(
    private dialogRef: MatDialogRef<AddFileModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private sanitizer: DomSanitizer,
    private coursesService: CoursesService
  ) {}

  ngOnInit(): void {
    this.uploadContainer = this.data.uploadContainer;

    this.photoUploader.onAfterAddingFile = (fileItem) => {
      if (this.photoUploader.queue.length > 1) {
        this.photoUploader.queue.splice(0, 1);
      }

      let url = window.URL
        ? window.URL.createObjectURL(fileItem._file)
        : (window as any).webkitURL.createObjectURL(fileItem._file);
      this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);

      this.photoUploader.queue.map((file: any) => {
        this.selectedFile = file.file.rawFile;
      });

      this.checkIfImage();
    };
  }

  checkIfImage() {
    // if the file type is jpg, jpeg, png, svg show the image preview
    const fileType = this.photoUploader.queue[0]?.file.type;
    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg' ||
      fileType === 'image/png' ||
      fileType === 'image/svg+xml'
    ) {
      this.showImagePreview = true;
    } else {
      this.showImagePreview = false;
    }
  }

  upload() {
    this.loader = true;
    const formData = new FormData();
    formData.append('file', this.selectedFile);
    formData.append('containerName', this.uploadContainer);

    this.coursesService.postUploadFile(formData).subscribe({
      next: () => {
        this.loader = false;
        this.dialogRef.close(true);
      },
      error: (error) => {
        this.loader = false;
        console.error('Error uploading file', error);
      },
    });
  }

  close() {
    this.dialogRef.close();
  }
}
