import { components } from '../../../../api/interfaces/swagger-types';
export type CertificatesGrid = components['schemas']['CertificatesGridViewModel'];

export interface Certificates {
  certificatesSignal: CertificatesGrid | null;
  loadingSignal: boolean;
}

export const initialState: Certificates = {
  certificatesSignal: null,
  loadingSignal: false,
};
