import { Injectable } from '@angular/core';
import { BaseAPI } from '../base-api';

@Injectable({
  providedIn: 'root'
})
export class CatalogService extends BaseAPI {

  getCourseCatalogGrid(params: any) {
    return this.http.get('AdminCourse/catalog', { params });
  }

  getCatalogItem(id: number) {
    return this.http.get(`AdminCourse/catalog/${id}`);
  }

  upsertCatalogItem(data: any) {
    return this.http.post('AdminCourse/catalog', data);
  }

}
