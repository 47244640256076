import { Injectable } from '@angular/core';
import { BaseAPI } from '../../base-api';

@Injectable({ providedIn: 'root' })
export class DashboardService extends BaseAPI {

  getDailyReports(paramsRequested: any) {
    return this.http.get('adminReports/dashboard-daily', {
      params: paramsRequested,
    });
  }

  getTrendReports(paramsRequested: any) {
    return this.http.get('adminReports/dashboard-trend', {
      params: paramsRequested,
    });
  }
}
