import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appMessageService } from 'src/app/api/services/app-messages/app-message.service';
import { FileUploader, FileUploadModule } from 'ng2-file-upload';
import { DomSanitizer } from '@angular/platform-browser';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgIf, NgFor } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-login-message-edit',
    templateUrl: './login-message-edit.component.html',
    styleUrls: ['./login-message-edit.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, FileUploadModule, NgFor, MatDatepickerModule]
})
export class LoginMessageEditComponent {
  action: string = 'Add';
  form = this.fb.group({
    title: this.fb.control(null, [Validators.required]),
    imageUrl: this.fb.control(null),
    description: this.fb.control(null, [Validators.required]),
    startDate: this.fb.control(null, [Validators.required]),
    endDate: this.fb.control(null, [Validators.required]),
  })
  disableFlag: boolean = false;
  maxFileSize = 1024 * 1024;
  requiredImage: boolean = false;
  failImageFlag: boolean = false;
  failSizeFlag: boolean = false;
  failFlag: boolean = false;
  loader: boolean = false;
  public photoUploader: FileUploader = new FileUploader({
    url: '',
    allowedMimeType: ['image/png', 'image/jpeg'],
    maxFileSize: this.maxFileSize,
  });
  responseObj: any;
  states: any;
  showImagePreview: boolean = false;
  selectedFile: any;
  uploadContainer!: string;
  localImageUrl: any;

  constructor(
    private fb: FormBuilder,
    private appMessageService: appMessageService,
    private coursesService: CoursesService,
    private sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<LoginMessageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action?: string, items: any, messageType: string, uploadContainer: string },
  ) {}

  ngOnInit(): void {

    if(this.data) {
      this.action = this.data.action! == 'edit' ? 'Edit' : 'Add';

      if(this.data.messageType! === 'LoginAd') {
        this.appMessageService
        .getAppMessage(this.data.messageType, this.data.items.id)
        .subscribe((res: any) => {
          var advertisementJson = JSON.parse(res.advertisementJson);
          var patchValue = {
            title: advertisementJson.Title,
            description: advertisementJson.Description,
            imageUrl: advertisementJson.ImageUrl,
            startDate: res.startDate,
            endDate: res.endDate
          }
          this.form.patchValue(patchValue);
          
        })
      } 
    }

    this.uploadContainer = this.data.uploadContainer;

    this.photoUploader.onAfterAddingFile = (fileItem) => {
      if (this.photoUploader.queue.length > 1) {
        this.photoUploader.queue.splice(0, 1);
      }

      let url = window.URL
        ? window.URL.createObjectURL(fileItem._file)
        : (window as any).webkitURL.createObjectURL(fileItem._file);
      this.localImageUrl = this.sanitizer.bypassSecurityTrustUrl(url);

      this.photoUploader.queue.map((file: any) => {
        this.selectedFile = file.file.rawFile;

        this.loader = true;
        let blobURL: string = '';
        const imageFormData = new FormData();
        imageFormData.append('file', this.selectedFile);
        imageFormData.append('containerName', this.uploadContainer);
        this.appMessageService.postUploadFile(imageFormData).subscribe({
          next: (data: any) => {
            this.loader = false;
            blobURL = data.replace(/"/g, "");
            (this.form.get('imageUrl') as FormControl).patchValue(blobURL);
          },
          error: (error) => {
            this.loader = false;
            console.error('Error uploading file', error);
          },
        });
      });

      this.checkIfImage();
    };

  }

  checkIfImage() {
    const fileType = this.photoUploader.queue[0]?.file.type;
    if (
      fileType === 'image/jpeg' ||
      fileType === 'image/jpg' ||
      fileType === 'image/png' ||
      fileType === 'image/svg+xml'
    ) {
      this.showImagePreview = true;
    } else {
      this.showImagePreview = false;
    }
  }

  onSubmit() {
   const { title, description, imageUrl, startDate, endDate } = this.form.value;

   const AdvertisementDisplayCriteria = {
    AdvertisementId:  this.data.items?.id ?? 0,
    AdvertisementPageId: 3,
    ShowEntityAdmin: false,
    ShowCoopAdmin: false,
    ShowOrgLearner: false,
    ShowIndividual: false,
    ShowAnonymous: true,
   }

   const AdvertisementJson = {
      Title: title ?? '',
      Description: description  ?? '',
      ImageUrl: imageUrl,
   }

   const formValues = {
     id: this.data.items?.id ?? 0,
     AdvertisementTypeId: 2,
     AdvertisementJson: AdvertisementJson,
     AdvertisementDisplayCriteria: AdvertisementDisplayCriteria,
     StartDate: startDate ?? '',
     EndDate: endDate ?? ''
   };

   this.appMessageService
     .upsertAdvertisementMessage(formValues)
     .subscribe({
       next: () => {
        this.dialogRef.close('success');
       },
       error: (err) => {
         console.error('Error uploading data:', err);
         this.loader = false;
       }
     });
  }


  closeModal() {
    this.dialogRef.close();
  }
}
