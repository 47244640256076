import { Component, effect, inject, OnInit } from '@angular/core';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { environment } from 'src/environments/environment';
import { NgIf, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    standalone: true,
    imports: [NgIf, DatePipe],
})
export class FooterComponent implements OnInit {
  readonly authStore = inject(AuthStore);
  date: number = new Date().getFullYear();
  versionNumber!: { number: string; date: string };
  constructor() {}

  ngOnInit(): void {
    this.versionNumber = {
      number: environment.versionNumber.split('|')[0] || '',
      date: environment.versionNumber.split('|')[1] || '',
    };
  }
}
