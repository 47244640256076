import { Component, inject, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { SubscriptionReportSerice } from 'src/app/api/services/reports/subscription-report/subscription-report.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-subscription-report',
    templateUrl: './subscription-report.component.html',
    styleUrls: ['./subscription-report.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class SubscriptionReportComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  paramsReq: any;
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -365 },
        { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Plan Name', formControlName: 'pricingPlanId', type: 'dropdown', lookup: this.lookupsStore.pricingPlansSignal },
        { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore.stripeSubscriptionStatusSignal  },
      ],
      openFilters: true,
      columns: [
        { header: 'Create Date', controlName: 'createDate', displayPipe: 'date'},
        { header: 'Organization Name', controlName: 'organizationName' },
        { header: 'Contact Email', controlName: 'entityContactEmail', displayPipe: 'truncate'},
        { header: 'Plan Name', controlName: 'planName' },
        { header: 'Plan Amount', controlName: 'planAmount', displayPipe: 'currency'},
        { header: 'Status', controlName: 'stripeSubscriptionStatus' },
        { header: '# of Active Users', controlName: 'numberOfActiveUsers' },
        { header: '# of Seats', controlName: 'numberOfSeats' },
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      allowExportCSV: false,
      disableflag: false,
      isLoading: false,
      fetchData: false,
      displaySearch: true,
    };

  constructor(
    private subscriptionReportSerice: SubscriptionReportSerice,
    private lookupsService: LookupsService,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getLearnerStatus();
    this.lookupsService.getPricingPlans();
    this.lookupsService.getStripeSubscriptionStatus();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    const { search, startDate, endDate, pricingPlanId, status, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ? pageSize : 20,
      PageNumber: pageNumber ? pageNumber : 1,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      PricingPlanId: pricingPlanId ? pricingPlanId : '',
      stripeSubscriptionStatus: status ? status : [],
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
    };

    this.paramsReq = params;
    this.subscriptionReportSerice.getAll(params).subscribe((results: any) => {
      var gridDatasource: any[] = [];
      const { rows, totalNumberOfItems } = results;
      rows.forEach((row: any) => {
        const datasource = gridDatasource;
        datasource.push(row);
        gridDatasource = datasource;
      });
      this.gridOptions.data.data = gridDatasource;
      this.gridOptions.totalNumberOfItems = totalNumberOfItems;
      this.gridOptions.isLoading = false;
    });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'entityContactEmail':
          activeSort = 'entityContactEmail';
          break;
        case sort.active === 'planName':
          activeSort = 'planName';
          break;
        case sort.active === 'planAmount':
          activeSort = 'planAmount';
          break;
        case sort.active === 'stripeSubscriptionStatus':
          activeSort = 'stripeSubscriptionStatus';
          break;
        case sort.active === 'numberOfActiveUsers':
          activeSort = 'numberOfActiveUsers';
          break;
        case sort.active === 'numberOfSeats':
          activeSort = 'numberOfSeats';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // export csv
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      // add button
    }
  }
}
