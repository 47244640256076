import { Component, Inject, OnInit, } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatIconModule } from '@angular/material/icon';
import { catchError, throwError } from 'rxjs';
import { UserInvitesService } from 'src/app/api/services/organizations/user-invites/user-invites.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  imports: [
    MatIconModule,
    NgxPermissionsModule
  ],
  standalone: true,
  selector: 'app-users-confirm-email',
  templateUrl: './users-confirm-email.component.html',
  styleUrls: ['./users-confirm-email.component.scss'],
})
export class ConfirmUsersEmailComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ConfirmUsersEmailComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInvitesService: UserInvitesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }

  submitButton(button: any) {
    const email = this.data?.email;
    this.userInvitesService
      .allUsersConfirmEmail(email)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Email Confirmed' }})
          this.close();
        },
        error: (e) => {
          console.log(e);
        },
      });
    return;
  }     
  
}
