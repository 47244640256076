import { inject, Injectable } from "@angular/core";
import { LookupsStore } from "./lookups/lookups.store";
import { AuthStore } from "./auth/auth.store";
import { CooperativeAllStore } from "./cooperations/coop-all/cooperative-all.store";
import { CoopUserAssociationStore } from "./cooperations/user-association/coop-user-association.store";
import { DashboardStore } from "./reports/dashboard/dashboard.store";
import { OrgAllStore } from "./organizations/all/org-all.store";
import { AllCoursesStore } from "./courses/all/all-courses.store";
import { UserAssociationsStore } from "./organizations/user-associations/user-associations.store";
import { UserInvitesStore } from "./organizations/user-invites/user-invites.store";
import { CertificatesStore } from "./reports/certificates/certificates.store";
import { LearnerMetricsStore } from "./reports/learner-metrics/learner-metrics.store";
import { AllUsersStore } from "./users/all-users.store";

// All stores should be imported here
export const AppStores = [
  LookupsStore,
  AuthStore,
  CooperativeAllStore,
  CoopUserAssociationStore,
  DashboardStore,
  OrgAllStore,
  AllCoursesStore,
  UserAssociationsStore,
  UserInvitesStore,
  CertificatesStore,
  LearnerMetricsStore,
  AllUsersStore
];


@Injectable({
  providedIn: 'root',
})
export class Stores {
  stores:any[] = [];
  constructor() {
    AppStores.forEach(store => {
      const storeInject = inject(store);
      this.stores.push(storeInject);
    });
  }
  
  resetStores() {
    this.stores.forEach(store => {
      if (store.resetStore)
      store.resetStore();
    });
  }
}
