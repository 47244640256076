import { components, paths } from '../../../api/interfaces/swagger-types';

export interface UserModel extends Partial<components['schemas']['UserProfileViewModel']> {}

export interface Auth {
  jwtSignal: string | null;
  userSignal?: UserModel | null | boolean;
  useReportingDBSignal: null |boolean;
  userIsLoggedInSignal: null | boolean;
  profilePercentCompleteSignal: number;
  isLoadingSignal: boolean;
}

export const initialState: Auth = {
  jwtSignal: null,
  userSignal: null,
  useReportingDBSignal: null,
  userIsLoggedInSignal: null,
  profilePercentCompleteSignal: 0,
  isLoadingSignal: false,
};

export function createAuth() {
  return {

  } as Auth;
}