import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, Subscription, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { UntilDestroy } from '@ngneat/until-destroy';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { UserInvitesService } from 'src/app/api/services/organizations/user-invites/user-invites.service';
import { environment } from '../../../../environments/environment';
import { ApiService } from 'src/app/api/services/api.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgFor } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

export interface DialogData {
  item?: any;
  canEdit?: boolean;
  deleteId?: any;
  itemName?: string;
}

@UntilDestroy()
@Component({
    selector: 'app-add-users',
    templateUrl: './add-users.component.html',
    styleUrls: ['./add-users.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        MatDialogModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        NgFor,
        MatOptionModule,
        MatSelectModule,
        NgxPermissionsModule
    ],
})
export class AddUsersComponent implements OnInit {
  public addUsers: UntypedFormGroup = Object.create(null);
  disableFlag:boolean = true;
  OrgEntityGuid: any = null;
  orgNamesData: any;
  groupData: any;
  formValueChangesSub!: Subscription;
  invitesForm = this.fb.group({
    orgName: this.fb.control(null),
    userEmail: this.fb.control(null),
    group: this.fb.control(null),
  });
  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddUsersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private snackBar: MatSnackBar,
    public lookupsService: LookupsService,
    private userInvitesService: UserInvitesService,
    private api: ApiService
  ) {
  }

  ngOnInit(): void {
    this.activeOrganizationLookup('a');
    this.formValueChangesSub = this.invitesForm.valueChanges
    .pipe(
      debounceTime(250),
      tap((val: any) => {
        const { orgName } = val;

        if (orgName != null) {
          if (typeof orgName === 'string' && orgName.length >= 3) {
            this.groupData = null;
            this.invitesForm.controls['orgName'].setErrors({'incorrect':true});
            this.activeOrganizationLookup(orgName);
          } else if (typeof orgName === 'object') {
            this.OrgEntityGuid = this.invitesForm.get('orgName')?.value!;
            this.groupLookup(this.OrgEntityGuid.value);
            
          }
        } else {
          this.groupData = null;
          this.disableFlag = true;
          this.invitesForm.controls['orgName'].setErrors({'incorrect':true});
        }
        if (this.invitesForm.valid) {
          this.disableFlag = false;
        } else {
          this.disableFlag = true;
        }
      })
      
    )
    .subscribe();

  }

  displayFn(search: any): string {
    return search && search.name ? search.name : search?.length > 0 ? search : '';
  }

  onSubmitUsers() {
    const { orgName, userEmail, group } = this.invitesForm.getRawValue();
    const formValues = {
      //@ts-ignore
      EntityGuid: orgName?.value ? orgName?.value : '',
      UserEmail: userEmail ? userEmail : '',
      //@ts-ignore
      GroupIds: group ? group : [],
      acceptUrl: `${environment?.domainName}/authentication/accept-invitaion-confirmed`,
      statusId: 3, // Skip Archived Organizations
    };
    
    this.userInvitesService
      .sendInvite(formValues)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Invitation Sent' }})
          this.closeModel();
        },
        error: (e) => {
          console.log(e);
        },
      });
    
    return;
  }

  closeModel() {
    this.dialogRef.close();
  }

  // Lookup only active organizations, not archived
  activeOrganizationLookup(val: any) {
    if (val === '') {
      this.activeOrganizationLookup('a');
    } else {
      this.api.lookups
      .getActiveOrganization(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.orgNamesData = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }

  groupLookup(val: any) {
    if (val === '') {
      this.groupLookup('a');
    } else {
      this.api.lookups
      .getGroup(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.groupData = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }
}
