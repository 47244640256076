import { Component, OnInit, AfterViewInit, inject, effect } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, tap, throwError } from 'rxjs';
import { ApiService } from 'src/app/api/services/api.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CoopUserAssociationStore } from 'src/app/api/services/cooperations/user-association/coop-user-association.store';
import { CoopUserAssociationService } from 'src/app/api/services/cooperations/user-association/coop-user-association.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { ActivatedRoute, Router } from '@angular/router';
import { StatusRoleComponent } from 'src/app/shared/modals/status-role/status-role.component';
import { AddUserToCooperativeComponent } from 'src/app/shared/modals/add-user-to-cooperative/add-user-to-cooperative.component';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-user-association',
    templateUrl: './user-association.component.html',
    styleUrls: ['./user-association.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class UserAssociationComponent implements OnInit, AfterViewInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly authStore = inject(AuthStore);
  readonly coopUserAssociationStore = inject(CoopUserAssociationStore);
  paramsReq: any;
  defaultSortColumn: string = 'associatedDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
      filterData: [
        { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -45 },
        { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
        { name: 'Search', formControlName: 'search', type: 'search' },
        { name: 'Cooperative', formControlName: 'orgName', type: 'searchDropdown', defaultValue: undefined, lookup: [] },
        { name: 'Coop Role', formControlName: 'orgRole', type: 'dropdown', lookup: this.lookupsStore?.orgRolesSignal },
        { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal },
        { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore.entityStatusSignal },
      ],
      openFilters: true,
      columns: [
        { header: 'Associated Date', controlName: 'associatedDate', displayPipe: 'date'},
        { header: 'First', controlName: 'first' },
        { header: 'Last', controlName: 'last' },
        { header: 'Email', controlName: 'email', displayPipe: 'truncate'},
        { header: 'Cooperative Name', controlName: 'organizationName' },
        { header: 'Role', controlName: 'orgRole' },
        { header: 'State', controlName: 'state' },
        { header: 'Status', controlName: 'status' },
        { header: 'Edit', controlName: 'action',
          actionColumnitems: [
            { icon: 'edit', buttonAction: 'edit' },
          ]
        },
      ],
      data: new MatTableDataSource<any>(),
      totalNumberOfItems: 0,
      allowExportCSV: false,
      addButtonName: 'Add Learner',
      disableflag: false,
      isLoading: false,
      fetchData: false,
      displaySearch: true,
    };

  constructor(
    private coopUserAssociationService: CoopUserAssociationService,
    private lookupsService: LookupsService,
    private api: ApiService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      gridDatasource = [];
      const coopUserAssociation = this.coopUserAssociationStore.coopUserAssociationSignal() ?? {};
      if (coopUserAssociation.totalNumberOfItems != null) {
        const { rows, totalNumberOfItems } = coopUserAssociation;
        rows?.forEach((admin: any) => {
          const datasource = gridDatasource;
          datasource.push(admin);
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.lookupsService.getEntityStatus();
    this.lookupsService.getStates();
    this.lookupsService.getOrgRoles();
    this.lookupsService.getEntityStatus();
    this.route.queryParams
    .subscribe(params => {
      if (params.orgRole) {
        this.gridOptions.filterData.find((filter) => filter.formControlName === 'orgRole')!.defaultValue = params.orgRole;
      }
      if (params.orgName) {
        this.api.lookups
        .getCooperative(params.orgName)
        .pipe(
          catchError((e) => throwError(() => e)),
          tap((data: any) => {
            if (data) {
              const testdata = data.find((coop: any) => {
                const nameParts = coop.name.split(params.orgName + " - ");
                return nameParts.length === 2 && nameParts[0] === "" && coop.name.startsWith(params.orgName + " - ");
              });
              this.gridOptions.filterData.find((filter) => filter.formControlName === 'orgName')!.lookup = data;
              this.gridOptions.filterData.find((filter) => filter.formControlName === 'orgName')!.defaultValue = testdata;
            }
          })
        )
        .subscribe({
          next: () => {
            this.gridOptions.fetchData = true;
          },
          error: (e) => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
          },
        });
      } else {
        this.gridOptions.fetchData = true;
      }
    });

    this.cooperativeLookup("");
  }

  ngAfterViewInit() {
    this.router.navigate([],{queryParams: {}});
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    var useReportingDB = this.authStore.useReportingDBSignal() ?? false;

    const { search, startDate, endDate, stateId, orgName, status, orgRole, sortOrder, pageSize, pageNumber } = rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      StateId: stateId ? stateId : '',
      Status: status ? status : '',
      //@ts-ignore
      EntityGuid: orgName?.value ? orgName?.value : '',
      OrgRoleId: orgRole ? orgRole : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      UseReportingDB: useReportingDB ? useReportingDB : false,
    };

    this.paramsReq = params;
    this.coopUserAssociationService.getAll(params).subscribe();

  }

  cooperativeLookup(val: any) {
      this.api.lookups
      .getCooperative(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.gridOptions.filterData.find((filter) => filter.formControlName === 'orgName')!.lookup = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) { 
      switch (true) {
        case sort.active === 'associatedDate':
          activeSort = 'associatedDate';
          break;
        case sort.active === 'first':
          activeSort = 'firstName';
          break;
        case sort.active === 'last':
          activeSort = 'lastName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'organizationName':
          activeSort = 'orgName';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'orgRole':
          activeSort = 'orgRole';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  formChanges(formChanges: any) {
    const { orgName } = formChanges;
    if (typeof orgName === 'string' && orgName.length >= 3) {
      this.cooperativeLookup(orgName);
    }
  }

  editButton(row: any) {
    const dialogRef = this.dialog.open(StatusRoleComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: {
        items: row,
        entityType: 'Cooperative'
      },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.gridOptions.fetchData = true;
    });
  }

  openAddUserModal() {
    const dialogRef = this.dialog.open(AddUserToCooperativeComponent, { panelClass: 'full-screen-modal-medium-mobile-panel-class', data: { items: ''}});
    dialogRef.afterClosed().subscribe((value) => {
      this.gridOptions.fetchData = true;
    });
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // exportCSV
    } else if (event.action === 'formChanges') {
      this.formChanges(event.data);
    } else if (event.action === 'clearFilters') {
      // clearFilters
    } else if (event.action === 'addButton') {
      this.openAddUserModal();
    }
    // 'edit' is specific to this component 
    else if (event.action === 'edit') {
      this.editButton(event.data);
    }
  }
}
