import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { CooperativeAll } from './cooperative-all.model';
import { CooperativeAllStore } from './cooperative-all.store';
import { ApiService } from '../../../../api/services/api.service';

@Injectable({ providedIn: 'root' })
export class CoopAllService {
  cooperativeAllStore = inject(CooperativeAllStore);
  constructor(private api: ApiService) {}

  all(params: any) {
    this.cooperativeAllStore.setLoading(true);
    return this.api.coopAll.getAll(params).pipe(
      tap((entities: any) => {
        this.cooperativeAllStore.setAll(entities);
        this.cooperativeAllStore.setLoading(false);
      })
    );
  }

  addCooperative(body: any) {
    return this.api.coopAll.addCooperative(body);
  }

  emailExists(email: string) {
    return this.api.auth.emailExist(email);
  }

  entityUserEmailExist(email: string, entityGuid: string) {
    return this.api.auth.entityUserEmailExist(email, entityGuid);
  }

}
