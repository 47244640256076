<h1 class="learner">Courses</h1>
<div [formGroup]="filtersForm">
  <div>
    <a class="flex primary-link cursor-pointer items-center" (click)="openDialog('Add', null)">
      <mat-icon class="custom-mat-icon">add</mat-icon>
      <p class="btn btn-icon-text font-bold medium">Add Course</p>
    </a>
  </div>
  <mat-toolbar class="mb-5 pt-2 lg:pb-0 justify-center mat-toolbar-multi-row px-0" style="background: transparent;">

    <div class="flex flex-col w-full" [formGroup]="filtersForm">
      <div class="px-4">
        <div class="flex flex-row justify-end items-center">
          <div class="flex flex-row flex-wrap">
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>From</mat-label>
                <input matInput formControlName="fromDate" [matDatepicker]="picker1" />
                <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                <mat-datepicker #picker1 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="w-full lg:w-1/2">
              <mat-form-field class="datePicker-2 mr-4">
                <mat-label>To</mat-label>
                <input matInput formControlName="toDate" [matDatepicker]="picker2" />
                <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                <mat-datepicker #picker2 disabled="false"></mat-datepicker>
              </mat-form-field>
            </div>
          </div>
          <button class="flex items-center primary-link h-auto w-auto" (click)="closeFilter()">
            <app-filter-icon></app-filter-icon>
          </button>
        </div>
      </div>
      
      <div class="flex justify-between items-center leading-none lg:gap-4 w-full flex-col lg:flex-row px-4 bg-color-lightBlue" *ngIf="openFilters">
        <div class="w-full lg:w-1/4">
          <div class="w-full">
            <mat-form-field class="w-full text-[16px]" hideRequiredMarker>
              <mat-label>Search</mat-label>
              <input matInput placeholder="Search" #input formControlName="search" />
            </mat-form-field>
          </div>
        </div>
        <div class="flex justify-end items-center leading-none lg:gap-4 lg:w-3/4 w-full flex-col lg:flex-row">
          <div class="w-full lg:w-1/4">
            <mat-form-field class="w-full">
              <mat-label>Training</mat-label>
              <mat-select formControlName="trainingType" panelClass="mat-select-mt3">
                <mat-option [value]="null">All</mat-option>
                <mat-option *ngFor="let training of lookupsStore?.trainingsSignal()" [value]="training.value"
                >{{ training.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          
          <div class="w-full lg:w-1/4">
            <mat-form-field class="w-full">
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" panelClass="mat-select-mt3">
                <mat-option [value]="null">All</mat-option>
                <mat-option *ngFor="let state of lookupsStore?.statesSignal()" [value]="state.value"
                  >{{ state.label }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="w-full lg:w-1/4">
            <mat-form-field class="w-full">
              <mat-label>Status</mat-label>
              <mat-select formControlName="status" panelClass="mat-select-mt3">
                <mat-option [value]="null">All</mat-option>
                <mat-option value="True">Published</mat-option>
                <mat-option value="False">Unpublished</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
    
          <div class="flex items-center">
            <button class="btn-primary-outlined small" (click)="applyFilter()">
              Search
            </button>
            <div class="lg:pl-3">
              <button type="button" class="btn-link-alt opacity-100" (click)="clearFilters()">
                <mat-icon> close</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
  
    </div>
  </mat-toolbar>
  <mat-accordion class="course-grid-accordion">
    <mat-expansion-panel *ngFor="let course of dataSource?.filteredData">
      <mat-expansion-panel-header>
        <div *ngIf="course?.courseIcon" class="header-icon">
          <img src="assets/images/individual-courses/{{ course?.courseIcon }}.svg" alt="Course Icon" />
        </div>
        <div class="flex flex-col">
          <div class="flex items-center">
            <h1>{{ course?.learningPathName }}</h1>
            <h2 class="mx-4">{{ course?.courseName }}</h2>
            <button mat-icon-button (click)="openDialog('Edit', course)">
              <mat-icon>edit</mat-icon>
            </button>
          </div>
          <div class="flex items-center mt-3">
            <div class="tags">
              <h4 class="training-type" [ngClass]="getTrainingTagClass(course?.trainingName)">
                {{ getTrainingTagTitle(course?.trainingName) }}
              </h4>
              <h4 class="status" [ngClass]="course?.published ? 'published' : 'unpublished'">
                {{ course?.published ? 'Published' : 'Unpublished' }}
              </h4>
              <h4 *ngIf="course?.retiredDate" class="retire-date">{{ course?.retiredDate | date : 'M/d/yy' }}</h4>
              <h4 class="state" [ngClass]="{'multi': course?.courseStates?.length > 1, 'single': course?.courseStates?.length === 1}">
                {{ course?.courseStates?.length > 2 ? 'NATL' : course?.courseStates?.length === 1 ? getStateName(course?.courseStates[0]) : '' }}
              </h4>
              <h4 *ngIf="course?.isPaid" class="paid">{{ course?.isPaid ? '$$' : '' }}</h4>
              <h4 *ngIf="course?.isCE" class="ce">{{ course?.isCE ? 'CE' : '' }}</h4>
              <h4 *ngIf="course?.authority" class="ce">{{ course?.authority }}</h4>
            </div>
            <div class="info ml-4">
              <div>{{ course.learningPathTemplate ?? 'No Cert PDF' }}</div>
              <div *ngIf="course.learningPathCertExpiry">{{ course.learningPathCertExpiry }} Months</div>
              <p>
                Created on <span class="font-semibold">{{ course.createDate | date : 'MM/dd/yy' }}</span> by
                <span class="font-semibold">{{ course.createdBy }}</span>
              </p>
            </div>
          </div>
        </div>
      </mat-expansion-panel-header>
      <div  class="course-card-grid w-full">
        <a class="course-card-grid-item no-data-overlay-parent" *ngFor="let courseVersion of course.courseVersions; let i = index" (click)="openCourseVersion('Edit', course, i)">
          <app-course-card [courseVersion]="courseVersion"></app-course-card>
          <div class="course-card-overlay">
            <div class="course-card-overlay-content">
              View
            </div>
          </div>
        </a>
        <div *ngxPermissionsOnly="['courses:edit']" class="course-card-grid-item-add" title="Add A Course Version" (click)="openCourseVersion('Add', course)">
          <mat-icon>add</mat-icon>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  <mat-paginator [length]="100" [pageSize]="20" [pageSizeOptions]="pageSizeOptions" aria-label="Select page">
  </mat-paginator> 
</div>
