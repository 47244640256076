import { components } from "../../interfaces/swagger-types";
export type AllUser = components['schemas']['UsersGridViewModel'];

export interface AllUsers {
  allUsersSignal: AllUser | null;
  loadingSignal: boolean;
}

export const initialState: AllUsers = {
  allUsersSignal: null,
  loadingSignal: false,
};