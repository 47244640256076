import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable, map } from 'rxjs';
import { CatalogService } from 'src/app/api/services/courses/catalog.service';
import { LookupsService, LookupResult } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, AsyncPipe } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-add-course-catalog',
    templateUrl: './add-course-catalog.component.html',
    styleUrls: ['./add-course-catalog.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        AsyncPipe,
        NgxPermissionsModule
    ],
})

export class AddCourseCatalogComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  modalTitle: string = 'Add';

  form = this.fb.group({
    trainingId: this.fb.control(0, Validators.required),
    courseStates: this.fb.control(null, Validators.required),
    courseId: this.fb.control(0, Validators.required),
    jobTitleId: this.fb.control(0, Validators.required),
    mappings: this.fb.array([]),
  });

  dropdownData: {
    options$: Observable<LookupResult[]>;
    optionsHasValue$: Observable<boolean>;
  }[] = [];

  get mappings() {
    return this.form.get('mappings') as UntypedFormArray;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCourseCatalogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action?: string, course?: any },
    public lookupsService: LookupsService,
    private catalogService: CatalogService
  ) {}

  ngOnInit() {
    this.lookupsService.getTrainings();
    this.lookupsService.getIndustries();
    this.lookupsService.getStates();

     if(this.data.action === 'edit') {
      this.populateMappings(this.data.course.id!)
      this.lookupsService.getCourseStatesByCourseId(this.data.course.id!);
      this.modalTitle = 'Edit';
      const course = this.data.course.stateIds;
      this.form.get('courseStates')?.patchValue(course);
     }
     
    this.form.get('trainingId')?.valueChanges.subscribe((trainingId) => {
      this.lookupsService.getCourseTrainings(+trainingId!);
    });

    this.form.get('courseId')?.valueChanges.subscribe((courseId) => {
      this.lookupsService.getCourseStatesByCourseId(courseId!);
      this.populateMappings(courseId!);
    });
  }

  populateMappings(courseId: number) {
    this.form.setControl('mappings', this.fb.array([]));
    this.catalogService.getCatalogItem(courseId).subscribe((item: any) => {
      this.lookupsService.getCourseTrainings(item.trainingId);

      this.form.patchValue(item, { emitEvent: false });
      item.mappings.forEach((mapping: any) => {
        this.mappings.push(
          this.fb.group({
            industryId: this.fb.control(mapping.industryId, Validators.required),
            professionIds: this.fb.control(mapping.professionIds, Validators.required),
            jobTitleIds: this.fb.control(mapping.jobTitleIds),
          })
        );

        this.addDropdownData(this.mappings.length - 1);
      });
    });
  }

  addDropdownData(index: number) {
    const existingData = Boolean(this.dropdownData[index]);
    if (existingData) {
      this.dropdownData[index]!.options$ = this.lookupsService.lookupProfessions(
        this.mappings.controls[index]!.get('industryId')?.value
      );
      this.dropdownData[index]!.optionsHasValue$ = this.dropdownData[index]!.options$.pipe(
        map((options) => options.length > 0)
      );
    } else {
      this.dropdownData.push({
        options$: this.lookupsService.lookupProfessions(this.mappings.controls[index]!.get('industryId')?.value),
        optionsHasValue$: new Observable(),
      });
      this.dropdownData[index]!.optionsHasValue$ = this.dropdownData[index]!.options$.pipe(
        map((options) => options.length > 0)
      );
    }
  }

  itemAsForm(index: number) {
    return this.mappings.controls[index] as UntypedFormGroup;
  }

  addFormItem() {
    this.mappings.push(
      this.fb.group({
        industryId: this.fb.control('', Validators.required),
        professionIds: this.fb.control('', Validators.required),
        jobTitleIds: this.fb.control(''),
      })
    );
  }

  removeFormItem(index: number) {
    this.mappings.removeAt(index);
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.form.valid) {
      this.catalogService.upsertCatalogItem(this.form.value).subscribe(() => {
        this.closeModal();
      });
    } else {
      this.form.markAllAsTouched();
    }
  }
}
