import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AddCourseComponent } from 'src/app/shared/modals/add-course/add-course.component';
import { NgClass, NgIf, NgFor, DatePipe } from '@angular/common';

@Component({
    selector: 'app-course-card',
    templateUrl: './course-card.component.html',
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        NgFor,
        DatePipe,
    ],
})
export class CourseCardComponent implements OnInit {
  @Input() courseVersion: any;

  constructor( private dialog: MatDialog) {}

  ngOnInit() {
  }

  openDialog(action: 'add' | 'edit' | 'duplicate', item?: any) {
    this.dialog.open(AddCourseComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      backdropClass: 'dialog-bg-darkgrey',
      data: { action, item },
    });
  }
}
