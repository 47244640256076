import { Component, effect, inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, tap, throwError } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ResentVerificationComponent } from 'src/app/shared/modals/resent-verification/resent-verification.component';
import { ClearCacheComponent } from 'src/app/shared/modals/users-clearcache/users-clearcache.component';
import { AllUsersStore } from 'src/app/api/services/users/all-users.store';
import { AllUsersService } from 'src/app/api/services/users/all-users.service';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { Router } from '@angular/router';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-all-users',
    templateUrl: './all-users.component.html',
    styleUrls: ['./all-users.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})
export class AllUsersComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly allUsersStore = inject(AllUsersStore);
  paramsReq: any;
  defaultSortColumn: string = 'registeredDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: 0 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Opt-Out', formControlName: 'notificationOptOut', type: 'dropdown', lookup: this.lookupsStore.userOptOutNotificationTypesSignal },
      { name: 'Learner Role', formControlName: 'orgRole', type: 'dropdown', lookup: this.lookupsStore?.rolesSignal },
      { name: 'Confirmed?', formControlName: 'confirmed', type: 'checkBox', lookup: this.lookupsStore.statesSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Registered Date', controlName: 'registeredDate', displayPipe: 'date' },
      { header: 'First', controlName: 'first' },
      { header: 'Last', controlName: 'last' },
      { header: 'Email', controlName: 'email', displayPipe: 'truncate' },
      { header: 'Opt-Out', controlName: 'notificationOptOuts' },
      { header: 'Email Confirmed', controlName: 'emailConfirmed' },
      { header: 'Role', controlName: 'role' },
      { header: 'Action', controlName: 'action', actionColumnitems: [{ icon: 'edit', buttonAction: 'edit' }] },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };
  constructor(
    private dialog: MatDialog,
    private allUsersService: AllUsersService,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private router: Router
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      const all = this.allUsersStore.allUsersSignal() ?? {};
      gridDatasource = [];
      if (all.totalNumberOfItems != null) {
        const { rows, totalNumberOfItems } = all;
        rows?.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.lookupsService.getUserOptOutNotificationTypes();
    this.lookupsService.getRoles();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true
    if (rawParams) {
      const { search, startDate, endDate, orgRole, notificationOptOut, confirmed, sortOrder, pageSize, pageNumber } = rawParams;

      const newSortOrder = this.sortField(sortOrder);
      const params = {
        PageSize: pageSize ?? 20,
        PageNumber: pageNumber,
        SearchTerm: search?.length! > 0 ? search : '',
        NotificationOptOut: notificationOptOut ? notificationOptOut : '',
        RoleId: orgRole ? orgRole : '',
        FromDate: startDate,
        ToDate: endDate,
        EmailConfirmed: confirmed ? false : '',
        SortField: newSortOrder
          ? newSortOrder.active && newSortOrder.direction
            ? newSortOrder.active
            : this.defaultSortColumn
          : this.defaultSortColumn,
        SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      };

      this.paramsReq = params;
      this.allUsersService.getAll(params).subscribe();
    }
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'registeredDate':
          activeSort = 'registeredDate';
          break;
        case sort.active === 'first':
          activeSort = 'firstName';
          break;
        case sort.active === 'last':
          activeSort = 'lastName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'emailConfirmed':
          activeSort = 'emailConfirmed';
          break;
        case sort.active === 'role':
          activeSort = 'orgRole';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  openModal(row: any, action: any) {
    if (action === 'userall-confirmEmail') {
      const dialogRef = this.dialog.open(ResentVerificationComponent, {
        panelClass: 'full-screen-modal-medium-mobile-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          buttonAction: action,
          items: row,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.fetchAll();
      });
    } else if (action === 'userall-clearCache') {
      const dialogRef2 = this.dialog.open(ClearCacheComponent, {
        panelClass: 'full-screen-modal-medium-mobile-panel-class',
        backdropClass: 'dialog-bg-darkgrey',
        data: {
          buttonAction: action,
          items: row,
        },
      });
      dialogRef2.afterClosed().subscribe(() => {
        this.fetchAll();
      });
    }
  }

  exportCsv() {
    const paramsReq = {
      SearchTerm: this.paramsReq?.SearchTerm ? this.paramsReq?.SearchTerm : '',
      FromDate: this.paramsReq?.FromDate ? this.paramsReq?.FromDate : '',
      ToDate: this.paramsReq?.ToDate ? this.paramsReq?.ToDate : '',
      NotificationOptOut: this.paramsReq?.NotificationOptOut ? this.paramsReq?.NotificationOptOut : '',
      RoleId: this.paramsReq?.RoleId ? this.paramsReq?.RoleId : '',
      EmailConfirmed: this.paramsReq?.EmailConfirmed ? this.paramsReq?.EmailConfirmed : '',
    };
    this.allUsersService
      .export(paramsReq)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Success, message: 'Report will be sent to your email' },
          });
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' },
          });
        },
      });
  }

  goToEdit(row: any) {
    this.router
      .navigate(['/learners/all-learners/edit-learners', row?.userProfileId], {
        state: { name: `${row.first} ${row.last}` },
      })
      .then();
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv();
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      // add button
    }
    // 'edit' is specific to this component
    else if (event.action === 'edit') {
      this.goToEdit(event.data);
    }
  }
}
