import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, throwError } from 'rxjs';
import { UserInvitesService } from 'src/app/api/services/organizations/user-invites/user-invites.service';
import { environment } from '../../../../environments/environment';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { NgIf, TitleCasePipe, DatePipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-resent-verification',
    templateUrl: './resent-verification.component.html',
    styleUrls: ['./resent-verification.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        NgIf,
        TitleCasePipe,
        DatePipe,
        NgxPermissionsModule
    ],
})
export class ResentVerificationComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ResentVerificationComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private userInvitesService: UserInvitesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {}
  close() {
    this.dialogRef.close();
  }

  submitButton(button: any) {
    if (button === 'userall-confirmEmail') {
      const email = this.data?.items?.email;
      this.userInvitesService
        .allUsersConfirmEmail(email)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Email Confirmed' }})
            this.close();
          },
          error: (e) => {
            console.log(e);
          },
        });
      return;
    } else if (button === 'userInvite-resent') {
      const formValues = {
        inviteGuid: this.data?.items?.inviteGuid,
        acceptUrl: `${environment?.domainName}/authentication/accept-invitaion-confirmed`,
      };
      this.userInvitesService
        .resendInvitation(formValues)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Invitation Sent' }})
            this.close();
          },
          error: (e) => {
            console.log(e);
          },
        });
      return;
    } else if (button === 'userInvite-cancel') {
      this.userInvitesService
        .cancelInvites(this.data?.items?.inviteGuid)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Invitation Cancelled' }})
            this.close();
          },
          error: (e) => {
            console.log(e);
          },
        });
      return;
    }
  }
}
