import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { UserInvites, initialState } from './user-invites.model';

export const UserInvitesStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(userInvitesSignal: UserInvites["userInvitesSignal"]) {
      patchState(store, {userInvitesSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)