<div class="flex justify-center flex-col h-full p-3">
  <div class="flex justify-end">
    <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
      <p class="bold mb-0">Close</p>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="admin-modal-body">
    <div class="px-12 py-8">
        <div class="text-center px-5 py-3">
            <h2 class="font-bold mb-5"> Archive {{data.entityType}}</h2>
        </div>
        <p>
          Are you sure you want to archive this {{data.entityType.toLowerCase()}}? All users associated with this {{data.entityType.toLowerCase()}} will lose access.
        </p>
        <div class="flex flex-col">
          <mat-form-field>
            <mat-label>Type "ARCHIVE" to archive this {{data.entityType.toLowerCase()}}</mat-label>
            <input matInput type="text" [formControl]="confirm" />
          </mat-form-field>
        </div>
        <div class="pt-8 mb-4 flex justify-center">
          <button  *ngxPermissionsOnly="['orgs-info:edit']"  id="button-primary" 
          (click)="archiveOrg()" 
          [disabled]="confirm.value.toLowerCase() !== 'archive' || disableFlag"
          class="btn-primary medium" 
          type="button">
            Archive
          </button>
        </div>  
    </div>
  </div>
</div>