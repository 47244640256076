<div class="p-5 pb-0" *ngIf="pageInfo?.urls?.length > 0">
  <div class="flex flex-wrap">
    <div class="">
      <div class="breadcrumb flex flex-wrap align-items-center">
        <ul class="list-style-none flex flex-wrap  text-black font-bold items-center">
          <ng-template ngFor let-url [ngForOf]="pageInfo?.urls" let-last="last">
            <li class="breadcrumb-item flex items-center capitalize" *ngIf="!last" [routerLink]="url.url">
              <a [routerLink]="url.url">{{ url.title }}</a>
              <span class="flex pr-1 pl-1 breadcrumb-item">
                <mat-icon> navigate_next </mat-icon>
              </span>
            </li>
            <li class="breadcrumb-item flex items-center capitalize cursor-default active" *ngIf="last">{{ url.title }}<ng-container
                *ngIf="dynamicName"><span class="flex pr-1 pl-1 breadcrumb-item">
                  <mat-icon> navigate_next </mat-icon>
                </span>{{ dynamicName }}</ng-container></li>
          </ng-template>
        </ul>
      </div>
    </div>
  </div>
</div>