import { Component, OnInit, OnDestroy, Inject, inject } from '@angular/core';
import { FormGroup, UntypedFormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ValidationErrors } from 'src/app/api/interfaces/validation-errors';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-add-product',
  templateUrl: './add-product.component.html',
  styleUrls: ['./add-product.component.scss'],
  standalone: true,
  imports: [
    MatIconModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    NgFor, 
    MatOptionModule,
    NgIf, 
    MatInputModule, 
    MatDatepickerModule, 
    MatDialogModule, 
    NgxPermissionsModule
  ]
})

export class AddProductComponent implements OnInit, OnDestroy  {
  readonly lookupsStore = inject(LookupsStore);
  destroy$: Subject<boolean> = new Subject<boolean>();
  form: FormGroup = this.fb.group({
    learningPath: this.fb.control('', Validators.required),
    stripePriceId: this.fb.control('', Validators.required),
    startDate: this.fb.control('', Validators.required),
    endDate: this.fb.control(''),
    price: this.fb.control('', Validators.compose([Validators.required, Validators.pattern(/^\$?\d+(,\d{3})*(\.\d{1,2})?$/)])),
  });
  learningPath: any[] = [];
  disableButton: boolean = false;

  constructor(
    private fb: UntypedFormBuilder,
    public matDialogRef: MatDialogRef<AddProductComponent>,
    public lookupsService: LookupsService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private coursesService: CoursesService,
  ) {}

  ngOnInit(): void {
    if (this.data.items) {
      this.form.patchValue({
        learningPath: this.data.items.learningPathId,
        stripePriceId: this.data.items.stripePriceId,
        startDate: this.data.items.startDate,
        endDate: this.data.items.endDate,
        price: this.data.items.price,
      });
    }
    this.lookupsService.getLearningPath();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  createProduct() {
    this.disableButton = true;
    if (!this.form.valid) {
      this.disableButton = false;
      this.form.markAllAsTouched();
      return;
    }
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: any) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
      }
      return of(null);
    };
    const { learningPath, stripePriceId, startDate, endDate, price } = this.form.getRawValue();
    const body = {
      ProductId: this.data.items.productId ? this.data.items.productId : 0,
      LearningPathId: parseInt(learningPath),
      StripePriceId: stripePriceId.trim(),
      StartDate: startDate,
      EndDate: endDate ? endDate : '',
      Price: typeof price === 'number' && !Number.isNaN(price) ? price : parseFloat(price.replace('$', '')).toFixed(2),
    };

    this.coursesService.postAddPaidProduct(body).pipe(takeUntil(this.destroy$)).subscribe(
      (res) => {
        if (this.data.items.productId) {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Product updated successfully' }})
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Product added successfully' }})
        }
        this.matDialogRef.close();
      },
      (error) => {
        this.disableButton = false;
        if (error.error.validationErrors) {
          validationErrorHandler(error.error.validationErrors);
        } else {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error occured' }})
        }
      }
    );
  }

  close() {
    this.matDialogRef.close();
  }
}
