import { Component, Inject, OnInit } from '@angular/core';
import { OrgAllService } from 'src/app/api/services/organizations/all/org-all.service';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-add-enterprise-subscription',
  templateUrl: './add-enterprise-subscription.component.html',
  styleUrls: ['./add-enterprise-subscription.component.scss'],
  standalone: true,
  imports: [
    MatIconModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatCardModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    MatOptionModule, 
    NgIf, 
    MatInputModule, 
    MatDatepickerModule,
    NgxPermissionsModule
  ]
})

export class AddEnterpriseSubscriptionComponent implements OnInit {
  disableFlag = false;
  initialSnapshot!: any;
  loaded = false;
  errors!: { field: string; message: string }[];

  form = this.fb.group({
    certificateBased: this.fb.control(null, Validators.required),
    startDate: this.fb.control(null),
    expireDate: this.fb.control(null),
    amount: this.fb.control(null, [this.integerValidator]),
    seats: this.fb.control(null, [this.integerValidator]),
    notes: this.fb.control(null, Validators.maxLength(500)),
  });

  constructor(
    private orgAllService: OrgAllService,
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddEnterpriseSubscriptionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: 'Add' | 'Edit'; item?: any },
    private coursesService: CoursesService,
    private snackBar: MatSnackBar
  ) {}

  integerValidator(control: any) {
    const value = control.value;

    if (value === null || value === undefined || value === '') {
      return null;
    }

    const isValid = Number.isInteger(Number(value));

    return isValid ? null : { 'notAnInteger': true };
  }

  ngOnInit(): void {
    this.initialSnapshot = this.form.value;
    if (this.data.action === 'Edit' && this.data.item) {
      const {
        numberOfSeats,
        ...remaining
       } = this.data.item;
      const patchData = {
        seats: numberOfSeats,
        ...remaining,
      }
      this.form.patchValue(patchData);
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  calculateDate(val: any) {
    return new Date(val).toISOString();
  }

  onSubmit() {
    this.errors = [];
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.disableFlag = true;
    
    const formValues = this.form.getRawValue();
    const body = {
      Id: this.data.action === 'Add' ? 0 : this.data.item.enterpriseEntityId,
      entityId: this.data.item.entityId,
      CertificateBased: formValues?.certificateBased ?? false,
      StartDate: formValues?.startDate ? this.calculateDate(formValues?.startDate) : '',
      ExpireDate: formValues?.expireDate? this.calculateDate(formValues?.expireDate) : '',
      Amount: Number(formValues?.amount) ?? '',
      Seats: Number(formValues?.seats) ?? '',
      Notes: formValues?.notes ?? null,
    };
    this.orgAllService.updateEnterprise(body).subscribe({
      next: (res: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Enterprise Sucess' }})
        this.dialogRef.close('success');
      },
      error: (err: any) => {
        console.log(err);
        
        this.disableFlag = false;
        if (err.status === 400) {
          this.errors = err;
        } else if (err.status === 500) {
          this.errors = [{ field: 'Server Error', message: err.error.split('\n')[0] }]
        } else {
          this.errors = [{ field: 'general', message: 'Something went wrong' }]
        }
      },
    });
  }
}
