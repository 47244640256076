import { Component, ElementRef, Inject, OnInit, ViewChild, AfterViewInit, inject } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
    selector: 'app-add-course',
    templateUrl: './add-course.component.html',
    styleUrls: ['./add-course.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        NgFor,
        MatOptionModule,
        NgIf,
        MatInputModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        NgxPermissionsModule
    ],
})
export class AddCourseComponent implements OnInit, AfterViewInit {
  readonly lookupsStore = inject(LookupsStore);
  disableFlag = true;
  initialSnapshot!: any;
  loaded = false;
  errors!: { field: string; message: string }[];
  courseControl!: any;
  @ViewChild('certFile') certFileInput!: ElementRef;
  selectedCertFile: any;

  form = this.fb.group({
    course: this.fb.group({
      trainingId: this.fb.control(null, Validators.required),
      learningPathName: this.fb.control(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
      learningPathCertExpiry: this.fb.control(null),
      learningPathTemplate: this.fb.control(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
      badgeName: this.fb.control(null, Validators.compose([Validators.required, Validators.maxLength(100)])),
      authority: this.fb.control(null, Validators.maxLength(30)),
      isCE: this.fb.control(false),
      isPartnership: this.fb.control(false),
      isOrganizationOnly: this.fb.control(false),
      requiresSubscription: this.fb.control(false),
      certInfo: this.fb.control(null, Validators.maxLength(1000)),
      partnershipInfo: this.fb.control(null, Validators.maxLength(100)),
      courseName: this.fb.control(null, Validators.compose([Validators.required, Validators.maxLength(200)])),
      courseDescription: this.fb.control(null, Validators.required),
      published: this.fb.control(false),
      sort: this.fb.control(null, Validators.required),
      courseStates: this.fb.control(null, Validators.required),
      metaDescription: this.fb.control(null),
      brochureSlug: this.fb.control(null, Validators.maxLength(50)),
    }),
  });

  get isNewCourse() {
    return this.form.get('selectedCourseId')?.value === 0;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCourseComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: 'Add' | 'Edit'; item?: any },
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
    private snackBar: MatSnackBar
  ) {
    this.courseControl = this.form.controls.course;
  }

  ngOnInit() {
    this.initialSnapshot = this.form.getRawValue();
    this.lookupsService.getTrainings();
    this.lookupsService.getStates();
    this.lookupsService.getCertFiles();
    this.lookupsService.getBadgeFiles();

    if (this.data.action === 'Edit' && this.data.item) {
      const course = this.data.item;
      course.isPartnership = course.partnershipInfo ? true : false;
      this.form.get('course')?.patchValue(course);
      this.initialSnapshot = this.form.getRawValue();
      this.loaded = true;
    }
    this.disableFlag = false;
  }

  ngAfterViewInit() {
    this.certFileInput.nativeElement.addEventListener('change', (e: any) => {
      const file = e.target.files[0];
      if (file) {
        this.selectedCertFile = file;
        this.form.get('course')?.get('learningPathTemplate')?.reset();
        this.form.get('course')?.get('learningPathTemplate')?.disable();
      } else {
        this.selectedCertFile = null;
        this.form.get('course')?.get('learningPathTemplate')?.enable();
      }
    });
  }

  clearFileInput() {
    this.certFileInput.nativeElement.value = '';
    this.selectedCertFile = null;
    this.form.get('course')?.get('learningPathTemplate')?.enable();
  }

  reset() {
    var changedElements = document.querySelectorAll('.changed');
    changedElements.forEach((el) => {
      el.classList.remove('changed');
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.errors = [];
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.disableFlag = true;
    
    const formData = new FormData();
    if (this.selectedCertFile) {
      formData.append('certFile', this.selectedCertFile);
      this.form.get('course')?.get('learningPathTemplate')?.setValue(this.selectedCertFile.name);
    }

    const formValue = {
      courseId: this.data.action === 'Add' ? 0 : this.data.item.courseId,
      ...this.form.get('course')?.getRawValue(),
    };

    formData.append('modelJson', JSON.stringify(formValue));

    this.coursesService.postCreateNewCourse(formData).subscribe({
      next: (res: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Course created successfully' }})
        this.dialogRef.close('success');
      },
      error: (err: any) => {
        console.log(err);
        
        this.disableFlag = false;
        if (err.status === 400) {
          this.errors = err;
        } else if (err.status === 500) {
          this.errors = [{ field: 'Server Error', message: err.error.split('\n')[0] }]
        } else {
          this.errors = [{ field: 'general', message: 'Something went wrong' }]
        }
      },
    });
  }
}
