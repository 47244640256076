<h1 class="learner">{{ organizationName }}</h1>
<div class="back-to-link mb-4 lg:mb-0 pr-4">
  <a class="primary-link" 
    [routerLink]="fromEnterprisePage ? '/revenue/enterprise-subscriptions' : 
      (fromOrganizationPage ? '/organizations/all-organizations' : 
      (fromCooperativesPage ? '/cooperatives/all-cooperatives' : ''))"
  >Back to All {{ fromEnterprisePage ? 'Enterprise' : 
      (fromOrganizationPage ? 'Organizations' : 
      (fromCooperativesPage ? 'Cooperatives' : '')) }}
  </a>
</div>
<mat-tab-group #tabs>
  <mat-tab label="Information">
    <ng-container *ngTemplateOutlet="step1"></ng-container>
  </mat-tab>
  <mat-tab label="Subscription">
    <ng-container *ngTemplateOutlet="step2"></ng-container>
  </mat-tab>
  <mat-tab label="Pay-As-You-Go" *ngIf="isOrganization">
    <ng-container *ngTemplateOutlet="step3"></ng-container>
  </mat-tab>
  <mat-tab label="Enterprise" *ngIf="isOrganization">
    <ng-container *ngTemplateOutlet="step4"></ng-container>
  </mat-tab>
  <mat-tab label="Trainings" *ngIf="!isOrganization">
    <ng-container *ngTemplateOutlet="step5"></ng-container>
  </mat-tab>
</mat-tab-group>
<ng-template #step1>
  <form [formGroup]="form">
    <mat-card class="mb-10">
      <mat-card-content>
        <mat-toolbar class="bg-transparent h-12 px-0 text-black flex justify-between text-base font-bold w-full">
          <span>Profile Image</span>
        </mat-toolbar>
        <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
          <div class="flex sm:flex-row flex-col w-full mb-2 py-3">
            <div>
              <img
                *ngIf="showProfileImagePic; else other_image"
                [src]="localImageUrl"
                class="w-[6.25rem] h-[6.25rem] block object-cover border-2 rounded-full border-primary p-[4px] org_image"
                alt="{{ form?.value?.name }}"
              />
              <ng-template #other_image>
                <img
                  src="/assets/images/profile/individual-icon.svg"
                  class="w-[6.25rem] h-[6.25rem] block object-cover border-2 rounded-full border-primary p-[4px] org_image"
                  alt="{{ form?.value?.name }}"
                />
              </ng-template>
            </div>
            <div class="ml-4">
              <p class="mb-0">Upload your photo</p>
              <p class="mb-0">Photo should be at least 300px x 300px</p>
    
              <div class="flex sm:flex-row flex-col w-full pt-4">
                <div>
                  <label for="photos" class="mb-1">
                    <input type="file" hidden class="text-black" id="photos"
                      accept="image/png, image/jpeg, image/jpg, image/jfif, image/pjpeg, image/pjp" ng2FileSelect
                      [uploader]="photoUploader" #selectedPicture />
                    <div class="flex items-center">
                      <div class="btn-primary-outlined small text-center mb-1">Browse</div>
                      <div class="lds-ring" *ngIf="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    </div>
                    <mat-error *ngIf="requiredImage"> Required </mat-error>
                  </label>
                  <div *ngIf="failImageFlag" class="{{ failImageFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error">
                      We accept only jpg, png, jpeg, jfif, pjpeg, pjp files and file size must be less than 1mb</mat-error>
                  </div>
                  <div *ngIf="failSizeFlag" class="{{ failSizeFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error"> file size must be less than 1mb </mat-error>
                  </div>
                  <div *ngIf="failFlag" class="{{ failFlag ? 'mb-4' : '' }}">
                    <mat-error class="image-error"> We accept only jpg, png, jpeg, jfif, pjpeg, pjp files </mat-error>
                  </div>
                  <div *ngFor="let item of photoUploader.queue">
                    <div class="mb-1 w-full flex flex-wrap gap-1 items-center">
                      <p class="mb-0">
                        {{ item?.file?.name }}
                      </p>
                      <mat-icon class="cursor-pointer text-secondary"
                        (click)="item.remove(); clearSelectedPicture()">delete</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>{{isOrganization ? 'Organization' : 'Cooperative'}} Name</mat-label>
              <input type="text" class="text-black" matInput formControlName="name" />
              <mat-error *ngIf="form.controls['name'].hasError('required') && form.controls['name'].touched"
                >Required
              </mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Website</mat-label>
              <input class="text-black" type="text" matInput formControlName="website" />
              <mat-error *ngIf="form.controls['website'].hasError('required') && form.controls['website'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['website'].errors?.pattern">Please enter valid Website URL </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>Industry</mat-label>
              <mat-select formControlName="industryId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let industry of lookupsStore?.industriesSignal()" [value]="industry.value">
                  {{ industry.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['industryId'].hasError('required') && form.controls['industryId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['industryId'].errors?.message">{{
                form.controls['industryId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>State</mat-label>
              <mat-select formControlName="stateId" panelClass="mat-select-mt3">
                <mat-option *ngFor="let state of lookupsStore?.statesSignal()" [value]="state.value">
                  {{ state.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.controls['stateId'].hasError('required') && form.controls['stateId'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['stateId'].errors?.message">{{
                form.controls['stateId'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
     
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>HR Contact</mat-label>
              <input class="text-black" type="text" matInput formControlName="hrContact" />
              <mat-error *ngIf="form.controls['hrContact'].hasError('required') && form.controls['hrContact'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['hrContact'].errors?.message">{{
                form.controls['hrContact'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 ">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>HR Email</mat-label>
              <input class="text-black" type="text" matInput formControlName="hrEmail" />
              <mat-error *ngIf="form.controls['hrEmail'].hasError('required') && form.controls['hrEmail'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['hrEmail'].errors?.email && form.controls['hrEmail'].touched"
                >You must include a valid email.
              </mat-error>
              <mat-error *ngIf="form.controls['hrEmail'].errors?.message">{{
                form.controls['hrEmail'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-form-field class="w-full" hideRequiredMarker>
              <mat-label>HR Phone</mat-label>
              <input class="text-black" type="text" mask="(000) 000-0000" matInput formControlName="hrPhone" />
              <mat-error *ngIf="form.controls['hrPhone'].hasError('required') && form.controls['hrPhone'].touched"
                >Required
              </mat-error>
              <mat-error *ngIf="form.controls['hrPhone'].errors?.message">{{
                form.controls['hrPhone'].getError('message')
              }}</mat-error>
            </mat-form-field>
          </div>

          <div *ngIf="isOrganization" class="w-full mb-3">
            <mat-label>Note: You must have a group created for your public page to work.</mat-label>
          </div>

          <div class="w-full sm:w-1/2 pr-0 sm:pr-5 public-user-association" *ngIf="!isIndividual && isOrganization">
            <a
              class="text-right label-link underlined"
              href="https://mandatedreportertraining.zendesk.com/hc/en-us/articles/18755043082267"
              target="_blank"
              >Learn More</a
            >
            <mat-form-field class="w-full">
              <mat-label>Public User Association </mat-label>
              <mat-select formControlName="publicLandingPageEnabled" panelClass="mat-select-mt3">
                <mat-option [value]="true"> On </mat-option>
                <mat-option [value]="false"> Off </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="w-full sm:w-1/2" *ngIf="!isIndividual">
            <mat-form-field class="w-full">
              <mat-label>Public User Association URL </mat-label>
              <input
                id="publicUserAssociationURL"
                class="flex-wrap"
                type="text"
                matInput
                formControlName="publicUserAssociationURL"
                readonly="true"
              />
              <mat-icon  *ngIf="landingPageEnabled"  matSuffix class="primary-link" (click)="clipBoard()"
                >assignment</mat-icon>
            </mat-form-field>
          </div>
        </div>
        <div class="flex flex-wrap justify-between mb-3">
          <div class="w-full sm:w-1/2 pr-0 sm:pr-5">
            <mat-label>Status</mat-label>
            <div>
              <mat-label class="font-semibold mr-24 ">{{ entityStatus }}</mat-label>
              <button  *ngxPermissionsOnly="['orgs-info:edit']" class="primary-link cursor-pointer text-[14px]" (click)="updateEntityStatus()"
              >{{ entityStatus === 'Archived' ? 'Reactivate' : isOrganization ? 'Archive Org' : 'Archive Coop'}}</button>
            </div>
          </div>
          <div class="w-full sm:w-1/2">
            <mat-label >{{isOrganization ? 'Entity' : 'Coop'}} Admins</mat-label>
            <div class="primary-link text-[16px] cursor-pointer" (click)="goToUserAssociation(organizationName)">
              {{ entityAdminCount === 1 ? entityAdminCount + ' user' : entityAdminCount + ' Users' }}
            </div>
            <div *ngIf="entityAdminEmails">
              <ol>
                <li *ngFor="let email of entityAdminEmails">{{ email }}</li>
              </ol>
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="flex-col mr-16">
              <mat-label>Created</mat-label>
              <p class="pt-4">{{ createDate | date : 'MM/dd/yyyy' }}</p>
            </div>
            <div class="flex-col">
              <mat-label>Trial End Date</mat-label>
              <p class="pt-4">{{ trialEnded | date : 'MM/dd/yyyy' }}</p>
            </div>
          </div>            
        </div>
      </mat-card-content>
    </mat-card>
    <div class="ml-4">
      <button *ngxPermissionsOnly="['orgs-info:edit']" type="submit" class="btn-primary large" [disabled]="loader" (click)="update()">
        Update {{isOrganization? 'Organization': 'Cooperative'}}
      </button>
    </div>
  </form>
</ng-template>
<ng-template #step2>
  <mrt-grid-template [gridOptions]="subscriptionGridOptions" (actionEvent)="subscriptionEvent($event)"></mrt-grid-template>
</ng-template>
<ng-template #step3>
  <mrt-grid-template [gridOptions]="appointCertsGridOptions" (actionEvent)="appointCertsActionEvent($event)"></mrt-grid-template>
</ng-template>
<ng-template #step4>
  <mrt-grid-template [gridOptions]="enterpriseGridOptions" (actionEvent)="enterpriseActionEvent($event)"></mrt-grid-template>
</ng-template>
<ng-template #step5>
  <div *ngIf="onDataLoad" class="content-container no-data-overlay-parent">
    <div *ngIf="showZeroOverlay" class="no-data-overlay-child">
      <a class="btn-light large cursor-pointer items-center text-center" (click)="openSelectTrainingModal()"
        >Add Training</a
      >
    </div>
    <mrt-grid-template [gridOptions]="gridOptions" (actionEvent)="actionEvent($event)"></mrt-grid-template>
  </div>
</ng-template>