
<div class="bg-white sm:bg-inherit flex items-center justify-center sm:px-4 sm:py-5">
    <div class="p-8 sm:py-8 sm:px-20 bg-white sm:card-shadow sm:w-11/12 md:w-[75%] lg:w-[56%] xl:w-[45%] w-full">
      <div class="text-center mb-5">
        <h2>Forgot Password</h2>
      </div>
      <div class="forgot-psw-max-w">
        <p class="text-center font-normal mb-5">
          Enter the email address for your account and we'll send instructions to reset your password
        </p>
        <form [formGroup]="forgotForm">
          <div class="w-full">
            <app-form-field label="Email Address" type="email" formControlName="email"> </app-form-field>
          </div>
          <div class="w-full pt-5 flex items-center justify-center">
            <button type="submit" class="btn-primary large" [disabled]="disableFlag" (click)="onSubmit()">
              Reset Password
            </button>
          </div>
        </form>
        <div class="w-full flex py-5 justify-center">
          <a routerLink="/authentication/login" class="primary-link">Return to Log In</a>
        </div>
      </div>
    </div>
  </div>
  

  