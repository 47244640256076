import {components} from "../../../interfaces/swagger-types";
export type UserAssociation =  components['schemas']['UserAssocGridViewModel'];

export interface UserAssociations {
  userAssociationsSignal: UserAssociation | null;
  loadingSignal: boolean;
}

export const initialState: UserAssociations = {
  userAssociationsSignal: null,
  loadingSignal: false,
};