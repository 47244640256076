import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from '../../components/layouts/snackbar/snackbar.component';
import { ApiService } from 'src/app/api/services/api.service';
import { catchError, of, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-user-reset-password',
    templateUrl: './user-reset-password.component.html',
    styleUrls: ['./user-reset-password.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf]
})
export class UserResetPasswordComponent implements OnInit{
  public form: UntypedFormGroup = Object.create(null);
  disableFlag:boolean = false;

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<UserResetPasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private snackBar: MatSnackBar,
    private _api: ApiService,
  ) { }

  ngOnInit(): void { 
    this.form = this.fb.group({
      email: [{value:null , disabled : true}, Validators.compose([Validators.required, Validators.email])],
    });    
    this.form.patchValue(this.data);
  }

  onSubmit() {


    this.disableFlag = false;
    
    const rawValue = this.form.getRawValue();
    if(rawValue.email !== this.data.email ){
      this.form.markAllAsTouched()
      return ;
    } 
    this.disableFlag = true;
    const validationErrorHandler = (validationErrors: ValidationErrors | any) => {
      if (validationErrors.length) {
        validationErrors.forEach((error: { field: any; message: any; }) => {
          const control = this.form.get(error.field);
          if (control) {
            this.form.markAllAsTouched();
            control.setErrors({
              message: error.message,
            });
          } else {
           this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${error.message}` }})
          }
        });
      } else if (validationErrors.error) {
        const control = this.form.get('email');
        if (control) {
          this.form.markAllAsTouched();
          control.setErrors({
            message: validationErrors?.error.user[0],
          });
        }
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: `${validationErrors?.error.user[0]}` }})
      } else {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: "Error occured" }})
      }

      return of(null);
    };
    
    

    const resetPasswordUrl = environment.domainName + '/reset';
    const app = 'LMS';

    const formValues ={
      email:rawValue?.email,
      resetPasswordUrl:resetPasswordUrl,
      reCaptchaToken: '6LdUltwpAAAAAG7ji4a7JFk-6OfqghSt3fM00mDm',
      app: app
    }

    this._api.auth
      .forgotPassword(formValues)
      .pipe(catchError((error) => throwError(error)))
      .subscribe(
        () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: `Email Sent Successfully` }})
          this.close();
          this.disableFlag = false;
        },
        (error) => {
          this.disableFlag = false;
          validationErrorHandler(error);
        }
      );
  }

  close() {
    this.dialogRef.close();
  }
}
