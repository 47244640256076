import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { NgIf } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';


export interface DialogData {
  buttonAction: 'add-training' | 'archive-training';
  course: any;
  entityGuid: string;
}

@Component({
    selector: 'app-entity-course-modal',
    templateUrl: './entity-course-modal.component.html',
    styleUrls: ['./entity-course-modal.component.scss'],
    standalone: true,
    imports: [
      MatIconModule,
      NgIf,
      NgxPermissionsModule
    ]
})

export class EntityCourseModalComponent implements OnInit {
  disableFlag = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private dialogRef: MatDialogRef<EntityCourseModalComponent>,
    private snackBar: MatSnackBar,

    public courseApiService: CourseApiService,
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.dialogRef.close();
  }

  submitButton() {
    this.disableFlag = true;
    this.courseApiService
      .toggleActiveAndInactive(this.data?.course.id, this.data?.entityGuid)
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Training Status Changed' }})
          this.disableFlag = false;
          this.dialogRef.close(true);
        },
        error: (e: any) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Error Occured While Updating' }})
          this.disableFlag = false;
          this.closeModal();
        }
    });
  }
}
