import { Component, EventEmitter, ChangeDetectorRef, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DomSanitizer } from '@angular/platform-browser';
import { throwError } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { AllCoursesService } from 'src/app/api/services/courses/all/all-courses.service';
import { MatIconModule } from '@angular/material/icon';
import { NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-choose-course',
    templateUrl: './choose-course.component.html',
    styleUrls: ['./choose-course.component.scss'],
    standalone: true,
    imports: [
        NgClass,
        NgIf,
        MatIconModule,
    ],
})
export class ChooseCourseComponent implements OnInit {
  courseSelected!: boolean;
  private _item: any;
  alreadyAdded: any;
  isEntityAdmin: any;
  isCoopAdmin: any;
  @Input() bottomBtn?: string;

  @Input() set item(item: any) {
    this._item = item;
  }

  @Output() selectCourse: EventEmitter<any> = new EventEmitter();

  get item() {
    return this._item;
  }

  constructor(
    private api: ApiService,
    private domSanitizer: DomSanitizer,
    private snackBar: MatSnackBar,
    private allCoursesService: AllCoursesService
  ) {}

  ngOnInit(): void {
    this.isEntityAdmin = localStorage.getItem('roleType') === 'EntityAdmin';
    this.isCoopAdmin = localStorage.getItem('roleType') === 'CoopAdmin';
  }

  selectDiv(item: any) {
    this.allCoursesService.updateCoursesActive(item.id);
  }

  icon(svg: any) {
    if (!svg) {
      return null;
    }
    return this.domSanitizer.bypassSecurityTrustHtml(svg);
  }

  svgIcon(icon: any) {
    return `/assets/images/individual-courses/${icon}.svg#Layer_1`;
  }

  getTransformedModuleNames(): string[] {
    return this.item.moduleNames.map((moduleName: any) => 
      moduleName.includes('Exam') ? 'Exam' : moduleName
    );
  }
}
