<div class="p-4">
  <div class="flex justify-center flex-col">
    <div class="flex justify-end close-modal-button-pos">
      <button type="button"  class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="admin-modal-body">
      <div class="px-12 py-8">
        <div class="text-center px-5 py-3">
            <h2 class="font-bold mb-5">{{ data.items.title }}</h2>
        </div>
        <div class="pb-6 text-center flex">
          <p class="font-medium text-[14px]">
            {{ data.items.body }}
          </p>
        </div>
        <div class="pt-8 mb-4 flex justify-center">
          <button
            class="btn-primary medium"
            type="button"
            (click)="confirm()"
          >
          {{ data.items.buttonText }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
