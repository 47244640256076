import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { AllUser } from './all-users.model';
import { AllUsersStore } from './all-users.store';

@Injectable({ providedIn: 'root' })
export class AllUsersService {
  allUsersStore = inject(AllUsersStore);
  constructor(private api: ApiService) {}

  getAll(params: any) {
    this.allUsersStore.setLoading(true);
    return this.api.usersService.getAll(params).pipe(
      tap((entities: AllUser) => {
        this.allUsersStore.setAll(entities);
        this.allUsersStore.setLoading(false);
      })
    );
  }

  export(params: any) {
    return this.api.usersService.exportCSV(params);
  }

  deleteUserState(id: any) {
    return this.api.usersService.usersDeleteUserState(id);
  }
}
