<div class="course-version-card">
  <div class="course-version-card__header">
    <div class="course-version-card__header__version">
      <h3>{{ courseVersion?.courseVersionMajor }}.{{ courseVersion?.courseVersionMinor }}</h3>
    </div>
    <div class="course-version-card__header__tags">
      <h4
        class="language"
        [ngClass]="courseVersion?.languageId == 1 ? 'english' : courseVersion?.languageId == 2 ? 'spanish' : ''"
      >
        {{ courseVersion?.languageId == 1 ? 'English' : courseVersion?.languageId == 2 ? 'Spanish' : '' }}
      </h4>
      <h4 class="status" [ngClass]="courseVersion?.published ? 'published' : 'unpublished'">
        {{ courseVersion?.published ? 'Published' : 'Unpublished' }}
      </h4>
      <h4 *ngIf="courseVersion?.retired" class="retired" title="{{courseVersion?.retiredDate | date: 'M/d/yy'}}">
        Retired
      </h4>
    </div>
  </div>
  <div class="course-version-card__content">
    <div class="course-version-card__content__modules">
      <div
        class="course-version-card__content__modules__item"
        *ngFor="let module of courseVersion.modules; let i = index"
      >
        <h2>{{ i + 1 }}</h2>
        <h3>{{ module.scormShortName }}</h3>
        <h5>{{ module.scormType }}</h5>
      </div>
    </div>
  </div>
</div>
