import { Component, inject, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { catchError, throwError } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { UsersApiService } from 'src/app/api/services/users/users-api.service';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';


@Component({
    selector: 'app-org-admin',
    templateUrl: './org-admin.component.html',
    styleUrls: ['./org-admin.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent]
})
export class OrgAdminComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  paramsReq: any;
  defaultSortColumn: string = 'orgCreateDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: 0 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      { name: 'Opt Outs', formControlName: 'optOut', type: 'dropdown', lookup: this.lookupsStore?.userOptOutNotificationTypesSignal },
      { name: 'Purchases', formControlName: 'plan',type: 'dropdown', lookup: this.lookupsStore?.orgPurchaseTypesSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Create Date', controlName: 'orgCreateDate', displayPipe: 'date' },
      { header: 'Org Name', controlName: 'orgName' },
      { header: 'First', controlName: 'firstName' },
      { header: 'Last', controlName: 'lastName' },
      { header: 'Email', controlName: 'email', displayPipe: 'truncate' },
      { header: 'Opt Out', controlName: 'notificationOptOuts' },
      { header: 'Purchases', controlName: 'plan' },
      { header: 'Users', controlName: 'numberOfActiveUsers' },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: true,
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };
  constructor(
    private usersService: UsersApiService,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.lookupsService.getOrgPurchaseTypes();
    this.lookupsService.getUserOptOutNotificationTypes();
    this.gridOptions.fetchData = true;
  }


  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true
    if (rawParams) {
      const {startDate, endDate, search, orgCreateDate, orgName, firstName, lastName, email, optOut, plan, numberOfActiveUsers, sortOrder, pageSize, pageNumber } = rawParams;

      const newSortOrder = this.sortField(sortOrder);
      const params = {
        PageSize: pageSize ?? 20,
        PageNumber: pageNumber,
        SearchTerm: search?.length! > 0 ? search : '',
        FromDate: startDate,
        ToDate: endDate,
        createDate: orgCreateDate ? orgCreateDate : '',
        OrgName: orgName ? orgName : '',
        FirstName: firstName ? firstName : '',
        LastName: lastName ? lastName : '',
        Email: email ? email : '',
        OptOuts: optOut ? optOut : '',
        Plan: plan ? plan : '',
        NumberOfUsers: numberOfActiveUsers ? numberOfActiveUsers : '',
        SortField: newSortOrder
          ? newSortOrder.active && newSortOrder.direction
            ? newSortOrder.active
            : this.defaultSortColumn
          : this.defaultSortColumn,
        SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      };

      this.paramsReq = params;
      this.usersService.getOrgAdminAnalyst(params).subscribe((res: any) => {
        this.gridOptions.data.data = res.rows;
        this.gridOptions.totalNumberOfItems = res.totalNumberOfItems;
        this.gridOptions.isLoading = false;
    });
    }
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'orgCreateDate':
          activeSort = 'orgCreateDate';
          break;
        case sort.active === 'orgName':
          activeSort = 'orgName';
          break;
        case sort.active === 'firstName':
          activeSort = 'firstName';
          break;
        case sort.active === 'lastName':
          activeSort = 'lastName';
          break;
        case sort.active === 'email':
          activeSort = 'email';
          break;
        case sort.active === 'optOut':
          activeSort = 'optOut';
          break;
        case sort.active === 'plan':
          activeSort = 'plan';
          break;
        case sort.active === 'numberOfActiveUsers':
          activeSort = 'numberOfActiveUsers';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }


  exportCsv() {
    const paramsReq = {
      SearchTerm: this.paramsReq?.SearchTerm ? this.paramsReq?.SearchTerm : '',
      FromDate: this.paramsReq?.startDate ? this.paramsReq?.startDate : '',
      ToDate: this.paramsReq?.endDate ? this.paramsReq?.endDate : '',

      OrgName: this.paramsReq?.orgName ? this.paramsReq?.orgName : '',
      FirstName: this.paramsReq?.firstName ? this.paramsReq?.firstName : '',
      LastName: this.paramsReq?.lastName ? this.paramsReq?.lastName : '',
      Email: this.paramsReq?.email ? this.paramsReq?.email : '',
      optOut: this.paramsReq?.optOut ? this.paramsReq?.optOut : '',
      Plan: this.paramsReq?.plan ? this.paramsReq?.plan : '',
      NumberOfUsers: this.paramsReq?.numberOfActiveUsers ? this.paramsReq?.numberOfActiveUsers : ''
    };
    this.usersService
      .orgAdminAnalystExportCSV(paramsReq)
      .pipe(catchError((e) => throwError(() => e)))
      .subscribe({
        next: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Success, message: 'Report will be sent to your email' },
          });
        },
        error: () => {
          this.snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' },
          });
        },
      });
  }


  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      this.exportCsv();
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      // add button
    }
    // 'edit' is specific to this component
    else if (event.action === 'edit') {
      // go to edit function
    }
  }
}




