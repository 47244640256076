import {components} from "../../../../api/interfaces/swagger-types";

export type CooperativeAll = {
  coopAllSignal: components['schemas']['AdminCoopGridViewModel'] | null;
  loadingSignal: boolean;
}

export const initialState: CooperativeAll = {
  coopAllSignal: null,
  loadingSignal: false
};