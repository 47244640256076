import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormArray, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf, NgClass } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  selector: 'app-add-course-version',
  templateUrl: './add-course-version.component.html',
  styleUrls: ['./add-course-version.component.scss'],
  standalone: true,
  imports: [
    MatIconModule, 
    FormsModule, 
    ReactiveFormsModule, 
    MatFormFieldModule, 
    MatSelectModule, 
    NgFor, 
    MatOptionModule, 
    MatInputModule, 
    NgIf, 
    NgClass, 
    MatSlideToggleModule,
    NgxPermissionsModule
  ]
})

export class AddCourseVersionComponent implements OnInit{
  readonly lookupsStore = inject(LookupsStore);
  disableFlag = true;
  initialSnapshot!: any;
  loaded = false;
  errors!: { field: string; message: string }[];
  enableForm = true;
  courseVersionId: number = 0;

  form = this.fb.group({
    course: this.fb.group({
      trainingId: this.fb.control(0, Validators.required),
      learningPathName: this.fb.control(null, Validators.required),
      courseName: this.fb.control(null, Validators.required),
      courseStates: this.fb.control(null, Validators.required),
    }),
    courseVersion: this.fb.group({
      courseVersionMajor: this.fb.control(null, Validators.required),
      courseVersionMinor: this.fb.control(0),
      languageId: this.fb.control(null, Validators.required),
      published: this.fb.control(false, Validators.required),
      modules: this.fb.array([]),
    }),
  });

  get isNewCourse() {
    return this.form.get('selectedCourseId')?.value === 0;
  }

  get modulesArray() {
    return this.form.get('courseVersion')?.get('modules') as UntypedFormArray;
  }

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddCourseVersionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: 'Add' | 'Edit'; item?: any; index?: number},
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.initialSnapshot = this.form.getRawValue();
    this.lookupsService.getTrainings();
    this.lookupsService.getStates();
    this.lookupsService.getScormTypes();
    this.lookupsService.getModules();

    this.modulesArray.clear();
    const course = this.data.item;
    this.form.get('course')?.patchValue(course);
    this.form.get('course')?.disable();
    if (this.data.action === 'Edit' && this.data.index !== undefined) {
      const courseVersion = this.data.item.courseVersions[this.data.index];
      this.form.get('courseVersion')?.patchValue(courseVersion);
      this.courseVersionId = courseVersion.courseVersionId;
      courseVersion.modules.forEach((module: any) => {
        //@ts-ignore
        this.form.get('courseVersion')?.get('modules')?.push(this.fb.group(module));
      });
      if (courseVersion.published) {
        this.form.get('courseVersion')?.disable();
        this.enableForm = false;
      }
    }
    this.initialSnapshot = this.form.getRawValue();
    this.loaded = true;

    this.form.get('courseVersion')?.valueChanges.subscribe((currentState: any) => {
      this.disableFlag = false;
      if (this.loaded && !this.isNewCourse) {
        Object.keys(currentState).forEach((key) => {
          if (this.form.get(key)?.dirty) {
            if (this.initialSnapshot[key] !== currentState[key]) {
              const el = document.querySelector(`[formcontrolname="${key}"]`) as HTMLElement;
              el.classList.add('changed');
            } else {
              const el = document.querySelector(`[formcontrolname="${key}"]`) as HTMLElement;
              el.classList.remove('changed');
            }
          }
        });
      }
    });
  }

  reset() {
    this.modulesArray.clear();
    var changedElements = document.querySelectorAll('.changed');
    changedElements.forEach((el) => {
      el.classList.remove('changed');
    });
  }

  closeModal() {
    this.dialogRef.close();
  }

  addModule() {
    const newModuleForm = this.fb.group({
      isInList: this.fb.control(false, Validators.required),
      moduleId: this.fb.control(null),
      moduleName: this.fb.control(null, Validators.required),
      scormId: this.fb.control(null),
      scormFullName: this.fb.control(null, Validators.required),
      scormShortName: this.fb.control(null, Validators.required),
      scormDescription: this.fb.control(null, Validators.required),
      scormTypeId: this.fb.control(null, Validators.required),
      scormType: this.fb.control(null, Validators.required),
      scormDuration: this.fb.control(null, Validators.required),
    });

    this.modulesArray.push(newModuleForm);
  }

  onModuleSelect(event: any, index: number) {
    if (event.value) {
      this.coursesService.getModuleDetails(event.value).subscribe((res: any) => {
        this.modulesArray.at(index).patchValue({ isInList: true, ...res });
      });
    }
  }

  deleteModule(index: number) {
    this.modulesArray.removeAt(index);
  }

  onSubmit() {
    this.errors = [];
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }

    this.disableFlag = true;
    
    const formValue = {
      trainingId: this.form.get('course')?.get('trainingId')?.value,
      courseId: this.data.item.courseId,
      courseVersionId: this.courseVersionId,
      ...this.form.get('courseVersion')?.getRawValue()
    };
    this.coursesService.postAddCourseVersion(formValue).subscribe({
      next: (res: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Course version added successfully' }})
        this.dialogRef.close('success');
      },
      error: (err: any) => {
        console.log(err);
        
        this.disableFlag = false;
        if (err.status === 400) {
          this.errors = err;
        } else if (err.status === 500) {
          this.errors = [{ field: 'Server Error', message: err.error.split('\n')[0] }]
        } else {
          this.errors = [{ field: 'general', message: 'Something went wrong' }]
        }
      },
    });
  }
}
