import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AllUsersService } from 'src/app/api/services/users/all-users.service';
import { MatIconModule } from '@angular/material/icon';
import { catchError, throwError } from 'rxjs';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { NgxPermissionsModule } from 'ngx-permissions';

@Component({
  imports: [
    MatIconModule,
    NgxPermissionsModule
  ],
  standalone: true,
  selector: 'app-clearcache-verification',
  templateUrl: './users-clearcache.component.html',
  styleUrls: ['./users-clearcache.component.scss'],
})
export class ClearCacheComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ClearCacheComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private allUsersService: AllUsersService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
  }
  close() {
    this.dialogRef.close();
  }

  submitButton(button: any) {
    const id = parseInt(this.data?.items.userprofileId)
  
    this.allUsersService
    .deleteUserState(id)
    .pipe(catchError((e) => throwError(() => e)))
    .subscribe({
      next: () => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'User Cache Cleared' }})
        this.close();
      },
      error: (e) => {
        console.log(e);
      },
    });
    return;
  }     
  
}
