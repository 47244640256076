import { Component, OnInit, Input  } from '@angular/core';
import { interval, mergeMap } from 'rxjs';
import { AppversionService } from 'src/app/api/services/appversion/appversion.service';
import { environment } from 'src/environments/environment';
import { NgIf, NgClass } from '@angular/common';

@Component({
    selector: 'app-banner',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss'],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class BannerComponent implements OnInit {
  context!: string;
  inProd!: boolean;
  showRefreshBanner = false;
  @Input() useReportingDB: boolean = false;

  constructor(private versionService: AppversionService) { }

  ngOnInit() {
    if (environment.context !== 'prod') {
      this.context = environment.context;
      this.inProd = false;
    } else {
      this.inProd = true; 
    }

    this.versionService.checkForUpdate().subscribe({
      next: (value: any) => {
        const currentVersion = environment.versionNumber.split('|')[0];
        const releaseDate = environment.versionNumber.split('|')[1];
        if (currentVersion !== value.versionNumber && value.createdDate && releaseDate && Date.parse(value.createdDate) > Date.parse(releaseDate)) {
          this.showRefreshBanner = true;
        }
      },
    });

    interval(environment.versionPollTime * 60 * 1000)
      .pipe(mergeMap(() => this.versionService.checkForUpdate()))
      .subscribe({
        next: (value: any) => {
          const currentVersion = environment.versionNumber.split('|')[0];
          const releaseDate = environment.versionNumber.split('|')[1];
          if (currentVersion !== value.versionNumber && value.createdDate && releaseDate && Date.parse(value.createdDate) > Date.parse(releaseDate)) {
            this.showRefreshBanner = true;
          }
        },
      });
  }


  refreshApp(){
    window.location.reload();
  }

}
