import { Injectable } from '@angular/core';
import { AuthApiService } from './auth/auth-api.service';
import { LookupsService } from './lookups/lookups.service';
import { AllService } from './organizations/all/all.service';
import { UserAssociationsApiService } from './organizations/user-associations/user-associations-api.service';
import { UserInvitesApiService } from './organizations/user-invites/user-invites-api.service';
import { LearnerMetricsApiService } from './reports/learner-metrics/learner-metrics-api.service';
import { UsersApiService } from './users/users-api.service';
import { CourseApiService } from './courses/course-api.service';
import { CoursesService } from './courses/courses.service';
import { CertificatesApiService } from './reports/certificates/certificates-api.service';
import { CooperativeAllService } from './cooperations/coop-all/cooperative-all.service';
import { DashboardService } from './reports/dashboard/dashboard.service';

@Injectable({ providedIn: 'root' })
export class ApiService {
  constructor(
    public auth: AuthApiService,
    public orgAll: AllService,
    public lookups: LookupsService,
    public orgAssociations: UserAssociationsApiService,
    public orgUserInvites: UserInvitesApiService,
    public usersService: UsersApiService,
    public learnerMetricsService: LearnerMetricsApiService,
    public courseApiService: CourseApiService,
    public coursesService: CoursesService,
    public certificatesService: CertificatesApiService,
    public coopAll: CooperativeAllService,
    public dashboardService: DashboardService
  ) {}
}
