import { Component, OnInit, AfterViewInit, OnDestroy, Inject, effect, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Subject, Subscription } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { AllCoursesService } from 'src/app/api/services/courses/all/all-courses.service';
import { AllCoursesStore } from 'src/app/api/services/courses/all/all-courses.store';
import { CourseApiService } from 'src/app/api/services/courses/course-api.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { ChooseCourseComponent } from '../../components/choose-course/choose-course.component';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-select-training',
    templateUrl: './select-training.component.html',
    styleUrls: ['./select-training.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatSelectModule, NgFor, MatOptionModule, NgIf, ChooseCourseComponent]
})
export class SelectTrainingComponent implements OnInit, AfterViewInit, OnDestroy{
  readonly lookupsStore = inject(LookupsStore);
  readonly allCoursesStore = inject(AllCoursesStore);
  trainingFilterForm = this.fb.group({
    training: this.fb.control(null),
    state: this.fb.control(null),
  });
  public selectCourseForm: UntypedFormGroup = Object.create(null);
  myTrainingCourses: any[] = [];
  availableCourses: any[] = [];
  availableCoursesFiltered: any[] = [];
  disableAddTrainingBtn: boolean = true;
  addTrainingLoading: boolean = false;
  trainingCoursesFound: boolean = false;
  coursesToAdd: any[] = [];
  destroy$: Subject<boolean> = new Subject<boolean>();
  courseDataSubscription!: Subscription;

  constructor(
    private fb: FormBuilder,
    public sanitizer: DomSanitizer,
    private dialogRef: MatDialogRef<SelectTrainingComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public allCoursesService: AllCoursesService,
    public courseApiService: CourseApiService,
    public lookupsService: LookupsService,
    private snackBar: MatSnackBar,
  ) {
    // trainings filter and listener for changes
    effect(() => {
      if (this.lookupsStore.trainingsSignal().length > 0) {
        var defaultTrainings = this.lookupsStore.trainingsSignal().filter((training) => training.name === 'Child Abuse');
        if (defaultTrainings.length > 0) {
          this.trainingFilterForm.get('training')?.setValue(defaultTrainings[0]?.value);
        }
      }
    });
    effect(() => {
      const courses = this.allCoursesStore.coursesSignal();
      if (courses?.length === 0) {
        return;
      }

      if (courses?.length > 0) {
        this.trainingCoursesFound = true;
      }
      courses.map((course: any) => {

  
          if (this.myTrainingCourses.length === 0) {
            this.myTrainingCourses = courses
              .map((course: any) => {
                if (course.selected) {
                  return course;
                }
              })
              .filter(Boolean);
          }
  
          this.availableCourses = courses;
  
          this.coursesToAdd = courses
            .map((course: any) => {
              if (course.courseSelected) {
                return course;
              }
            })
            .filter(Boolean);
  
          return courses.map((course: any) => course.courseSelected);
        }),
        tap((courses: any) => {
          this.filterAvailableCourses(this.trainingFilterForm.getRawValue());
          if (courses?.includes(true)) {
            this.disableAddTrainingBtn = false;
          } else {
            this.disableAddTrainingBtn = true;
          }
        });
    });
  }

  ngOnInit(): void {

    this.trainingFilterForm.valueChanges.subscribe((form) => {
      this.filterAvailableCourses(form);
    });

    // trainings tap data
    this.trainingFilterForm.get('state')?.setValue(this.data.stateId);
    this.fetchAllCourses();
  }

  ngAfterViewInit(): void {
    this.trainingFilterForm.get('training')?.valueChanges.subscribe((val) => {
      if (val !== null) {
        //@ts-ignore
        this.availableCoursesFiltered = this.availableCourses.filter((course) => course.name.toLowerCase().includes(val.toLowerCase()));
      } else {
        this.availableCoursesFiltered = this.availableCourses;
      }
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
    if (this.courseDataSubscription) {
      this.courseDataSubscription.unsubscribe();
    }
  }


  fetchAllCourses() {
    this.courseDataSubscription = this.allCoursesService.reloadCourses(this.data.entityGuid).subscribe();
  }

  // training tab filter available courses
  filterAvailableCourses(form: any) {
    this.availableCoursesFiltered = this.availableCourses.filter(
      (course: any) =>
        (form.training ? course?.trainingId == form.training : true) &&
        Object.keys(course.states!).some((s) => (form.state ? s == form.state : true))
    );

    if (this.availableCoursesFiltered?.length > 0) {
      this.trainingCoursesFound = true;
    } else {
      this.trainingCoursesFound = false;
    }
    
  }

  // training tab add training
  addCoursesOrgAdmin(){
    this.addTrainingLoading = true;
    const addCourseCalls: number[] = this.coursesToAdd
    .filter((c) => c)
    .map((course) => {
      return course.id;
    });
    this.courseApiService
      .addOrgCourses(addCourseCalls, this.data.entityGuid)
      .pipe(
        tap(() => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Training added successfully' }})
          this.dialogRef.close(true);
        }),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        if (this.courseDataSubscription) {
          this.myTrainingCourses = [];
          this.courseDataSubscription.unsubscribe();
        }
        this.addTrainingLoading = false;
      });
  }

  closeModal() {
    this.dialogRef.close();
  }
}
