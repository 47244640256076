<div class="p-4">
  <div class="flex flex-col h-full">
    <div class="text-right justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="admin-modal-body">
      <div class="px-12 py-8">
          <div class="text-center px-5 py-3">
              <h2 class="font-bold mb-5"> {{ data.action + ' Course' }}</h2>
          </div>
          <div class="w-full overflow-y-auto">
            <form [formGroup]="form">
              <div class="flex flex-wrap mt-5">
                <!-- course -->
                <ng-container formGroupName="course">
                  <div class="w-full">
                    <h2 class="mb-0 pl-2 learner">Training</h2>
                  </div>
                  <mat-form-field class="w-full p-2">
                    <mat-label>Training</mat-label>
                    <mat-select formControlName="trainingId" panelClass="mat-select-mt3">
                      <mat-option *ngFor="let training of lookupsStore.trainingsSignal()" [value]="training.value"
                        >{{ training.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="courseControl.controls['trainingId'].hasError('required') && courseControl.controls['trainingId'].touched"
                    >Required
                    </mat-error>
                  </mat-form-field>
                  <div class="w-full">
                    <h2 class="mb-0 pl-2 learner">Learning Path</h2>
                  </div>
                  <div class="w-1/2">
                    <mat-form-field class="w-full p-2">
                      <input placeholder="Learning Path Name" matInput formControlName="learningPathName" />
                      <mat-error *ngIf="courseControl.controls['learningPathName'].hasError('required') && courseControl.controls['learningPathName'].touched"
                      >Required
                      </mat-error>
                      <mat-error *ngIf="courseControl.controls['learningPathName'].hasError('maxlength') && courseControl.controls['learningPathName'].touched"
                      >Max 100 Characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-1/4">
                    <mat-form-field class="w-full p-2">
                      <input
                        placeholder="Cert Expiration Months"
                        matInput
                        type="number"
                        formControlName="learningPathCertExpiry"
                      />
                    </mat-form-field>
                  </div>
                  <div class="w-full flex">
                    <mat-form-field class="w-full p-2">
                      <mat-label>Badge Name</mat-label>
                      <mat-select formControlName="badgeName" panelClass="mat-select-mt3" placeholder="Badge Name">
                        <mat-option *ngFor="let file of lookupsStore.badgeFilesSignal()" [value]="file.value"
                          >{{ file.value }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="courseControl.controls['badgeName'].hasError('required') && courseControl.controls['badgeName'].touched"
                      >Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full flex">
                    <div class="w-1/2">
                      <mat-form-field class="w-full p-2">
                        <mat-label>Certificate Template</mat-label>
                        <mat-select formControlName="learningPathTemplate" panelClass="mat-select-mt3" placeholder="Select from existing">
                          <mat-option *ngFor="let file of lookupsStore.certFilesSignal()" [value]="file.value"
                            >{{ file.value }}
                          </mat-option>
                        </mat-select>
                        <mat-error *ngIf="courseControl.controls['learningPathTemplate'].hasError('required') && courseControl.controls['learningPathTemplate'].touched"
                        >Required
                        </mat-error>
                      </mat-form-field>
                    </div>
                    <div class="w-1/2 ml-3">
                      <h5>Upload New Template</h5>
                      <input #certFile type="file" />
                      <button *ngIf="selectedCertFile" type="button" class="btn-link-alt opacity-100" (click)="clearFileInput()">
                        <mat-icon> close</mat-icon>
                      </button>
                    </div>
                  </div>
                  <div class="w-full flex ml-2">
                    <mat-form-field class="w-full sm:w-1/2">
                      <input class="p-2" placeholder="Authority" matInput formControlName="authority" />
                      <mat-error *ngIf="courseControl.controls['authority'].hasError('maxlength') && courseControl.controls['authority'].touched"
                      >Max 30 Characters
                      </mat-error>
                    </mat-form-field>
                    <div class="w-full sm:w-1/2 flex items-center">
                      <div class="w-full sm:w-1/3">
                        <mat-checkbox class="p-2" formControlName="isCE">Continuing Education?</mat-checkbox>
                      </div>
                      <div class="w-full sm:w-1/3">
                        <mat-checkbox class="p-2" formControlName="isOrganizationOnly">Org Only?</mat-checkbox>
                      </div>
                      <div class="w-full sm:w-1/3">
                        <mat-checkbox class="p-2" formControlName="requiresSubscription">Subscription Only?</mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="w-full flex ml-2">
                    <mat-form-field class="w-full sm:w-5/6">
                      <input class="p-2" placeholder="Partnership Info" matInput formControlName="partnershipInfo" />
                      <mat-error *ngIf="courseControl.controls['partnershipInfo'].hasError('maxlength') && courseControl.controls['partnershipInfo'].touched"
                      >Max 100 Characters
                      </mat-error>
                    </mat-form-field>
                    <div class="w-full sm:w-1/6 flex items-center">
                      <div class="w-full">
                        <mat-checkbox class="p-2" formControlName="IsPartnership">In Partnership?</mat-checkbox>
                      </div>
                    </div>
                  </div>
                  <mat-form-field class="w-full  p-2">
                    <textarea
                      placeholder="Info Bubble"
                      matInput
                      formControlName="certInfo"
                      style="height: 90px"
                    ></textarea>
                    <mat-error *ngIf="courseControl.controls['certInfo'].hasError('maxlength') && courseControl.controls['certInfo'].touched"
                    >Max 1000 Characters
                    </mat-error>
                  </mat-form-field>
        
                  <div class="w-full mt-4">
                    <h2 class="mb-0 pl-2 learner">Course</h2>
                  </div>
                  <div class="w-1/2">
                    <mat-form-field class="w-full p-2">
                      <input placeholder="Course Name" matInput formControlName="courseName" />
                      <mat-error *ngIf="courseControl.controls['courseName'].hasError('required') && courseControl.controls['courseName'].touched"
                      >Required
                      </mat-error>
                      <mat-error *ngIf="courseControl.controls['courseName'].hasError('maxlength') && courseControl.controls['courseName'].touched"
                      >Max 200 Characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-1/2">
                    <mat-form-field class="w-full p-2">
                      <mat-label>States Available</mat-label>
                      <mat-select formControlName="courseStates" multiple="true" panelClass="mat-select-mt3">
                        <mat-option *ngFor="let state of lookupsStore.statesSignal()" [value]="state.value"
                          >{{ state.name }}
                        </mat-option>
                      </mat-select>
                      <mat-error *ngIf="courseControl.controls['courseStates'].hasError('required') && courseControl.controls['courseStates'].touched"
                      >Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full">
                    <mat-form-field class="w-full p-2">
                      <textarea
                        placeholder="Course Description"
                        matInput
                        formControlName="courseDescription"
                        style="height: 90px"
                      ></textarea>
                      <mat-error *ngIf="courseControl.controls['courseDescription'].hasError('required') && courseControl.controls['courseDescription'].touched"
                      >Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-1/2">
                    <mat-form-field class="w-full p-2">
                      <input placeholder="Course Sort" matInput type="number" formControlName="sort" />
                      <mat-error *ngIf="courseControl.controls['sort'].hasError('required') && courseControl.controls['sort'].touched"
                      >Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  
                  <div class="w-full mt-4">
                    <h2 class="mb-0 pl-2 learner">Brochure Content</h2>
                  </div>
                  <mat-form-field class="w-full p-2">
                    <textarea
                      placeholder="Meta Description"
                      matInput
                      formControlName="metaDescription"
                      style="height: 90px"
                    ></textarea>
                  </mat-form-field>
                  <div class="w-full">
                    <mat-form-field class="w-1/2 p-2">
                      <input placeholder="Brochure Website URL" matInput formControlName="brochureSlug" />
                      <mat-error *ngIf="courseControl.controls['brochureSlug'].hasError('maxlength') && courseControl.controls['brochureSlug'].touched"
                      >Max 50 Characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                </ng-container>
              </div>
            </form>
          </div>
          <div *ngFor="let error of errors" class="my-4">
            <p class="text-red-400">{{ error.field }}: {{ error.message }}</p>
          </div>
          <div class="mt-auto pb-3 flex justify-center flex-col items-center">
            <form [formGroup]="form">
              <ng-container formGroupName="course">
                <div class="w-full items-center">
                  <div class="flex flex-col items-center pb-8">
                    <span class="pb-2 text-primary">Course Published</span>
                    <mat-slide-toggle formControlName="published">
                    </mat-slide-toggle>
                  </div>
                </div>
              </ng-container>
            </form>
            <button *ngxPermissionsOnly="['courses:edit']" class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmit()">
              <ng-container>{{ data.action + ' Course' }}</ng-container>
            </button>
          </div>
      </div>
    </div>
  </div>

</div>