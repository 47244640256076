import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { Certificates, initialState } from './certificates.model';

export const CertificatesStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(certificatesSignal: Certificates["certificatesSignal"]) {
      patchState(store, {certificatesSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)