<div class="p-4">
  <div class="flex flex-col h-full">
    <div class="text-right justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="admin-modal-body">
      <div class="px-12 py-8">
          <div class="text-center px-5 py-3">
              <h2 class="font-bold mb-5">{{ modalTitle + ' Course' }}</h2>
          </div>
          <div class="w-full overflow-y-auto">
            <form [formGroup]="form">
              <div class="flex flex-col mt-5">
                <div class="w-full">
                  <h2 class="mb-0 pl-2 learner">Course</h2>
                </div>
                <div class="w-full flex items-center">
                  <mat-form-field class="w-1/3 p-2">
                    <mat-label>Training</mat-label>
                    <mat-select formControlName="trainingId" panelClass="mat-select-mt3">
                      <mat-option *ngFor="let training of lookupsStore.trainingsSignal()" [value]="training?.value">{{
                        training?.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="form.controls['trainingId'].hasError('required') && form.controls['trainingId'].touched">Required
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-1/3 p-2">
                    <mat-label>State</mat-label>
                    <mat-select formControlName="courseStates" multiple="true" panelClass="mat-select-mt3">
                      <mat-option *ngFor="let state of lookupsStore.statesSignal()" [value]="state.value">{{ state.name }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="form.controls['courseStates'].hasError('required') && form.controls['courseStates'].touched">Required
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field class="w-1/3 p-2">
                    <mat-label>Course</mat-label>
                    <mat-select formControlName="courseId" panelClass="mat-select-mt3">
                      <mat-option *ngFor="let course of lookupsStore.courseTrainingsSignal()" [value]="course?.value">{{
                        course?.label }}
                      </mat-option>
                    </mat-select>
                    <mat-error
                      *ngIf="form.controls['courseId'].hasError('required') && form.controls['courseId'].touched">Required
                    </mat-error>
                  </mat-form-field>
                </div>
                <div class="px-2" *ngIf="form.controls['courseId']?.value">
                  <p>CourseId: {{ form.controls['courseId']?.value }}</p>
                  <div *ngFor="let stateList of lookupsStore.courseStatesByCourseIdSignal()">
                    <p>States: {{ stateList.label }}</p>
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-5">
                <div class="w-full">
                  <h2 class="mb-0 pl-2 learner">Industry, Profession, and Job Titles</h2>
                </div>
                <div class="w-full" formArrayName="mappings">
                  <div class="w-full flex items-center" *ngFor="let item of mappings?.controls; let i = index">
                    <div class="w-full" [formGroup]="itemAsForm(i)">
                      <mat-form-field class="w-1/3 p-2">
                        <mat-label>Industry</mat-label>
                        <mat-select formControlName="industryId" panelClass="mat-select-mt3"
                          (selectionChange)="addDropdownData(i)">
                          <mat-option *ngFor="let industry of lookupsStore.industriesSignal()" [value]="industry?.value">{{
                            industry?.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field *ngIf="dropdownData[i]?.optionsHasValue$ | async" class="w-1/3 p-2">
                        <mat-label>Profession</mat-label>
                        <mat-select formControlName="professionIds" multiple="true" panelClass="mat-select-mt3">
                          <mat-option *ngFor="let profession of dropdownData[i].options$ | async" [value]="profession?.value">{{
                            profession?.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                      <mat-form-field *ngIf="dropdownData[i]?.optionsHasValue$ | async" class="w-1/3 p-2">
                        <mat-label>Job Title</mat-label>
                        <mat-select formControlName="jobTitleIds" multiple="true" panelClass="mat-select-mt3">
                          <mat-option *ngFor="let jobTitle of dropdownData[i].options$ | async" [value]="jobTitle?.value">{{
                            jobTitle?.name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                    <mat-icon class="cursor-pointer" (click)="removeFormItem(i)">delete</mat-icon>
                  </div>
                  <a class="flex primary-link cursor-pointer mt-5 pl-2" (click)="addFormItem()">
                    <mat-icon class="custom-mat-icon self-center">add</mat-icon>
                    <h5>Add Mapping</h5>
                  </a>
                </div>
              </div>
            </form>
          </div>
          <div class="mt-auto pb-3 flex justify-center flex-col items-center">
            <button *ngxPermissionsOnly="['courses:edit']" class="btn-primary large" type="submit" [disabled]="disableFlag"
              (click)="onSubmit()">
              <ng-container>Submit</ng-container>
            </button>
          </div>
      </div>
    </div>
  </div>
</div>