import {components} from "../../../../api/interfaces/swagger-types";
export type CoopUserAssociation =  components['schemas']['UserAssocGridViewModel']

export type CoopUserAssociationAll = {
  coopUserAssociationSignal: CoopUserAssociation | null;
  loadingSignal: boolean;
}

export const initialState: CoopUserAssociationAll = {
  coopUserAssociationSignal: null,
  loadingSignal: false
};