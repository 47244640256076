import { Component, computed, effect, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CoursesService } from 'src/app/api/services/courses/courses.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { MatSnackBar } from '@angular/material/snack-bar';
import { debounceTime, Subscription, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { UsersApiService } from 'src/app/api/services/users/users-api.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { NgIf, NgFor } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-add-association-modal',
    templateUrl: './add-association-modal.component.html',
    styleUrls: ['./add-association-modal.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatAutocompleteModule, NgIf, NgFor, MatOptionModule, MatSelectModule]
})
export class AddAssociationModalComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  pTagContent: string = '';
  EntityNamesData: any;
  groupData: any;
  disableFlag: boolean = false;
  formValueChangesSub!: Subscription;
  seenForAddAssociation: boolean = true;
  orgRolesComputed = computed(() => this.lookupsStore.orgRolesSignal().filter((role: any) => 
    this.data.item.entityType === 'Organization' ? role.name !== 'CoopAdmin' :
    this.data.item.entityType === 'Coop' ? role.name !== 'EntityAdmin' : role));
  form = this.fb.group({
    associatedEntity: this.fb.control(null, Validators.required),
    roleGuids: this.fb.control(null),
    statusId: this.fb.control(null),
    groupIds: this.fb.control(null),
    notes: this.fb.control(null),
  });

  constructor(
    private fb: FormBuilder,
    private dialogRef: MatDialogRef<AddAssociationModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action: 'Add' | 'Edit'; item?: any; },
    public lookupsService: LookupsService,
    private coursesService: CoursesService,
    private snackBar: MatSnackBar,
    private api: ApiService,
    public usersService: UsersApiService
  ) {}

  ngOnInit() {
    this.lookupsService.getOrgRoles();
    this.lookupsService.getEntityStatus();

    if (this.data.action === 'Edit') {
      this.seenForAddAssociation = false;
      this.pTagContent = `Manage the entity association for ${this.data.item.userName}.`;
      this.groupLookup(this.data.item.entityGuid);
      const patchData = {
        associatedEntity: { value: this.data.item.entityGuid, name: `${this.data.item.entityName} - ${this.data.item.entityContact}` },
        roleGuids: this.data.item.roleGuids,
        statusId: this.data.item.statusId,
        groupIds: this.data.item.groupIds,
      };
      //@ts-ignore
      this.form.patchValue(patchData);
      this.form.controls['associatedEntity'].disable();
    } 
    
    if (this.data.action === 'Add') {
      this.data.item.id = 0;
      this.form.controls['notes'].setValidators([Validators.required, Validators.maxLength(200)]);
      var role = this.lookupsStore.orgRolesSignal().find((role: any) => role.name === 'Learner');
      if (role) {
        //@ts-ignore
        this.form.controls['roleGuids'].setValue([role.value]);
        this.form.controls['roleGuids'].disable();
      }
      this.pTagContent = `Select an existing entity to associate with the user ${this.data.item.userName}.`;
      this.activeEntityLookup('a');

      this.formValueChangesSub = this.form.valueChanges
      .pipe(
        debounceTime(250),
        tap((val: any) => {
          const { associatedEntity } = val;
          if (associatedEntity != null) {
            if (typeof associatedEntity === 'string' && associatedEntity.length >= 3) {
              this.form.controls['associatedEntity'].setErrors({'incorrect':true});
              this.activeEntityLookup(associatedEntity);
            } else if (typeof associatedEntity === 'object') {
              this.disableFlag = false;
            }
          } else {
            this.disableFlag = true;
            this.form.controls['associatedEntity'].setErrors({'incorrect':true});
          }
        })     
      )
      .subscribe();
    }
  }

  closeModal() {
    this.dialogRef.close();
  }

  displayFn(search: any): string {
    return search && search.name ? search.name : search?.length > 0 ? search : '';
  }

  // Lookup only active entities, not archived
  activeEntityLookup(val: any) {
    if (val === '') {
      this.activeEntityLookup('a');
    } else {
      this.api.lookups
      .getActiveOrgCoop(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.EntityNamesData = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }

  groupLookup(val: any) {
    if (val === '') {
      this.groupLookup('a');
    } else {
      this.api.lookups
      .getGroup(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.groupData = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
    }
  }

  onSubmit() {
    if (!this.form.valid) {
      return;
    }
    this.disableFlag = true;
    const { associatedEntity, roleGuids, statusId, groupIds, notes } = this.form.getRawValue();
    const data = {
      id: this.data.item.id,
      //@ts-ignore
      entityGuid: associatedEntity.value,
      userProfileId: this.data.item.userProfileId,
      roleGuids: roleGuids ?? [],
      statusId: statusId,
      groupIds: groupIds ?? [],
      notes: notes,
    };
    this.usersService.upsertAssociatedEntity(data).subscribe({
      next: (response: any) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Association saved successfully' }})
        this.dialogRef.close(true);
      },
      error: (e) => {
        this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        this.disableFlag = false;
      },
    });
  }
}
