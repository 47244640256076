import { computed } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import { LookupEntry, initialState } from './lookups.model';

export const LookupsStore = signalStore(
  withState(initialState),
  withComputed(({industriesSignal}) => ({
    industriesHasValueSignal: computed(() => industriesSignal().length > 0),
  })),
  withComputed(({certFilesSignal}) => ({
    certFilesHasValueSignal: computed(() => certFilesSignal().length > 0),
  })),
  withComputed(({badgeFilesSignal}) => ({
    badgeFilesHasValueSignal: computed(() => badgeFilesSignal().length > 0),
  })),
  withMethods(({...store}) => ({
    setLookup(signalKey: keyof typeof store, lookupData: LookupEntry[]) {
      patchState(store, {[signalKey]: lookupData});

    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
);