import { Component, effect, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { tap } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { AddCooperativeComponent } from 'src/app/shared/modals/add-cooperative/add-cooperative.component';
import { GridOptions } from '@mrt/mrt-grid-template/lib/interfaces/grid-options';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { CooperativeAllStore } from 'src/app/api/services/cooperations/coop-all/cooperative-all.store';
import { CoopAllService } from 'src/app/api/services/cooperations/coop-all/coop-all.service';
import { MRTGridTemplateComponent } from '@mrt/mrt-grid-template';

@Component({
    selector: 'app-cooperative-all',
    templateUrl: './cooperative-all.component.html',
    styleUrls: ['./cooperative-all.component.scss'],
    standalone: true,
    imports: [MRTGridTemplateComponent],
})
export class CooperativeAllComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  readonly authStore = inject(AuthStore);
  readonly cooperativesStore = inject(CooperativeAllStore);
  paramsReq: any;
  defaultSortColumn: string = 'createDate';
  defaultSortDirection: string = 'DESCENDING';
  gridOptions: GridOptions = {
    filterData: [
      { name: 'From', formControlName: 'startDate', type: 'date', dateOffset: -45 },
      { name: 'To', formControlName: 'endDate', type: 'date', dateOffset: 0 },
      { name: 'Search', formControlName: 'search', type: 'search' },
      {
        name: 'Industry',
        formControlName: 'industryId',
        type: 'dropdown',
        defaultValue: undefined,
        lookup: this.lookupsStore?.industriesSignal,
      },
      { name: 'State', formControlName: 'stateId', type: 'dropdown', lookup: this.lookupsStore.statesSignal},
      { name: 'Status', formControlName: 'status', type: 'dropdown', lookup: this.lookupsStore?.entityStatusSignal },
    ],
    openFilters: true,
    columns: [
      { header: 'Create Date', controlName: 'createDate', displayPipe: 'date' },
      { header: 'Cooperative Name', controlName: 'cooperativeName' },
      { header: 'Admin', controlName: 'coopAdmin', displayPipe: 'truncate' },
      { header: 'Industry', controlName: 'coopIndustry' },
      { header: 'State', controlName: 'state' },
      { header: 'Active Learners', controlName: 'numberOfActiveUsers' },
      { header: 'Status', controlName: 'status' },
      { header: 'Edit', controlName: 'action', actionColumnitems: [{ icon: 'edit', buttonAction: 'edit' }] },
    ],
    data: new MatTableDataSource<any>(),
    totalNumberOfItems: 0,
    allowExportCSV: false,
    addButtonName: 'Add Cooperative',
    disableflag: false,
    isLoading: false,
    displaySearch: true,
  };

  constructor(
    private dialog: MatDialog,
    private lookupsService: LookupsService,
    private snackBar: MatSnackBar,
    private coopAllService: CoopAllService,
    private router: Router
  ) {
    var gridDatasource: any[] = [];
    effect(() => {
      gridDatasource = [];
      const coopAll = this.cooperativesStore.coopAllSignal() ?? {};
      if (coopAll.totalNumberOfItems != null) {
        const { admins, totalNumberOfItems } = coopAll;
        admins?.forEach((row: any) => {
          const datasource = gridDatasource;
          datasource.push(row);
          gridDatasource = datasource;
        });
        this.gridOptions.data.data = gridDatasource;
        this.gridOptions.totalNumberOfItems = totalNumberOfItems;
        this.gridOptions.isLoading = false;
      }
    });
  }

  ngOnInit(): void {
    this.lookupsService.getEntityStatus();
    this.lookupsService.getStates();
    this.lookupsService.getIndustries();
    this.gridOptions.fetchData = true;
  }

  fetchAll(rawParams?: any) {
    this.gridOptions.isLoading = true;

    var useReportingDB = this.authStore.useReportingDBSignal() ?? false;

    const { search, startDate, endDate, stateId, numberOfActiveUsers, industryId, status, orgId, sortOrder, pageSize, pageNumber } =
      rawParams;
    const newSortOrder = this.sortField(sortOrder);
    const params = {
      PageSize: pageSize ?? 20,
      PageNumber: pageNumber,
      SearchTerm: search?.length! > 0 ? search : '',
      FromDate: startDate,
      ToDate: endDate,
      StateId: stateId ? stateId : '',
      OrgId: orgId ? orgId : '',
      IndustryId: industryId ? industryId : '',
      NumberOfActiveUsers: numberOfActiveUsers,
      Status: status ? status : '',
      SortField: newSortOrder
        ? newSortOrder.active && newSortOrder.direction
          ? newSortOrder.active
          : this.defaultSortColumn
        : this.defaultSortColumn,
      SortDirection: newSortOrder ? newSortOrder.direction ?? this.defaultSortDirection : this.defaultSortDirection,
      UseReportingDB: useReportingDB ? useReportingDB : false,
    };

    this.paramsReq = params;
    this.coopAllService.all(params).subscribe();
  }

  sortField(sort: Sort) {
    let activeSort: any = this.defaultSortColumn;
    let direction: any = this.defaultSortDirection;
    if (sort !== null && sort !== undefined) {
      switch (true) {
        case sort.active === 'createDate':
          activeSort = 'createDate';
          break;
        case sort.active === 'cooperativeName':
          activeSort = 'coopName';
          break;
        case sort.active === 'state':
          activeSort = 'state';
          break;
        case sort.active === 'coopIndustry':
          activeSort = 'coopIndustry';
          break;
        case sort.active === 'numberOfActiveUsers':
          activeSort = 'numberOfActiveUsers';
          break;
        case sort.active === 'coopAdmin':
          activeSort = 'coopAdmin';
          break;
        case sort.active === 'status':
          activeSort = 'status';
          break;
      }
      switch (true) {
        case sort.direction === 'desc':
          direction = 'DESCENDING';
          break;
        case sort.direction === 'asc':
          direction = 'ASCENDING';
          break;
        case sort.direction === '':
          direction = null;
          break;
      }
    }
    return { active: activeSort, direction: direction };
  }

  actionEvent(event: any) {
    if (event.action === 'fetchAll') {
      this.fetchAll(event.data);
    } else if (event.action === 'exportCSV') {
      // export csv
    } else if (event.action === 'formChanges') {
      // form changes
    } else if (event.action === 'clearFilters') {
      // clear filters
    } else if (event.action === 'addButton') {
      this.openAddUserModal();
    }
    // 'edit' is specific to this component
    else if (event.action === 'edit') {
      this.goToEdit(event.data);
    }
  }

  openAddUserModal() {
    const dialogRef = this.dialog.open(AddCooperativeComponent, {
      panelClass: 'full-screen-modal-medium-mobile-panel-class',
      data: { items: '' },
    });
    dialogRef.afterClosed().subscribe(() => {
      this.gridOptions.fetchData = true;
    });
  }

  goToEdit(row: any) {
    this.router.navigate(['/cooperatives/settings', row?.id], { state: { name: row.cooperativeName } }).then();
  }
}
