<div class="p-4">
  <div class="flex justify-center flex-col">
    <div class="flex justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="close()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div class="admin-modal-body">
      <div class="px-12 py-8">
          <div class="text-center px-5 py-3">
              <h2 class="font-bold mb-5">Upload File</h2>
          </div>

        <div class="w-full pr-0">
          <div class="flex w-full mb-2 py-3">
            <div>
              <img *ngIf="showImagePreview; else other_image" [src]="localImageUrl"
                class="w-[6.25rem] h-[6.25rem] block object-cover border-2 border-primary p-[4px] org_image"
                alt="{{ form?.value?.name }}" />
              <ng-template #other_image>
                <img src="/assets/images/profile/individual-icon.svg"
                  class="w-[6.25rem] h-[6.25rem] block object-cover border-2 border-primary p-[4px] org_image"
                  alt="{{ form?.value?.name }}" />
              </ng-template>
            </div>
            <div class="ml-4">
              <div class="flex flex-col w-full pt-4">
                <div>
                  <label for="photos" class="mb-1">
                    <input type="file" hidden class="text-black" id="photos" ng2FileSelect [uploader]="photoUploader"
                      #selectedPicture />
                    <div class="mb-3" *ngFor="let item of photoUploader.queue">
                      <div class="mb-1 w-full flex flex-wrap gap-1 items-center">
                        <p class="mb-0">
                          {{ item?.file?.name }}
                        </p>
                        <mat-icon class="cursor-pointer text-secondary"
                          (click)="item.remove(); clearSelectedPicture()">delete</mat-icon>
                      </div>
                    </div>
                    <div class="flex items-center">
                      <div class="btn-primary-outlined small text-center mb-1">Browse</div>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="pt-8 mb-4 flex justify-center">
          <ng-container *ngIf="!loader; else loading">
            <button class="btn-primary medium" type="button" (click)="upload()">
              Upload
            </button>
          </ng-container>
          <ng-template #loading>
            <div class="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>