import { inject, Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { CertificatesGrid } from './certificates.model';
import { CertificatesStore } from './certificates.store';

@Injectable({ providedIn: 'root' })
export class CertificatesService {
  certificatesStore = inject(CertificatesStore);
  constructor(private api: ApiService) {}
  
  getAll(params: any) {
    this.certificatesStore.setLoading(true);
    let rows = this.api.certificatesService.getAll(params).pipe(
      tap((entities: CertificatesGrid) => {
        const { rows, totalNumberOfItems } = entities;
        const newRows = rows?.map((row) => {
          return {
            ...row,
          };
        });
        const newEntities = {
          rows: newRows,
          totalNumberOfItems,
        };
        this.certificatesStore.setAll(newEntities);
        this.certificatesStore.setLoading(false);
      })
    );
    return rows;
  }

  exportCSV(params: any) {
    return this.api.certificatesService.exportCSV(params);
  }

  download(id: any, name: string) {
    this.api.certificatesService.downloadCert(id, name);
  }

}
