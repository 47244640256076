import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { ApiService } from 'src/app/api/services/api.service';
import { TrainingMetricsService } from 'src/app/api/services/reports/training-metrics/training-metrics.service';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { SpinnerService } from 'src/app/shared/services/spinner.service';
import { MatCardModule } from '@angular/material/card';
import { MatIconModule } from '@angular/material/icon';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { FilterIconComponent } from '../../../shared/components/layouts/filter-icon/filter-icon.component';
import { NgFor, NgIf } from '@angular/common';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';

@Component({
    selector: 'app-training-metrics',
    templateUrl: './training-metrics.component.html',
    styleUrls: ['./training-metrics.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed, void', style({ height: '0px', minHeight: '0', display: 'none' })),
            state('expanded', style({ height: '*', display: 'block' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
            transition('expanded <=> void', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        MatToolbarModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        NgFor,
        FilterIconComponent,
        NgIf,
        MatInputModule,
        MatAutocompleteModule,
        MatIconModule,
        MatCardModule,
        MatTableModule,
        MatDatepickerModule
    ],
})
export class TrainingMetricsComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  displayedColumns: string[] = [
    'courseName',
    'trainingType',
    'state',
    'notStartedCount',
    'inProgressCount',
    'completeCount',
    'registrationCount',
    'certsIssuedCount',
  ];
  tableHeading: string[] = [
    'Course',
    'Training',
    'State',
    'Not Started',
    'In Progress',
    'Complete',
    'Total Registrations',
    'Certificates Issued',
  ];
  expandedElement: any | null;
  dataSource!: MatTableDataSource<any>;
  disableflag: boolean = false;
  clearBtnFlag: boolean = true;
  expandElements: any[] = [];
  displayData: any = [];
  noSearchData: boolean = false;
  searchData: any;
  @ViewChild(MatSort) sort!: MatSort;
  filtersForm!: FormGroup;
  openFilters: boolean = true;
  totalNotStartedCourse: number = 0;
  totalIncompleteCourse: number = 0;
  totalCompleteCourse: number = 0;
  totalRegistrations: number = 0;
  totalCertificatesIssued: number = 0;

  constructor(
    private fb: FormBuilder,
    private trainingMetricsService: TrainingMetricsService,
    public lookupsService: LookupsService,
    private api: ApiService,
    private snackBar: MatSnackBar,
    private spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    this.spinnerService.whiteBackground();
    this.spinnerService.show();
    this.lookupsService.getStates();
    this.lookupsService.getTrainings();

    const today = new Date();
    const fromDate = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth(), 0);

    this.filtersForm = this.fb.group({
      search: this.fb.control(''),
      fromDate: this.fb.control(fromDate || null),
      toDate: this.fb.control(toDate || null),
      orgName: this.fb.control(''),
      trainingType: this.fb.control(null),
      stateId: this.fb.control(null),
    });

    this.organizationLookup('a');

    this.filtersForm
      .get('orgName')
      ?.valueChanges.pipe(
        tap((val: any) => {
          if (!val?.value && val?.length > 3) {
            this.organizationLookup(val);
          } else if (!val?.length) {
            this.organizationLookup('a');
          }
        })
      )
      .subscribe();

      this.applyFilters();
  }

  applyFilters() {
    const formData = this.filtersForm.value;
    const { fromDate, toDate, search, orgName, trainingType, stateId } = formData;
    const criteria = {
      fromDate: this.getDateString(fromDate) ?? '',
      toDate: this.getDateString(toDate) ?? '',
      search: search ?? '',
      entityGuid: orgName?.value ?? '',
      trainingType: trainingType ?? '',
      stateId: stateId ?? '',

    };

    this.trainingMetricsService.getAll(criteria).subscribe((results: any) => {
      this.spinnerService.hide();
      if (criteria.search) {
        const searchResults = Object.values(results).filter((x: any) =>
          x.courseName.toLowerCase().includes(criteria.search.toLowerCase())
        );
        this.displayData = searchResults;
      } else {

        Object.keys(results).forEach((key) => {

          if (key === 'totalNotStartedCourse') {
            this.totalNotStartedCourse = results[key];
          } else if (key === 'totalIncompleteCourse') {
              this.totalIncompleteCourse = results[key];
          } else if (key === 'totalCompleteCourse') {
              this.totalCompleteCourse = results[key];
          } else if (key === 'totalRegistrations') {
              this.totalRegistrations = results[key];
          } else if (key === 'totalCertificatesIssued') {
              this.totalCertificatesIssued = results[key];
          }

        })
        this.displayData = results.rows;
      }
    });
  }

  organizationLookup(val: any) {
    this.api.lookups
      .getOrganization(val)
      .pipe(
        catchError((e) => throwError(() => e)),
        tap((data: any) => {
          if (data) {
            this.searchData = data;
          }
        })
      )
      .subscribe({
        next: () => {},
        error: (e) => {
          this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
        },
      });
  }

  checkExpanded(element: any): boolean {
    let flag = true;
    this.expandElements.forEach((e: any) => {
      if (e === element) {
        flag = false;
      }
    });
    return flag;
  }

  pushPopElement(element: any) {
    const index = this.expandElements.indexOf(element);
    if (index === -1) {
      this.expandElements.push(element);
    } else {
      this.expandElements.splice(index, 1);
    }
  }

  displayFn(search: any): string {
    return search && search.name ? search.name : search?.length > 0 ? search : '';
  }

  exportCsv() {
    const formData = this.filtersForm.value;
    const { fromDate, toDate, search, orgName, trainingType, stateId } = formData;
    const criteria = {
      fromDate: this.getDateString(fromDate) ?? '',
      toDate: this.getDateString(toDate) ?? '',
      search: search ?? '',
      entityGuid: orgName?.value ?? '',
      trainingType: trainingType ?? '',
      stateId: stateId ?? '',

    };
    this.disableflag = true;
    this.trainingMetricsService.exportAll(criteria).subscribe({
      next: () => {
        this.disableflag = false;
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Success, message: 'Report will be sent to your email' },
        });
      },
      error: () => {
        this.disableflag = false;
        this.snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          data: { toastType: ToastType.Error, message: 'Something went wrong, please try Again' },
        });
      },
    });
  }

  clearFilters() {
    this.filtersForm.reset();
    const today = new Date();
    const fromDate = new Date(today.getFullYear() - 1, today.getMonth(), 1);
    const toDate = new Date(today.getFullYear(), today.getMonth(), 0);
    this.filtersForm.controls?.toDate?.setValue(toDate);
    this.filtersForm.controls?.fromDate?.setValue(fromDate);
  }

  closeFilter() {
    this.openFilters = !this.openFilters;
  }

  chooseFromMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
    const month = normalizedMonth.getMonth();
    const year = normalizedMonth.getFullYear();
    this.filtersForm.get('fromDate')?.setValue(new Date(year, month, 1));

  }

  chooseToMonthHandler(normalizedMonth: any, datepicker: MatDatepicker<any>) {
    datepicker.close();
    const month = normalizedMonth.getMonth();
    const year = normalizedMonth.getFullYear();
    this.filtersForm.get('toDate')?.setValue(new Date(year, month + 1, 0));
  }

  getDateString(date: any) {
    if (date) {
      const newDate = new Date(date);
      return newDate.toUTCString();
    }
    return '';
  }
}
