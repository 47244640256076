import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appMessageService } from 'src/app/api/services/app-messages/app-message.service';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';

@Component({
    selector: 'app-banner-message-edit',
    templateUrl: './banner-message-edit.component.html',
    styleUrls: ['./banner-message-edit.component.scss'],
    standalone: true,
    imports: [MatIconModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, NgIf, MatDatepickerModule]
})
export class BannerMessageEditComponent implements OnInit  {
  action: string = 'Add'; 


  form = this.fb.group({
    title: this.fb.control(null, [Validators.required]),
    message: this.fb.control(null, [Validators.required]),
    buttonText: this.fb.control(null),
    buttonLink: this.fb.control(null),
    displayStartDate: this.fb.control(null, [Validators.required]),
    displayEndDate: this.fb.control(null, [Validators.required]),
    maintenanceDate: this.fb.control(null, [Validators.required]),
  })

  disableFlag: boolean = false;

  constructor(
    private fb: FormBuilder,
    private appMessageService: appMessageService,
    private dialogRef: MatDialogRef<BannerMessageEditComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { action?: string, items: any, messageType: string },
  ) {}

  ngOnInit(): void {
    if(this.data) {
      this.action = this.data.action! == 'edit' ? 'Edit' : 'Add';
      if(this.data.messageType! === 'Banner') {
        this.appMessageService
        .getAppMessage(this.data.messageType, this.data.items.id)
        .subscribe((res) => {
          this.form.patchValue(res);
        })
      } 
    }
  }

  onSubmit() {
    this.disableFlag = true;
    if (!this.form.valid) {
      this.disableFlag = false;
      this.form.markAllAsTouched();
      return;
    }
  
    const { title, message, buttonText, buttonLink, displayStartDate, displayEndDate, maintenanceDate } = this.form.value;
  
    // Helper function to safely parse dates within SQL Server's acceptable range
    const parseDate = (dateString: string): string | null => {
      const date = new Date(dateString);
      // Ensure date is valid and within SQL Server's range
      if (!isNaN(date.getTime()) && date >= new Date('1753-01-01T00:00:00') && date <= new Date('9999-12-31T23:59:59')) {
        return date.toISOString()?.split('.')[0] ?? null; // Format to "yyyy-MM-ddTHH:mm:ss"
      }
      return null; // Return null for dates out of range or invalid dates
    };
  
    const formValues = {
      id: this.data.items?.id ?? 0,
      Title: title ?? '',
      Message: message ?? '',
      ButtonText: buttonText ?? '',
      ButtonLink: buttonLink ?? '',
      DisplayStartDate: displayStartDate ? parseDate(displayStartDate) : null,
      DisplayEndDate: displayEndDate ? parseDate(displayEndDate) : null,
      MaintenanceDate: maintenanceDate ? parseDate(maintenanceDate) : null
    };
  
    this.appMessageService.upsertBannerMessage(formValues).subscribe({
      next: () => {
        this.closeModal();
      },
      error: (error) => {
        this.disableFlag = false;
        console.error("Error saving banner message:", error);
      }
    });
  }
  

  closeModal() {
    this.dialogRef.close('success');
  }
}


