<ng-container *ngIf="!loginSuccessful">
 <div class=" bg-white sm:bg-inherit flex items-center justify-center sm:px-4 sm:py-5">
    <div class="p-4 sm:p-8 bg-white sm:card-shadow sm:max-w-[700px] sm:min-w-280 w-full">
      <div class="login-dynamic-width mx-auto">
        <div class="text-center mb-5">
          <h1 class="text-primary font-bold mb-5 text-center">Log In</h1>
        </div>

        <form [formGroup]="form">
          <div class="flex flex-wrap">
            <div class="w-full sm:py-1">
              <app-form-field label="Email Address *" formControlName="userName"> </app-form-field>
            </div>
            <div class="w-full sm:py-1">
              <app-form-field label="Password *" type="password" formControlName="password" showPassword="true">
              </app-form-field>
            </div>

            <div class="w-full pt-5 text-center mb-2 flex justify-center">
              <button type="submit" (click)="loginValidate()" [disabled]="disableFlag" class="btn-primary large">Log
                In</button>
            </div>
          </div>
        </form>
        <div class="flex items-center justify-center font-medium mb-5 mt-5">
          <a class="primary-link" routerLink="/authentication/forgot">Forgot Password</a>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container  *ngIf="downloadApp;" >
<div class="text-center login-container">
    <h1>Two-Factor Authentication</h1>
    <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account secure.</p>

    <h2 class="learner mt-4 strong">Download an Authenticator App</h2>
    <p>On your phone, download and install an Authenticator App.</p>

    <p class="mt-3 flex items-center justify-center">Google Authenticator <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a>  <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US&pli=1" target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a></p>
    <p class="flex items-center justify-center">Microsoft Authenticator  <a href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458" target="_blank"><mat-icon>apple</mat-icon><span class="sr-only"></span></a>  <a href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US" target="_blank"><mat-icon>android</mat-icon><span class="sr-only"></span></a> </p>

    <p class="mt-3">Once you’ve installed the Authenticator App, click Next.</p>


    <div class="my-5">
      <button class="btn-primary large" (click)="showQRCode()">Next</button>
    </div>
</div>
</ng-container >


<ng-container *ngIf="show2fa;">
  <div class="w-screen login-container">
    <div class="h-full text-center">
      <ng-container *ngIf="!is2faSetup">
        <h1>Two-Factor Authentication</h1>
        <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account secure.</p>
        <h2 class="learner mt-10 strong">Scan the QR Code</h2>

        <p class="width25 mx-auto">Open the Authenticator App, follow instructions to add a new account and 
          use the app to scan the QR Code below.</p>
          
          
        <div id="qrCode" class="w-[150px] my-4 mx-auto">
          <img [src]="qrCodeImgSrc">
        </div>
      </ng-container>
      
      <button (click)="showVerifyAuthCode()" class="btn-primary large">
        Verify
      </button>

    </div>
  </div>
</ng-container> 

<ng-container *ngIf="verifyAuthCode;">
  <div class="w-screen login-container">
    <div class="h-full text-center">
        <h1>Two-Factor Authentication</h1>
        <p class="mt-3">Mandated Reporter Training requires additional authentication to keep your account secure.</p>
        <h2 class="learner mt-10 strong">Enter Authentication Code</h2>
        <p class="width25 mx-auto">Open your Authenticator App and enter the 6-digit code below.</p>


      <form class="flex flex-col items-center mt-8" [formGroup]="form">
        <div class="width25">
          <mat-form-field class="flex mx-auto">
            <mat-label>Verification Code</mat-label>
            <input matInput type="text" class="w-full" formControlName="verificationCode" />
            <mat-error
              *ngIf="form.controls['verificationCode'].errors && form.controls['verificationCode'].touched">
              That code didn't work
            </mat-error>
          </mat-form-field>
          <button type="submit" (click)="onSubmit2fa()" [disabled]="disableFlag" class="btn-primary large mt-8">
            Verify
          </button>
        </div>
      </form>
    </div>
  </div>
</ng-container> 
  
