<!-- <h4>Course Template Files</h4>
<div *ngIf="lookupQuery.certFilesHasValue$ | async">
  <ng-container *ngFor="let templateFile of lookupQuery.certFiles$ | async">
    {{ templateFile.value }}
  </ng-container>
</div> -->
<h1 class="learner py-4">Course Files</h1>
<div class="flex flex-wrap gap-3" *ngIf="lookupsStore.badgeFilesHasValueSignal()">
  <div class="h-52 w-52 p-2 bg-gray-100 hover:bg-gray-300 hover:cursor-pointer flex justify-center items-center" (click)="addBadgeFile()">
    <mat-icon>add_circle_outline</mat-icon>
  </div>
  <div class="h-fit p-2 shadow-md"
    *ngFor="let templateFile of lookupsStore.badgeFilesSignal()">
    <img class="h-48" [src]="templateFile.name" [alt]="templateFile.value" />
  </div>
</div>