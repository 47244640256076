import { Component,inject,  Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { catchError, throwError, map, forkJoin } from 'rxjs';
import { LearnerMetricsService } from 'src/app/api/services/reports/learner-metrics/learner-metrics.service';
import { HttpClient } from '@angular/common/http';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { ApiService } from 'src/app/api/services/api.service';
import { NgIf, NgFor } from '@angular/common';
import { Clipboard } from '@angular/cdk/clipboard';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { FormFieldComponent } from '../../../form/components/form-field/form-field.component';
import { MatOptionModule } from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { components } from '../../../api/interfaces/swagger-types';
import { NgxPermissionsModule } from 'ngx-permissions';
export type LookupEntityTypeViewModel= components['schemas']['LookupEntityTypeViewModel'];

@Component({
    selector: 'app-learning-metrics-modal',
    templateUrl: './learning-metrics-modal.component.html',
    styleUrls: ['./learning-metrics-modal.component.scss'],
    standalone: true,
    imports: [
        MatIconModule,
        NgIf,
        FormsModule,
        ReactiveFormsModule,
        NgFor,
        MatFormFieldModule,
        MatSelectModule,
        MatOptionModule,
        FormFieldComponent,
        MatExpansionModule,
        NgxPermissionsModule
    ],
})

export class LearningMetricsModalComponent implements OnInit {
  formStatus = this.fb.group({});
  readonly lookupsStore = inject(LookupsStore);
  formAccount = this.fb.group({});
  initialmoduleStatusData: any[] = [];
  moduleStatusData: any[] = [];
  options: any[]=[];
  userCourseVersionId: number | undefined;
  editPage: boolean = true;
  courseData: any;
  // TODO shouldnt be using a lookup
  stateNames: string[] = [
    "National",
    "Alabama",
    "Alaska",
    "Arizona",
    "Arkansas",
    "California",
    "Colorado",
    "Connecticut",
    "District of Columbia",
    "Delaware",
    "Florida",
    "Georgia",
    "Hawaii",
    "Idaho",
    "Illinois",
    "Indiana",
    "Iowa",
    "Kansas",
    "Kentucky",
    "Louisiana",
    "Maine",
    "Maryland",
    "Massachusetts",
    "Michigan",
    "Minnesota",
    "Mississippi",
    "Missouri",
    "Montana",
    "Nebraska",
    "Nevada",
    "New Hampshire",
    "New Jersey",
    "New Mexico",
    "New York",
    "North Carolina",
    "North Dakota",
    "Ohio",
    "Oklahoma",
    "Oregon",
    "Pennsylvania",
    "Rhode Island",
    "South Carolina",
    "South Dakota",
    "Tennessee",
    "Texas",
    "Utah",
    "Vermont",
    "Virginia",
    "Washington",
    "West Virginia",
    "Wisconsin",
    "Wyoming"
];
  constructor(
    private dialogRef: MatDialogRef<LearningMetricsModalComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private learnerMetricsService: LearnerMetricsService,
    private snackBar: MatSnackBar,
    private http: HttpClient,
    public lookupsService: LookupsService,
    private clipboard: Clipboard,
    private api: ApiService,

  ) {}
  disableFlag: boolean = true;

  // TODO: Why is this component making an api call directly
  ngOnInit(): void {
    this.formAccount.addControl("organization", new FormControl(null));
    this.userCourseVersionId = this.data.items.userCourseVersionId;
    this.http.get<LookupEntityTypeViewModel[]>(`/AdminEntityUser/entities/${this.data.items.entityUserId}`).pipe(map(s => 
      {
          // Return the entity type to filter out cooperatives
          return s.map(value => {return {text:value.key, label: value.text, entityType: value.entityTypeName}});
      }
      )).subscribe( (res)=>
        {
          this.options = res.filter(s=>s.label !== this.data.items.organizationName && s.entityType !== "Coop");
        }
    );

    this.formStatus.addControl("note", new FormControl(null, Validators.required));
    this.lookupsService.getUserScormtrackStatus();
    this.userCourseVersionId = this.data.items.userCourseVersionId;
    const params = { userCourseVersionId: this.data.items.userCourseVersionId };
    
    this.http.get('AdminUserCourseVersion/get-detail', { params }).subscribe((courseData) => {
      this.courseData = courseData;
      //@ts-ignore
      (courseData.scormTracks as any[]).forEach((scormTrack) => {
        //building form
        this.formStatus.addControl(scormTrack.id, new FormControl(''));
        this.formStatus.get(scormTrack.id.toString())?.setValue(scormTrack.status);
        if (scormTrack.status == "Complete") {
          this.formStatus.get(scormTrack.id.toString())?.disable();
        }
        //building data source
        this.initialmoduleStatusData.push({
          id: scormTrack.id,
          label: scormTrack.name,
          status: scormTrack.status,
          controlName: scormTrack.id,
        });

        this.moduleStatusData.push({
          id: scormTrack.id,
          label: scormTrack.name,
          status: scormTrack.status,
          controlName: scormTrack.id,
        });
      });
    });
}
  getControlValue() {
    return this.data.items.trainingStatus;
  }

  close() {
    this.dialogRef.close();
  }

  statusChange(event: any) {
    const scormTrackId = event.source.ngControl.name;
    const status = event.value;
  
    this.moduleStatusData.forEach((i) => {
      if (i.id === scormTrackId) {
        i.status = status;
      }
    });
  }

  submitButton(button: any) {
    var statusChanges = false;
    var associationChanges = false;
    if (!this.formStatus?.valid) {
      this.formStatus.markAllAsTouched();
      return;
    }
    if (!this.formAccount?.valid) {
      this.formAccount.markAllAsTouched();
      return;
    }

    // check if there were any changes in status or the account association
    for (let i = 0; i < this.initialmoduleStatusData.length; i++) {
      if (this.initialmoduleStatusData[i].status !== this.moduleStatusData[i].status) {
        statusChanges = true;
        break;
      }
    }
    const orgId = this.formAccount.get("organization")?.value;
    if (orgId) {
      associationChanges = true;
    }

    // training status
    let tracks = this.moduleStatusData.map((item) => {
      return { id: item.id, status: item.status };
    });
    const formStatusValues = {
      userCourseVersionId: this.userCourseVersionId,
      notes: this.formStatus.get("note")?.value,
      tracks: tracks,
    };

    // account association
    const formAssociationValues = {
      userCourseVersionId: this.userCourseVersionId,
      organizationId: orgId,
      notes: this.formStatus.get("note")?.value,
    };

    // call endpoints based on the changes made
    var endpointsToCall: any[] = [];
    if (statusChanges) endpointsToCall.push(this.learnerMetricsService.updateLearnerMetric(formStatusValues));
    if (associationChanges) endpointsToCall.push(this.learnerMetricsService.transferCourse(formAssociationValues));

    if (statusChanges || associationChanges) {
      forkJoin(endpointsToCall)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.learnerMetricsService.getAll(this.data.gridParams).subscribe({
              next: () => {
                this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Updated Training' }})
                this.close();
              },
            });
          },
          error: (e) => {
            if(e.error)
            {
               this.snackBar.openFromComponent(SnackbarComponent, { duration: 8000, data: { toastType: ToastType.Error, message: 'Error '+ JSON.stringify(e.error) }})
            }
            else{
              this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Something went wrong' }})
            }
          },
        });
    }

    // if there were no changes in status or account association, then show the snackbar
    else {
      this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'No Changes were made to the Training Status or Account Association' }})
    }

  }

  downloadCert(id: any, name: string) {
    this.learnerMetricsService.download(id, name);
  }

  formatDate(date: any) {
    return new Intl.DateTimeFormat("en-US", { year: "numeric", month: "2-digit", day: "2-digit" }).format(new Date(date));
  }

  getExpiryMonths(completionDate: any, expiryMonths: any) {
    const date = new Date(completionDate);
    date.setMonth(date.getMonth() + expiryMonths);
    return this.formatDate(date);
  }

  clipBoard(scormJson: any) {
    this.clipboard.copy(JSON.stringify(scormJson));
    this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Success, message: 'Copied Json Data' }})
  }
}
