import {components} from "../../../../api/interfaces/swagger-types";
export type OrgAll = components['schemas']['AdminOrgGridViewModel'];

export interface OrganizationAll {
  orgAllSignal: OrgAll | null;
  loadingSignal: boolean;
}

export const initialState: OrganizationAll = {
  orgAllSignal: null,
  loadingSignal: false,
};
