import { Component, inject, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { catchError, filter, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/api/services/auth/auth.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';
import { ToastService } from 'src/app/api/services/toast.service';
import { SnackbarComponent, ToastType } from 'src/app/shared/components/layouts/snackbar/snackbar.component';
import { MatIconModule } from '@angular/material/icon';
import { FormFieldComponent } from '../../../../form/components/form-field/form-field.component';
import { NgIf } from '@angular/common';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';

@UntilDestroy()
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    standalone: true,
    imports: [
      NgIf, 
      FormsModule, 
      ReactiveFormsModule, 
      FormFieldComponent, 
      RouterLink, 
      MatIconModule, 
      MatFormFieldModule, 
      MatInputModule]
})
export class LoginComponent implements OnInit {
  authStore = inject(AuthStore);
  public form: FormGroup = Object.create(null);
  disableFlag: boolean = false;
  verifyEmail: boolean = false;
  loginSuccessful = false;
  is2faSetup = true;
  is2FaRequired = false;
  downloadApp = false;
  show2fa = false;
  verifyAuthCode = false;
  qrCodeImgSrc: string = '';

  constructor(
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private router: Router,
    private authService: AuthService,
    private _toastService: ToastService,
  ) {
    this.form = this.fb.group({
      userName: this.fb.control(null, [Validators.required, Validators.email]),
      password: this.fb.control(null, Validators.required),
      verificationCode: this.fb.control(null),
    });
  }

  ngOnInit(): void {
  }

  loginValidate() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    this.disableFlag = true;
    this.authService
        .loginValidate({userName: this.form.value.userName, password: this.form.value.password})
        .pipe(
          catchError((error) => {
            this.disableFlag = false;
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Invalid username or password' }})
            console.log('Login validation error', error);
            return error;
          }),
        )
        .subscribe((response: any) => {
          this.is2FaRequired = true;

          if (response.qrCode) {
            this.is2faSetup = false;
            this.qrCodeImgSrc = 'data:image/bmp;base64,' + response.qrCode; // show QR code
            this.downloadApp = true;
            this.disableFlag = false;
            this.loginSuccessful = true;
            this.form.get('verificationCode')?.setValidators(Validators.required);
          } else {
            this.loginSuccessful = true;
            this.verifyAuthCode = true;
            this.disableFlag = false;
            this.downloadApp = false;
          }
        });
  }

  onSubmit2fa() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    this.disableFlag = true;

    this.authService
      .login(this.form.value)
      .pipe(
        untilDestroyed(this),
        tap((success) => {
          if (!success) {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Invalid Authentication Code' }})
            this.disableFlag = false;
          }
        }),
        filter((success) => Boolean(success)),
      )
      .subscribe({
        next: () => {
          this.disableFlag = false;
          this.router.navigateByUrl('/').then();
        },
        error: (e) => {
          let err = e; 
          this.disableFlag = false;
          const control = this.form?.get('userName');
          this.verifyEmail = false
          if (err?.error?.login_failure?.length > 0) {
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Incorrect username/password' }})
          } else if (err?.error?.verify_email?.length > 0) {
            this.verifyEmail = true
          } else {
            this._toastService.error('Incorrect username/password');
            this.snackBar.openFromComponent(SnackbarComponent, { duration: 3000, data: { toastType: ToastType.Error, message: 'Incorrect username/password' }})
          }
        },
      });
  }

  showQRCode() {
    this.show2fa = true;
    this.downloadApp = false;
  }


  showVerifyAuthCode() {
    this.show2fa = false;
    this.verifyAuthCode = true;
  }

}
