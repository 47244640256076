import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from 'src/app/api/services/auth/auth.service';
import { AuthStore } from 'src/app/api/services/auth/auth.store';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard  {
  authStore = inject(AuthStore);
  constructor(private authServ: AuthService, private router: Router) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn();
  }
  canLoad(
    route: Route,
    segments: UrlSegment[]): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.userLoggedIn();
  }
  private userLoggedIn() {
    this.authServ.loadUserDetails();
    if (this.authStore.userLoggedInSignal()) {
      return true;
    } else {
      this.router.navigateByUrl('/authentication/login');
      return false;
    }
  }
}
