import { CoursesResponse } from "src/app/api/services/courses/courses.service";

export type AllCourse = CoursesResponse[number];

export interface AllCourses {
  coursesSignal: AllCourse[];
  loadingSignal: boolean;
}

export const initialState: AllCourses = {
  coursesSignal: [],
  loadingSignal: false,
};
