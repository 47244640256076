import { enableProdMode, importProvidersFrom } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { NgxMaskModule } from 'ngx-mask';
import { provideAnimations } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app/app-routing.module';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { NgxPermissionsModule } from 'ngx-permissions';
import { HTTP_INTERCEPTORS, HttpClientModule, provideHttpClient, withInterceptors } from '@angular/common/http';
import { MaterialModule } from './app/material/material.module';
import { BaseUrlInterceptor } from './app/api/interceptor/base-url.interceptor';
import { ErrorInterceptor } from './app/api/interceptor/error.interceptor';
import { JwtInterceptor } from './app/api/interceptor/jwt.interceptor';
import { AppStores } from './app/api/services/stores';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
      AppStores,
      importProvidersFrom(
        AppStores,
        MaterialModule,
        BrowserModule, 
        AppRoutingModule, 
        HttpClientModule, 
        NgxMaskModule.forRoot(), 
        NgxPermissionsModule.forRoot(),
      ),
      {
        provide: HTTP_INTERCEPTORS,
        useClass: BaseUrlInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ErrorInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtInterceptor,
        multi: true,
      },
      provideAnimations()
    ]
})
  .catch(err => console.error(err));
