import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ErrorComponent } from './shared/components/error/error.component';
import { BlankComponent } from './shared/components/layouts/blank/blank.component';
import { FullComponent } from './shared/components/layouts/full/full.component';
import { AuthGuardGuard } from './routes/authentication/guard/auth-guard.guard';
import { ngxPermissionsGuard } from 'ngx-permissions';
import { DashboardComponent } from './routes/dashboard/dashboard.component';
import { UnauthorizedComponent } from './shared/components/unauthorized/unauthorized.component';
import { ResetPasswordComponent } from './routes/authentication/components/reset-password/reset-password.component';
import { SubscriptionReportComponent } from './routes/purchases/subscription-report/subscription-report.component';
import { EnterpriseSubscriptionsComponent } from './routes/purchases/enterprise-subscriptions/enterprise-subscriptions.component';
import { CertificatesComponent } from './routes/purchases/certificates/certificates.component';
import { PayAsYouGoReportComponent } from './routes/purchases/pay-as-you-go-report/pay-as-you-go-report.component';
import { OrganizationEditComponent } from './routes/organization/organization-all/organization-edit/organization-edit.component';
import { AppMessagesComponent } from './routes/app-messages/app-messages/app-messages.component';
import { CoursesAllComponent } from './routes/courses/courses-all/courses-all.component';
import { ModulesAllComponent } from './routes/courses/modules-all/modules-all.component';
import { PaidProductsComponent } from './routes/courses/paid-products/paid-products.component';
import { CatalogComponent } from './routes/courses/catalog/catalog.component';
import { CourseFilesComponent } from './routes/courses/course-files/course-files.component';
import { LearnerMetricsComponent } from './routes/reports/learner-metrics/learner-metrics.component';
import { TrainingMetricsComponent } from './routes/reports/training-metrics/training-metrics.component';
import { SimpleOffsiteMetricsComponent } from './routes/reports/simple-offsite-metrics/simple-offsite-metrics.component';
import { AllUsersComponent } from './routes/users/all-users/all-users.component';
import { AllUsersEditComponent } from './routes/users/all-users/all-users-edit/all-users-edit.component';
import { OrgAdminComponent } from './routes/users/org-admin/org-admin.component';
import { PrivacyComponent } from './routes/users/privacy/privacy.component';
import { CooperativeAllComponent } from './routes/cooperative/cooperative-all/cooperative-all.component';
import { UserAssociationComponent as CooperativeUserAssociationComponent } from './routes/cooperative/user-association/user-association.component';
import { UserAssociationComponent } from './routes/organization/user-association/user-association.component';
import { UserInvitesComponent } from './routes/organization/user-invites/user-invites.component';
import { OrganizationAllComponent } from './routes/organization/organization-all/organization-all.component';
import { ForgotComponent } from './routes/authentication/components/forgot/forgot.component';
import { LoginComponent } from './routes/authentication/components/login/login.component';
import { CheckinboxComponent } from './routes/authentication/components/checkinbox/checkinbox.component';


const routes: Routes = [
  {path: 'authentication', redirectTo: '/authentication/login', pathMatch: 'full'},
  {path: 'reset', component: ResetPasswordComponent},
  {
    path: 'authentication',
    component: BlankComponent,
    children: [
      { path: 'forgot', component: ForgotComponent, },
      { path: 'login', component: LoginComponent, },
      { path: 'reset', component: ResetPasswordComponent, },
      {
        path: 'checkinbox',
        component: CheckinboxComponent,
        data: {
          title: 'Check inbox',
        },
      }    
    ],
  },
  {
    path: '',
    component: FullComponent,
    canActivate: [AuthGuardGuard],
    children: [
      { path: '404', component: ErrorComponent, },
      { path: '401', component: UnauthorizedComponent, },
      { path: '', pathMatch: 'full', redirectTo: '/dashboard' },
      { path: 'dashboard', component:DashboardComponent },


      // ORG_ROUTES
      {
        path: 'organizations',
        canActivate: [ngxPermissionsGuard],
       data: {
          permissions: {
            only: ['orgs:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'all-organizations', component: OrganizationAllComponent, },
          { path:'settings/:id', component: OrganizationEditComponent, },
          { path: 'learner-associations', component: UserAssociationComponent, },
          { path: 'learner-invites', component: UserInvitesComponent },
          { path:'', pathMatch: 'full', redirectTo: '/organizations/all-organizations' },
        ]
      },


      // COOP_ROUTES
      {
        path: 'cooperatives',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['coops:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'all-cooperatives', component:CooperativeAllComponent, },
          { path:'settings/:id', component: OrganizationEditComponent, },
          { path: 'learner-associations', component: CooperativeUserAssociationComponent, },
          { path:'', pathMatch: 'full', redirectTo: '/cooperatives/all' },
        ]
      },


      // LEARNERS_ROUTES
      {
        path: 'learners',
        data: {
          permissions: {
            only: ['users:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'all-learners', component: AllUsersComponent, },
          { path:'all-learners/edit-learners/:id', component: AllUsersEditComponent, },
          { path: 'org-admins', component: OrgAdminComponent, },
          { path: 'privacy', component: PrivacyComponent, },
          { path:'', pathMatch: 'full', redirectTo: '/learners/all-learners' },
        ]
      },


      // REPORTS_ROUTES
      {
        path: 'reports',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['reports:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'learner-reports', component:LearnerMetricsComponent, },
          { path: 'training-reports', component: TrainingMetricsComponent, },
          { path: 'simple-offsite', component: SimpleOffsiteMetricsComponent, },
          { path:'', pathMatch: 'full', redirectTo: '/reports/learner-metrics' }
        ]
      },


      // COURSES_ROUTES
      {
        path: 'courses',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['courses:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'all-courses', component: CoursesAllComponent, },
          { path: 'all-modules', component: ModulesAllComponent, },
          { path: 'paid-products', component: PaidProductsComponent, },
          { path: 'catalog', component: CatalogComponent, },
          { path: 'course-files', component: CourseFilesComponent, },
          { path:'', pathMatch: 'full', redirectTo: '/courses/all-courses' },
        ]
      },

      
      // NOTIFICATIONS_ROUTES
      {
        path: 'notifications',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['app-messages:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'app-messages', component: AppMessagesComponent },
          { path:'', pathMatch: 'full', redirectTo: '/notifications/app-messages'},
        ],
      },

      // REVENUE_ROUTES
      {
        path: 'revenue',
        canActivate: [ngxPermissionsGuard],
        data: {
          permissions: {
            only: ['purchases:view'],
            redirectTo: '/401',
          },
        },
        children: [
          { path: 'pay-as-you-go-report', component: PayAsYouGoReportComponent,},
          { path: 'subscription-report', component: SubscriptionReportComponent,},
          { path: 'certificates', component: CertificatesComponent,},
          { path: 'enterprise-subscriptions', component: EnterpriseSubscriptionsComponent,},
          { path: 'enterprise-subscriptions/edit-org/:id', component: OrganizationEditComponent,},
          { path:'', pathMatch: 'full', redirectTo: '/reports/subscription-report'}
        ],
      },
    ],
  },
  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
