import {
  patchState,
  signalStore,
  withMethods,
  withState,
} from '@ngrx/signals';
import { AllCourses, initialState } from './all-course.model';

export const AllCoursesStore = signalStore(
  withState(initialState),

  withMethods(({...store}) => ({
    setAll(coursesSignal: AllCourses["coursesSignal"]) {
      patchState(store, {coursesSignal});
    },
    setLoading(isLoading: boolean) {
      patchState(store, {loadingSignal: isLoading});
    },
    resetStore() {
      patchState(store, initialState);
    }
  }))
)