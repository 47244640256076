import { Component, inject, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { catchError, forkJoin, throwError } from 'rxjs';
import { LookupsService } from 'src/app/api/services/lookups/lookups.service';
import { LookupsStore } from 'src/app/api/services/lookups/lookups.store';
import { UserAssociationsService } from 'src/app/api/services/organizations/user-associations/user-association.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule } from '@angular/material/core';
import { NgFor, NgIf } from '@angular/common';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgxPermissionsModule } from 'ngx-permissions';

interface StatusRoleDialogData {
  items: any;
  entityType: 'Organization' | 'Cooperative';
}
@Component({
  selector: 'app-status-role',
  templateUrl: './status-role.component.html',
  styleUrls: ['./status-role.component.scss'],
  standalone: true,
  imports: [
      MatIconModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatSelectModule,
      NgFor,
      MatOptionModule,
      MatCheckboxModule,
      NgIf,
      NgxPermissionsModule
  ],
})
export class StatusRoleComponent implements OnInit {
  readonly lookupsStore = inject(LookupsStore);
  statusForm: FormGroup = Object.create(null);
  options = [
    { value: 'Active', label: 'Active' },
    { value: 'Inactive', label: 'Inactive' },
  ];
  originalStatus: string = '';
  roleForm: FormGroup = Object.create(null);
  originalRoles: [] = [];
  validateForm: boolean = false;
  disableFlag: boolean = true;
  constructor(
    private dialogRef: MatDialogRef<StatusRoleComponent>,
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: StatusRoleDialogData,
    public lookupsService: LookupsService,
    private userAssociationsService: UserAssociationsService
  ) {}

  ngOnInit(): void {
    // status form
    this.statusForm = this.fb.group({
      status: this.fb.control(null, Validators.required),
    });
    this.statusForm.patchValue({ status: this.data?.items?.status });
    this.originalStatus = this.data?.items?.status;

    // role form
    this.roleForm = this.fb.group({
      learner: this.fb.control(null),
      entityAdmin: this.fb.control(null),
      coopAdmin: this.fb.control(null),
    });
    const {
      items: { orgRole },
    } = this.data;
    const roles = orgRole?.split(/,\s|,/);
    this.originalRoles = orgRole?.split(/,\s|,/);
    if (roles?.includes('EntityAdmin')) {
      this.roleForm.patchValue({ entityAdmin: true });
    } if (roles?.includes('Learner')) {
      this.roleForm.patchValue({ learner: true });
    } if (roles?.includes('CoopAdmin')) {
      this.roleForm.patchValue({ coopAdmin: true });
    }
  }

  submit() {
    // status form
    if (!this.statusForm?.valid) {
      this.statusForm?.markAllAsTouched();
      return;
    }
    const {
      items: { id },
    } = this.data;
    const statusFormValues = {
      id: id,
      status: this?.statusForm?.get('status')?.value,
    };

    // role form
    const { entityAdmin, learner, coopAdmin } = this.roleForm?.getRawValue();
    let roles: any[] = [];
    if (!entityAdmin && !learner && !coopAdmin) {
      this.validateForm = true;
      return;
    } if (entityAdmin) {
      roles.push('EntityAdmin');
    } if (coopAdmin) {
      roles.push('CoopAdmin');
    } if (learner) {
      roles.push('Learner');
    }
    this.validateForm = false;
    const roleFormValues = {
      entityUserId: id,
      roleNames: roles,
    };

    // call endpoints based on the changes made
    var endpointsToCall: any[] = [];
    if (this.originalStatus !== statusFormValues.status) {
      endpointsToCall.push(this.userAssociationsService.assignStatus(statusFormValues));
    }
    if (this.originalRoles.toString() !== roles.toString()) {
      endpointsToCall.push(this.userAssociationsService.assignRoles(roleFormValues));
    }
    if (endpointsToCall.length) {
      forkJoin(endpointsToCall)
        .pipe(catchError((e) => throwError(() => e)))
        .subscribe({
          next: () => {
            this.close();
          },
        });
    }
  }

  close() {
    this.dialogRef.close();
  }

  learnerRoleButton() {
    if (this.roleForm.get('learner')?.value) {
      this.roleForm.patchValue({ coopAdmin: false });
    }
  }
  coopAdminRoleButton() {
    if (this.roleForm.get('coopAdmin')?.value) {
      this.roleForm.patchValue({ entityAdmin: false });
      this.roleForm.patchValue({ learner: false });
    }
  }
}
