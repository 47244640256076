<div class="p-4">
  <div class="flex flex-col h-full">
    <div class="text-right justify-end close-modal-button-pos">
      <button type="button" class="exit-btn cursor-pointer opacity-100 flex items-center" (click)="closeModal()">
        <p class="bold mb-0">Close</p>
        <mat-icon>close</mat-icon>
      </button>
    </div>
  
    <div class="admin-modal-body">
      <div class="px-12 py-8">
          <div class="text-center px-5 py-3">
              <h2 class="font-bold mb-5"> {{ data.action + ' Enterprise Subscription' }}</h2>
          </div>
          <p class="text-primary text-center w-full">Create an enterprise subscription to enable organization functionality outside of the standard paid subscription.</p>
          <form [formGroup]="form">
            <mat-card class="mb-10 relative">
              <mat-card-content>
                <div class="w-full flex">
                    <mat-form-field class="w-full">
                      <mat-label>Plan Type</mat-label>
                      <mat-select formControlName="certificateBased" panelClass="mat-select-mt3" placeholder="Badge Name">
                        <mat-option [value]="false"
                          >Seat Based
                        </mat-option>
                        <mat-option [value]="true"
                        >Certificate Based
                      </mat-option>
                      </mat-select>
                      <mat-error *ngIf="form.controls['certificateBased'].hasError('required') && form.controls['certificateBased'].touched"
                      >Required
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full">
                    <mat-form-field class="datePicker-2 w-full">
                      <mat-label>Start Date</mat-label>
                      <input matInput formControlName="startDate" [matDatepicker]="picker1" />
                      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                      <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="w-full">
                    <mat-form-field class="datePicker-2 w-full">
                      <mat-label>Expire Date</mat-label>
                      <input matInput formControlName="expireDate" [matDatepicker]="picker2" />
                      <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                      <mat-datepicker #picker2 disabled="false"></mat-datepicker>
                    </mat-form-field>
                  </div>
        
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>Amount</mat-label>
                      <input class="text-black" type="text" matInput formControlName="amount" />
                      <mat-error *ngIf="form.controls['amount'].hasError('notAnInteger') && form.controls['amount'].touched"
                      >Please enter a valid integer.
                      </mat-error>
                    </mat-form-field>
                  </div>        
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>{{form.get("certificateBased")?.getRawValue() === null ? "Certs/Seats" : form.get("certificateBased")?.getRawValue() === true ? "Certs" : "Seats"}}</mat-label>
                      <input class="text-black" type="text" matInput formControlName="seats" />
                      <mat-error *ngIf="form.controls['seats'].hasError('notAnInteger') && form.controls['seats'].touched"
                      >Please enter a valid integer.
                      </mat-error>
                    </mat-form-field>
                  </div>
                  <div class="w-full">
                    <mat-form-field class="w-full">
                      <mat-label>Notes</mat-label>
                      <input class="text-black" type="text" matInput formControlName="notes" />
                      <mat-error *ngIf="form.controls['notes'].hasError('maxlength') && form.controls['notes'].touched"
                      >Max 500 Characters
                      </mat-error>
                    </mat-form-field>
                  </div>
                
              </mat-card-content>
            </mat-card>
            <div class="mt-auto pb-3 flex justify-center flex-col items-center">
              <button *ngxPermissionsOnly="['purchases-subscriptions:edit']" class="btn-primary large" type="submit" [disabled]="disableFlag" (click)="onSubmit()">
                <ng-container>{{ data.action + ' Enterprise Subscription' }}</ng-container>
              </button>
            </div>
          </form>
      </div>
    </div>
  </div>
</div>