import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GrecaptchaService {
  // @ts-ignore
  grecaptcha$: any = new BehaviorSubject(grecaptcha);
  private _grecaptcha: any;

  constructor() {
    this.grecaptcha$.subscribe((grecaptcha: any) => {
      this._grecaptcha = grecaptcha;
    });
  }

  execute(action: string): Promise<string> {
    return new Promise((resolve) => {
      this._grecaptcha.ready(() => {
        this._grecaptcha
          .execute(environment.recaptchaSiteKey, {
            action: action + (environment.context !== 'prod' ? `_${environment.context}` : ''),
          })
          .then((token: string) => {
            resolve(token);
          });
      });
    });
  }
}
