<ng-container *ngIf="type && control">
  <mat-form-field class="w-full" hideRequiredMarker>
    <mat-label>{{ label }}</mat-label>

    <input
      matInput
      [type]="showPassword ? (hide ? 'password' : 'text') : inputType"
      [autocomplete]="autocomplete"
      [value]="value"
      [formControl]="control"
      [attr.disabled]="disabled"
      [step]="step"
      [min]="min"
      [max]="max"
      [dropSpecialCharacters]="specialCharaters"
      [name]="name"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
    />

    <mat-hint align="start" *ngIf="startHint"
      ><strong>{{ startHint }}</strong></mat-hint
    >
    <mat-hint align="end" *ngIf="endHint"
      ><strong>{{ endHint }}</strong></mat-hint
    >
    <mat-icon class="cursor-pointer" matSuffix (click)="showPasswordHandler()" *ngIf="showPassword">
      {{ hide ? 'visibility' : 'visibility_off' }}
    </mat-icon>
    <mat-icon matSuffix *ngIf="suffix">{{ suffix }}</mat-icon>
    <mat-icon matPrefix *ngIf="prefix">{{ prefix }}</mat-icon>

    <mat-error *ngIf="showError && control.errors.required">Required</mat-error>
    <mat-error *ngIf="control?.errors?.email">Must be a valid email</mat-error>
    <mat-error *ngIf="control?.errors?.min" [id]="null">{{ minError }}</mat-error>
    <mat-error *ngIf="control?.errors?.maxlength" [id]="null"> Name should be below 30 characters</mat-error>
    <mat-error *ngIf="control.errors?.notEquivalent && control.touched">{{ fieldMatch }} must match.</mat-error>

    <mat-error class="p-b-5" *ngIf="control?.errors?.pattern">{{ patternErrorMessage }}</mat-error>
    <mat-error *ngIf="control?.errors?.min"
      >Total Payroll must include $20,000 for each owner working in the field</mat-error
    >
    <mat-error *ngIf="control?.errors?.message && !showErrorMsg">{{ control.getError('message') }}</mat-error>
  </mat-form-field>
</ng-container>
