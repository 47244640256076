import { Injectable } from '@angular/core';
import { PermissionsService } from 'src/app/routes/authentication/services/permission.service';
import { Permissions } from 'src/app/routes/authentication/services/permission.service';
import { NgxPermissionsService } from 'ngx-permissions';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface Separator {
  name: string;
  type?: string;
}

export interface SubChildren {
  state: string;
  name: string;
  type?: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
  requiredPermission : Permissions;
  child?: SubChildren[];
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  requiredPermission?: Permissions;
  badge?: BadgeItem[];
  separator?: Separator[];
  children?: ChildrenItems[];
  onlyRole?: string;
}

const adminMenuItems: Menu[] = [
  {
    state: 'dashboard',
    name: 'Dashboard',
    type: '',
    icon: 'dashboard'
  },
  {
    state: 'learners',
    name: 'Learners',
    type: 'sub',
    icon: 'people',
    requiredPermission: 'users:view',
    children: [
      { state: 'all-learners', name: 'All Learners', type: 'link', requiredPermission: 'users:view', },
      { state: 'org-admins', name: 'Org Admins', type: 'link', requiredPermission: 'privacyrequest:view'},
      { state: 'privacy', name: 'Privacy', type: 'link', requiredPermission: 'privacyrequest:view'},
    ],
  },
  {
    state: 'organizations',
    name: 'Organizations',
    type: 'sub',
    icon: 'corporate_fare',
    requiredPermission: 'orgs:view',
    children: [
      { state: 'all-organizations', name: 'All Organizations', type: 'link', requiredPermission: 'orgs:view'},
      { state: 'learner-associations', name: 'Learner Associations', type: 'link', requiredPermission: 'orgs-user-associations:view'},
      { state: 'learner-invites', name: 'Learner Invites', type: 'link', requiredPermission: 'orgs-user-invites:view'},
    ],
  },
  {
    state: 'cooperatives',
    name: 'Cooperatives',
    type: 'sub',
    icon: 'corporate_fare',
    requiredPermission: 'coops:view',
    children: [
      { state: 'all-cooperatives', name: 'All Cooperatives', type: 'link', requiredPermission: 'coops:view'},
      { state: 'learner-associations', name: 'Learner Associations', type: 'link', requiredPermission: 'coops-user-invites:view'},
    ],
  },
  {
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    icon: 'assignment_turned_in',
    requiredPermission: 'reports:view',
    children: [
      { state: 'learner-reports', name: 'Learner Reports', type: 'link', requiredPermission: 'reports-learner-metrics:view'},
      { state: 'training-reports', name: 'Training Reports', type: 'link', requiredPermission: 'reports-training-metrics:view'},
      { state: 'simple-offsite', name: 'Simple Scorm', type: 'link', requiredPermission: 'reports-training-metrics:view'},
    ],
  },
  {
    state: 'courses',
    name: 'Courses',
    type: 'sub',
    icon: 'menu_book',
    requiredPermission: 'courses:view',
    children: [
      { state: 'all-modules', name: 'Modules', type: 'link' ,   requiredPermission: 'courses:view'},
      { state: 'all-courses', name: 'Courses', type: 'link', requiredPermission: 'courses:view'},
      { state: 'paid-products', name: 'Paid Products', type: 'link', requiredPermission: 'courses:view'},
      { state: 'catalog', name: 'Catalog', type: 'link', requiredPermission: 'courses:view'},
      { state: 'course-files', name: 'Course Files', type: 'link', requiredPermission: 'courses:view'},
    ],
  },
  {
    state: 'notifications',
    name: 'Notifications',
    type: 'sub',
    icon: 'chat_bubble',
    requiredPermission: 'courses:view',
    children: [
      { state: 'app-messages', name: 'App Messages', type: 'link' ,  requiredPermission: 'courses:view'},
    ],
  },
  {
    state: 'revenue',
    name: 'Revenue',
    type: 'sub',
    icon: 'payment',
    requiredPermission: 'purchases:view',
    children: [
      { state: 'pay-as-you-go-report', name: 'Pay-As-You-Go', type: 'link' , requiredPermission: 'purchases-pay-as-you-go:view'},
      { state: 'subscription-report', name: 'Subscriptions', type: 'link' , requiredPermission: 'purchases-subscriptions:view'},
      { state: 'enterprise-subscriptions', name: 'Enterprise', type: 'link', requiredPermission: 'purchases-enterprise:view' },
      { state: 'certificates', name: 'Certificates', type: 'link', requiredPermission: 'purchases-certs:view' },
    ],
  }
];

const userRoleToMenu: Record<string, Menu[]> = {
  SuperAdmin: adminMenuItems,
};

@Injectable({ providedIn: 'root' })
export class MenuItems {
  constructor(private permissionService: PermissionsService, private ngxPermissionService: NgxPermissionsService) {}
  getMenuitem(user: any) {
    let adminMenuItemsNew: Menu[] = [];
    this.permissionService.applyRoles(user?.roles);

        adminMenuItems.forEach(async (item) => {
          if(item.requiredPermission && !(await this.ngxPermissionService.hasPermission(item.requiredPermission)))
            {
                return;
            }
            const itemClone = { ...item };
            if (item.children) {
                itemClone.children = await Promise.all(item.children.map(async (child) => {
                  if (await this.ngxPermissionService.hasPermission(child.requiredPermission)) {
                    return child;
                  }
                  return null;
                })).then(children => children.filter(child => child !== null));
                adminMenuItemsNew.push(itemClone);
            }

            if (!item.children) {
                adminMenuItemsNew.push(itemClone);
            }
          
        });
    
    return adminMenuItemsNew;
  }
}
